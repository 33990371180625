import {
    Directive,
    Input
} from '@angular/core';


@Directive({
    selector: '[href]',
    host: { '(click)': 'preventDefault($event)' },
})
export class HrefPreventDefaultDirective {

    @Input() href: string;

    constructor() {
    }


    preventDefault(event) {
        if (this.href.length === 0 || this.href === '#') {
            event.preventDefault();
        }
    }
}
