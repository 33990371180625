import {Component, Input, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import cronstrue from 'cronstrue';
import {ScheduleInfo} from "../../infos/schedule.info";
import {CommonValidator} from "../../helpers/common-validator";
import {FormControlHelper} from "../../helpers/form-control.helper";

@Component({
  selector: 'app-show-more-modal',
  templateUrl: './set-schedule-modal.component.html'
})
export class SetScheduleModalComponent implements OnInit {

  @Input() id: number;
  @Input() schedule: string;
  @Input() notesForSchedule: string;
  cronTranslate: string;
  isLoading = false;
  formGroup: FormGroup;
  cronTabTableName: string = "";
  cronTabFieldTable: any[] = []

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder,
              private cv: CommonValidator,
              public formControlHelper: FormControlHelper,
              private scheduleInfo: ScheduleInfo) { }

  ngOnInit(): void {
    console.log(this.schedule);
    let scheduleSplit: string[] = ["*", "*", "*", "*", "*"];
    if (this.schedule) {
      scheduleSplit = this.schedule.split(" ");
    } else {
      this.schedule = "* * * * *";
    }

    this.formGroup = this.fb.group({
      minutes: [scheduleSplit[0], Validators.compose([Validators.required, this.cv.validateCronInput])],
      hours: [scheduleSplit[1], Validators.compose([Validators.required, this.cv.validateCronInput])],
      dayOfMonth: [scheduleSplit[2], Validators.compose([Validators.required, this.cv.validateCronInput])],
      month: [scheduleSplit[3], Validators.compose([Validators.required, this.cv.validateCronInput])],
      dayOfWeek: [scheduleSplit[4], Validators.compose([Validators.required, this.cv.validateCronInput])],
    })
    this.cronTranslate = cronstrue.toString(this.schedule)
  }

  expressionChange() {
    let tempSchedule: string = "";
    tempSchedule += this.formGroup.value.minutes;
    tempSchedule += " " + this.formGroup.value.hours;
    tempSchedule += " " + this.formGroup.value.dayOfMonth;
    tempSchedule += " " + this.formGroup.value.month;
    tempSchedule += " " + this.formGroup.value.dayOfWeek;
    this.schedule = tempSchedule;
    try {
      this.cronTranslate = cronstrue.toString(this.schedule);
    } catch (e) {
      this.cronTranslate = "";
    }
  }

  onClick(unit) {
    switch (unit) {
      case "MINUTES":
        this.cronTabTableName = "Crontab Minute Field";
        this.cronTabFieldTable = this.scheduleInfo.getCronTabMinuteField();
        break;
      case "HOURS":
        this.cronTabTableName = "Crontab Hour Field";
        this.cronTabFieldTable = this.scheduleInfo.getCronTabHourField();
        break;
      case "DAY_OF_MONTH":
        this.cronTabTableName = "Crontab Day Of Month Field";
        this.cronTabFieldTable = this.scheduleInfo.getCronTabDayOfMonthField();
        break;
      case "MONTH":
        this.cronTabTableName = "Crontab Month Field";
        this.cronTabFieldTable = this.scheduleInfo.getCronTabMonthField();
        break;
      case "DAY_OF_WEEK":
        this.cronTabTableName = "Crontab Day Of Week Field";
        this.cronTabFieldTable = this.scheduleInfo.getCronTabDayOfWeekField();
        break;
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid.length > 0;
  }

  save(id) {
    let response = {id: id, schedule: this.schedule}
    this.modal.close(response);
  }
}
