"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var RouteNameService = /** @class */ (function () {
    function RouteNameService() {
    }
    // get current route name
    RouteNameService.getCurrentRouteName = function (activatedRoute) {
        var breadcrumbs = [];
        var children = activatedRoute.root.children;
        // return if there are no more children
        if (children.length === 0) {
            return;
        }
        var child = activatedRoute.firstChild;
        // iterate over each children
        while (child != undefined) {
            if (child.outlet == router_1.PRIMARY_OUTLET && child.snapshot.data.hasOwnProperty('breadcrumb')) {
                // add breadcrumb
                breadcrumbs.push(child.snapshot.data.breadcrumb);
            }
            child = child.firstChild;
        }
        return breadcrumbs[breadcrumbs.length - 1].route;
    };
    return RouteNameService;
}());
exports.RouteNameService = RouteNameService;
