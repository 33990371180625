"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PopoverInfoComponent = /** @class */ (function () {
    function PopoverInfoComponent() {
    }
    PopoverInfoComponent.prototype.ngOnChanges = function (changes) {
    };
    return PopoverInfoComponent;
}());
exports.PopoverInfoComponent = PopoverInfoComponent;
