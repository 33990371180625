"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var $ = require("jquery");
var Helpers = /** @class */ (function () {
    function Helpers() {
    }
    Helpers.loadStyles = function (tag, src) {
        if (Array.isArray(src)) {
            $.each(src, function (k, s) {
                $(tag).append($('<link/>').attr('href', s).attr('rel', 'stylesheet').attr('type', 'text/css'));
            });
        }
        else {
            $(tag).append($('<link/>').attr('href', src).attr('rel', 'stylesheet').attr('type', 'text/css'));
        }
    };
    Helpers.unwrapTag = function (element) {
        $(element).removeAttr('appunwraptag').unwrap();
    };
    Helpers.setLoading = function (enable) {
        var body = $('body');
        if (enable) {
            $(body).addClass('m-page--loading-non-block');
        }
        else {
            $(body).removeClass('m-page--loading-non-block');
        }
    };
    Helpers.bodyClass = function (strClass) {
        $('body').attr('class', strClass);
    };
    return Helpers;
}());
exports.Helpers = Helpers;
