<div class="modal-header">
  <div class="modal-title h4">Set Date Format</div>
</div>
<div class="modal-body">
  <form id="date-format-form" class="form" [formGroup]="formGroup" (ngSubmit)="save()">
    <div class="row">
      <div class="col-lg-12 margin-bottom-10">
        <label>Date Format</label>
        <input
          title
          type="text"
          class="form-control form-control-lg form-control-solid"
          autocomplete="on"
          name="Filemask"
          [(ngModel)]="dateFormat"
          [ngModelOptions]="{standalone: true}"
          disabled
        />
      </div>
      <div class="margin-top-5 col-lg-6 border-right">
        <div class="form-group row margin-top-5" >
          <div class="col-lg-6">
            <label>Symbol Type:</label>
          </div>
          <div class="col-lg-12">
            <button type="button"
                    [disabled]="type === enumDataType.DateTimestamp"
                    (click)="addSymbol(enumDateSymbol.colon)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Colon (:)
            </button>
            <button type="button"
                    [disabled]="type === enumDataType.DateTimestamp"
                    (click)="addSymbol(enumDateSymbol.dot)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Dot (.)
            </button>
            <button type="button"
                    [disabled]="type === enumDataType.DateTimestamp"
                    (click)="addSymbol(enumDateSymbol.minus)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Minus (-)
            </button>
            <button type="button"
                    [disabled]="type === enumDataType.DateTimestamp"
                    (click)="addSymbol(enumDateSymbol.slash)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Slash (/)
            </button>
            <button type="button"
                    [disabled]="type === enumDataType.DateTimestamp"
                    (click)="addSymbol(enumDateSymbol.space)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Space ( )
            </button>
          </div>
        </div>
        <div class="form-group row margin-top-5">
          <div class="col-lg-6">
            <label>Date Format:</label>
          </div>
          <div class="col-lg-12">
            <button type="button"
                    *ngIf="type === enumDataType.DateDate || type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.yyyy)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.yyyy)"
                    [disabled]="checkDisableDateFormat('YEAR')"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>yyyy
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDate || type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.yy)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.yy)"
                    [disabled]="checkDisableDateFormat('YEAR')"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>yy
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDate || type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.MM)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.MM)"
                    [disabled]="checkDisableDateFormat('MONTH')"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>MM
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDate || type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.MMM)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.MMM)"
                    [disabled]="checkDisableDateFormat('MONTH')"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>MMM
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDate || type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.MMMM)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.MMMM)"
                    [disabled]="checkDisableDateFormat('MONTH')"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>MMMM
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDate || type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.dd)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.dd)"
                    [disabled]="checkDisableDateFormat(enumDateFormat.dd)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>dd
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.HH)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.HH)"
                    [disabled]="checkDisableDateFormat(enumDateFormat.HH)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>HH
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.mm)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.mm)"
                    [disabled]="checkDisableDateFormat(enumDateFormat.mm)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>mm
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.ss)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.ss)"
                    [disabled]="checkDisableDateFormat(enumDateFormat.ss)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>ss
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.SSS)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.SSS)"
                    [disabled]="checkDisableDateFormat(enumDateFormat.SSS)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>SSS
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateDatetime"
                    (click)="addDateFormat(enumDateFormat.z)"
                    ngbPopover="{{dateFormatPopContent}}"
                    popoverTitle="{{dateFormatPopTitle}}"
                    triggers="mouseenter:mouseleave"
                    (mouseenter)="loadDateFormatPopover(enumDateFormat.z)"
                    [disabled]="checkDisableDateFormat(enumDateFormat.z)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>z
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateTimestamp"
                    (click)="addDateFormat(enumDateFormat.timestamp10)"
                    [disabled]="checkDisableDateFormat('TIMESTAMP')"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Time Stamp (10 digits)
            </button>
            <button type="button"
                    *ngIf="type === enumDataType.DateTimestamp"
                    (click)="addDateFormat(enumDateFormat.timestamp13)"
                    [disabled]="checkDisableDateFormat('TIMESTAMP')"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Time Stamp (13 digits)
            </button>
          </div>
        </div>
      </div>
      <div class="margin-top-5 col-lg-6">
        <div class="form-group row margin-top-5">
          <div class="col-lg-6" *ngIf="dateFormatSelectList.length != 0">
            <label>Date Format Select List:</label>
            <ul>
              <li *ngFor="let fmBean of dateFormatSelectList; let i = index;">
                  {{fmBean.type}}
                <a title="Delete custom field"
                   class="btn btn-icon btn-light xx btn-hover-secondary btn-sm mx-3"
                   (click)="deleteArg(i)"
                >
                  <span
                    [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-md secondary"
                  >
                  </span>
                </a>
              </li>
            </ul>
            <a title="Clear All Argument"
               class="btn xx btn-danger xx btn-elevate"
               (click)="clearAllArg()"
            >
                  Clear All
            </a>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">
    Cancel
  </button>&nbsp;
  <button type="submit" class="btn xx btn-danger xx btn-elevate" form="date-format-form">
    Save
  </button>
</div>

