import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContextDataResolver} from "./context-data.resolver";
import {ThemeComponent} from "./theme.component";

// two way for load module
// 1. preLoad: loadChildren: () => import().then()
// 2. lazyLoad: loadChildren: ""

const routes: Routes = [
  {
    path: '',
    component: ThemeComponent,
    resolve: {
      rsContextData: ContextDataResolver
    },
    children: [
      // {
      //   path: '',
      //   redirectTo: 'dashboard',
      //   pathMatch: 'full',
      // },
      // {path: "error", loadChildren: "./pages/error/error.module#ErrorsModule"},
      // {path: "documentation/:type", loadChildren: "./pages/documentation/documentation.module#DocumentationModule"},
      {path: "dashboard", loadChildren: "./pages/dashboard/dashboard.module#DashboardModule"},
      {path: "notification/:type", loadChildren: "./pages/notificationDashboard/notificationDashboard.module#NotificationDashboardModule"},
      {path: "data-ware-house", loadChildren: "./pages/dataWareHouse/dataWareHouseMain.module#DataWareHouseMainModule"},
      {path: "data-pipeline", loadChildren: "./pages/dataPipeline/dataPipelineMain.module#DataPipelineMainModule"},
      {path: "user-group", loadChildren: "./pages/userGroup/userGroupMain.module#UserGroupMainModule"},
      {path: "access-control", loadChildren: "./pages/accessControl/accessControl.module#AccessControlModule"},
      {path: "admin-setting/:type", loadChildren: "./pages/adminSetting/adminSettingMain.module#AdminSettingMainModule"},
      {path: "smart-query", loadChildren: "./pages/smartQuery/smartQueryMain.module#SmartQueryMainModule"},
      {path: "smart-search", loadChildren: "./pages/customerDashboard/customerDashboardMain.module#CustomerDashboardMainModule"},
      {path: "account", loadChildren: "./pages/account/accountMain.module#AccountMainModule"},

      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      // },
      // {
      //   path: 'data-ware-house',
      //   loadChildren: () =>
      //     import('./pages/dataWareHouse/dataWareHouseMain.module').then((m) => m.DataWareHouseMainModule),
      // },
      // {
      //   path: 'data-pipeline',
      //   loadChildren: () =>
      //     import('./pages/dataPipeline/dataPipelineMain.module').then((m) => m.DataPipelineMainModule),
      // },
      // {
      //   path: 'userGroup',
      //   loadChildren: () =>
      //     import('./pages/userGroup/adminMain.module').then((m) => m.UserGroupMainModule),
      // },
      // {
      //   path: 'smart-query',
      //   loadChildren: () =>
      //     import('./pages/smartQuery/smartQueryMain.module').then((m) => m.SmartQueryMainModule),
      // },
      // {
      //   path: 'account',
      //   loadChildren: () =>
      //     import('./pages/account/accountMain.module').then((m) => m.AccountMainModule),
      // },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  // declarations: [ThemeComponent],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    ContextDataResolver,
  ]
})
export class ThemeRoutingModule { }
