"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumMainPageRoute;
(function (EnumMainPageRoute) {
    EnumMainPageRoute["Auth"] = "auth";
    EnumMainPageRoute["Dashboard"] = "dashboard";
    EnumMainPageRoute["DataWarehouse"] = "data-ware-house";
    EnumMainPageRoute["DataPipeline"] = "data-pipeline";
    EnumMainPageRoute["SmartQuery"] = "smart-query";
    EnumMainPageRoute["SmartSearch"] = "smart-search";
    EnumMainPageRoute["UserGroup"] = "user-group";
    EnumMainPageRoute["AccessControl"] = "access-control";
    EnumMainPageRoute["Account"] = "account";
    EnumMainPageRoute["AdminSetting"] = "admin-setting";
    EnumMainPageRoute["Notification"] = "notification";
    EnumMainPageRoute["Error"] = "error";
})(EnumMainPageRoute = exports.EnumMainPageRoute || (exports.EnumMainPageRoute = {}));
var EnumAuthPageRoute;
(function (EnumAuthPageRoute) {
    EnumAuthPageRoute["Login"] = "login";
})(EnumAuthPageRoute = exports.EnumAuthPageRoute || (exports.EnumAuthPageRoute = {}));
var EnumPipelinePageRoute;
(function (EnumPipelinePageRoute) {
    EnumPipelinePageRoute["AccessRight"] = "access-right";
    EnumPipelinePageRoute["PipelineFlow"] = "pipeline-flow";
    EnumPipelinePageRoute["PipelineFlowEdit"] = "pipeline-flow-edit";
    EnumPipelinePageRoute["RecycleBin"] = "recycle-bin";
    EnumPipelinePageRoute["Edit"] = "edit";
    EnumPipelinePageRoute["Console"] = "console";
    EnumPipelinePageRoute["Preview"] = "preview";
    EnumPipelinePageRoute["History"] = "history";
})(EnumPipelinePageRoute = exports.EnumPipelinePageRoute || (exports.EnumPipelinePageRoute = {}));
var EnumDataWarehousePageRoute;
(function (EnumDataWarehousePageRoute) {
    EnumDataWarehousePageRoute["RecycleBin"] = "recycle-bin";
    EnumDataWarehousePageRoute["DataView"] = "data-view";
    EnumDataWarehousePageRoute["QuerySample"] = "query-sample";
    EnumDataWarehousePageRoute["DataEntity"] = "data-entity";
    EnumDataWarehousePageRoute["FileUpload"] = "file-upload";
    EnumDataWarehousePageRoute["DataSchema"] = "data-schema";
    EnumDataWarehousePageRoute["History"] = "history";
    EnumDataWarehousePageRoute["AccessRight"] = "access-right";
    EnumDataWarehousePageRoute["EntityArchive"] = "entity-archive";
    EnumDataWarehousePageRoute["EntityRelation"] = "entity-relation";
    EnumDataWarehousePageRoute["EntityRelationCreate"] = "entity-relation-create";
    EnumDataWarehousePageRoute["EntityRelationEdit"] = "entity-relation-edit";
    EnumDataWarehousePageRoute["ApiFetching"] = "api-fetching";
})(EnumDataWarehousePageRoute = exports.EnumDataWarehousePageRoute || (exports.EnumDataWarehousePageRoute = {}));
var EnumSmartQueryPageRoute;
(function (EnumSmartQueryPageRoute) {
    EnumSmartQueryPageRoute["SmartQueryEditing"] = "smart-query-editing";
    EnumSmartQueryPageRoute["SmartQueryView"] = "smart-query-view";
    EnumSmartQueryPageRoute["AccessRight"] = "access-right";
})(EnumSmartQueryPageRoute = exports.EnumSmartQueryPageRoute || (exports.EnumSmartQueryPageRoute = {}));
var EnumUserGroupPageRoute;
(function (EnumUserGroupPageRoute) {
    EnumUserGroupPageRoute["GroupDetailsManagement"] = "group-details-management";
    EnumUserGroupPageRoute["UserDetailsManagement"] = "user-details-management";
    EnumUserGroupPageRoute["AccessRightManagement"] = "access-right-management";
    EnumUserGroupPageRoute["CreateUser"] = "create-user";
})(EnumUserGroupPageRoute = exports.EnumUserGroupPageRoute || (exports.EnumUserGroupPageRoute = {}));
var EnumAccountPageRoute;
(function (EnumAccountPageRoute) {
    EnumAccountPageRoute["MyProfile"] = "my-profile";
})(EnumAccountPageRoute = exports.EnumAccountPageRoute || (exports.EnumAccountPageRoute = {}));
