"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".role-color[_ngcontent-%COMP%]{background-color:#ffe2e5;color:#f64e60}div[_ngcontent-%COMP%]   .btn[_ngcontent-%COMP%]   .top-bar-badge[_ngcontent-%COMP%]{padding:4px;position:absolute;right:-2px;min-width:10px;font-size:10px;top:2px;margin:inherit}"];
exports.styles = styles;
