"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ToastrService = /** @class */ (function () {
    function ToastrService() {
        this.options = {
            'closeButton': true,
            'timeOut': 3000,
            'extendedTimeOut': 0,
            'tapToDismiss': false,
            'showDuration': 500,
            'hideDuration': 500
        };
    }
    ToastrService.prototype.success = function (message) {
        toastr.options = this.options;
        toastr['success'](message, 'Success');
    };
    ToastrService.prototype.error = function (message) {
        toastr.options = this.options;
        toastr['error'](message, 'Error');
    };
    ToastrService.prototype.info = function (message) {
        toastr.options = this.options;
        toastr['info'](message, 'Info');
    };
    ToastrService.prototype.warning = function (message) {
        toastr.options = this.options;
        toastr['warning'](message, 'Warning');
    };
    ToastrService.prototype.readonly = function (message) {
        var options = {
            'closeButton': true,
            'timeOut': 20000,
            'extendedTimeOut': 0,
            'tapToDismiss': false,
            'showDuration': 500,
            'hideDuration': 500
        };
        toastr.options = options;
        toastr['warning'](message, 'Warning');
    };
    return ToastrService;
}());
exports.ToastrService = ToastrService;
