"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var GlobalErrorHandler = /** @class */ (function () {
    function GlobalErrorHandler() {
    }
    GlobalErrorHandler.prototype.handleError = function (error) {
    };
    GlobalErrorHandler.prototype.handlerRoleAccessError = function () {
        var errorMessage = "Role Access Denied !";
        GlobalErrorHandler.info("Restricted Access!!", "Sorry, you are not authenticated to access this pages. Please notify your organization admin for further access.", function () { return console.log("Confirmed !"); });
        return rxjs_1.throwError(errorMessage);
    };
    GlobalErrorHandler.info = function (title, text, cbFn) {
        var alert = swal({
            html: text,
            text: text,
            title: title,
            type: 'info',
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonClass: 'btn-info',
            closeOnConfirm: true,
            confirmButtonText: 'Confirm'
        });
        try {
            alert;
        }
        catch (e) {
            console.log(e);
        }
        if (cbFn) {
            cbFn();
        }
    };
    return GlobalErrorHandler;
}());
exports.GlobalErrorHandler = GlobalErrorHandler;
