"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("../../../../../_metronic/core");
var notification_enum_1 = require("../../../../../enums/notification.enum");
var router_1 = require("@angular/router");
var router_enum_1 = require("../../../../../enums/router.enum");
var NotificationsDropdownComponent = /** @class */ (function () {
    function NotificationsDropdownComponent(layout, router) {
        this.layout = layout;
        this.router = router;
        this.notificationUpdateNum = new core_1.EventEmitter();
        this.extrasNotificationsDropdownStyle = 'dark';
        this.activeTabId = 'topbar_notifications_alerts';
        this.hover = false;
        this.isLoading = false;
        this.config = {
            minScrollbarLength: 100,
            maxScrollbarLength: 500
        };
        this.ts = new Date().getTime();
    }
    Object.defineProperty(NotificationsDropdownComponent.prototype, "enumNotificationRealm", {
        get: function () {
            return notification_enum_1.EnumNotificationRealm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationsDropdownComponent.prototype, "enumNotificationType", {
        get: function () {
            return notification_enum_1.EnumNotificationType;
        },
        enumerable: true,
        configurable: true
    });
    NotificationsDropdownComponent.prototype.ngOnInit = function () {
        this.extrasNotificationsDropdownStyle = this.layout.getProp('extras.notifications.dropdown.style');
    };
    NotificationsDropdownComponent.prototype.setActiveTabId = function (tabId) {
        this.activeTabId = tabId;
    };
    NotificationsDropdownComponent.prototype.getActiveCSSClasses = function (tabId) {
        if (tabId !== this.activeTabId) {
            return '';
        }
        return 'active show';
    };
    NotificationsDropdownComponent.prototype.navigateToNotificationDashboard = function (type) {
        this.router.navigate(["/" + router_enum_1.EnumMainPageRoute.Notification, type.toLowerCase()]).then();
    };
    NotificationsDropdownComponent.prototype.isChecked = function (type, index) {
        switch (type) {
            case notification_enum_1.EnumNotificationType.Alert:
                this.alertNotice[index].isChecked = !this.alertNotice[index].isChecked;
                break;
            case notification_enum_1.EnumNotificationType.Event:
                this.eventNotice[index].isChecked = !this.eventNotice[index].isChecked;
                break;
            case notification_enum_1.EnumNotificationType.Log:
            default:
                this.logNotice[index].isChecked = !this.logNotice[index].isChecked;
                break;
        }
    };
    return NotificationsDropdownComponent;
}());
exports.NotificationsDropdownComponent = NotificationsDropdownComponent;
