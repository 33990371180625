"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { TranslationService } from './modules/i18n/translation.service';
// language list
// import { locale as enLang } from './modules/i18n/vocabs/en';
// import { locale as chLang } from './modules/i18n/vocabs/ch';
// import { locale as esLang } from './modules/i18n/vocabs/es';
// import { locale as jpLang } from './modules/i18n/vocabs/jp';
// import { locale as deLang } from './modules/i18n/vocabs/de';
// import { locale as frLang } from './modules/i18n/vocabs/fr';
var splash_screen_service_1 = require("./shared/components/splash-screen/splash-screen.service");
var router_1 = require("@angular/router");
var splash_routing_service_1 = require("./shared/components/splash-routing/splash-routing.service");
var AppComponent = /** @class */ (function () {
    function AppComponent(
    // private translationService: TranslationService,
    splashScreenService, splashRoutingService, router) {
        this.splashScreenService = splashScreenService;
        this.splashRoutingService = splashRoutingService;
        this.router = router;
        // register translations
        // this.translationService.loadTranslations(
        //   enLang,
        //   chLang,
        //   // esLang,
        //   // jpLang,
        //   // deLang,
        //   // frLang
        // );
        this.routerSubscribeEvent();
    }
    AppComponent.prototype.routerSubscribeEvent = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            switch (true) {
                case event instanceof router_1.NavigationStart:
                    _this.splashRoutingService.showSplashRouting(true);
                    break;
                case event instanceof router_1.NavigationEnd:
                case event instanceof router_1.NavigationCancel:
                case event instanceof router_1.NavigationError:
                    _this.splashRoutingService.showSplashRouting(false);
                    _this.splashScreenService.hide();
                    // scroll to top on every route change
                    window.scrollTo(0, 0);
                    // to display back the body content
                    setTimeout(function () {
                        document.body.classList.add('page-loaded');
                    }, 500);
                    break;
                default:
                    break;
            }
        });
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
