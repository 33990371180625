import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ParamState} from "../../../../shared/model-states/param.model";

@Injectable({
  providedIn: 'root'
})
export class RoleAccessService {
  private BASE_URL = '/data-canva-api/protected/roleAccess';

  constructor(private http: HttpClient) {
  }

  updateParams(paginator, sorting) {
    let params = new ParamState();
    params.draw = 1;
    params.start = (paginator.page - 1) * paginator.pageSize;
    params.length = paginator.pageSize;
    params.searchValue = "";
    params.orderColumn = sorting.column;
    params.orderDirection = sorting.direction;
    return params;
  }

  getRoleAccessStatic() {
    const url = `${this.BASE_URL}/getRoleAccessStatic`;
    return this.http.get(url);
  }

  getRoleAccess(targetRole) {
    let params = {"targetRole": targetRole}
    const url = `${this.BASE_URL}/getRoleAccess`;
    return this.http.get(url, {params});
  }

  getRoleAccessStatusList() {
    const url = `${this.BASE_URL}/getRoleAccessStatusList`
    return this.http.get(url)
  }

  saveRoleAccess(element) {
    const url = `${this.BASE_URL}/saveRoleAccess`;
    return this.http.post(url, element);
  }

}
