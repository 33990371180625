"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumFileMask;
(function (EnumFileMask) {
    EnumFileMask["prefix"] = "PREFIX";
    EnumFileMask["character"] = "CHARACTER";
    EnumFileMask["suffix"] = "SUFFIX";
    EnumFileMask["serialNo"] = "SERIAL_NO";
})(EnumFileMask = exports.EnumFileMask || (exports.EnumFileMask = {}));
var EnumDateMask;
(function (EnumDateMask) {
    EnumDateMask["YYYY"] = "%Y";
    EnumDateMask["YY"] = "%y";
    EnumDateMask["MM"] = "%m";
    EnumDateMask["DD"] = "%d";
    EnumDateMask["HH"] = "%H";
    EnumDateMask["mm"] = "%M";
    EnumDateMask["ss"] = "%S";
    EnumDateMask["timestamp13"] = "%ts13";
    EnumDateMask["timestamp10"] = "%ts10";
})(EnumDateMask = exports.EnumDateMask || (exports.EnumDateMask = {}));
