"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./scripts-init.component");
var i2 = require("../../../../_metronic/core/services/layout.service");
var i3 = require("@angular/router");
var styles_ScriptsInitComponent = [];
var RenderType_ScriptsInitComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScriptsInitComponent, data: {} });
exports.RenderType_ScriptsInitComponent = RenderType_ScriptsInitComponent;
function View_ScriptsInitComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
exports.View_ScriptsInitComponent_0 = View_ScriptsInitComponent_0;
function View_ScriptsInitComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-scripts-init", [], null, null, null, View_ScriptsInitComponent_0, RenderType_ScriptsInitComponent)), i0.ɵdid(1, 4308992, null, 0, i1.ScriptsInitComponent, [i2.LayoutService, i3.ActivatedRoute, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ScriptsInitComponent_Host_0 = View_ScriptsInitComponent_Host_0;
var ScriptsInitComponentNgFactory = i0.ɵccf("app-scripts-init", i1.ScriptsInitComponent, View_ScriptsInitComponent_Host_0, {}, {}, []);
exports.ScriptsInitComponentNgFactory = ScriptsInitComponentNgFactory;
