import {Injectable} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError,} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {Helpers} from '../helpers';
import {SweetAlertService} from './sweet-alert.service';
import {Router} from '@angular/router';
import {BreadCrumbService} from './bread-crumb.service';
import {EnumMainPageRoute} from "../enums/router.enum";

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {

    private count: number = 0;

    constructor(
        private sweetAlertService: SweetAlertService,
        private router: Router) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.count++;

        Helpers.setLoading(true);

        let token: string = localStorage.getItem('token') || '';

        let newReq = (!request.headers.get('token'))? request.clone(
          { headers: request.headers.append('token', token) }
        ) : request.clone(
          {headers: request.headers.set('token', token)}
        );

        return next.handle(newReq).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    let token = event.headers.get('token');
                    if (-1 == request.url.indexOf('logout') && token !== undefined && token !== null && token.length > 1) {
                        localStorage.setItem('token', token)
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                  case 401:
                    localStorage.clear();
                    this.sweetAlertService.info('Timeout', 'You have been logged out for security reason.', () => {
                      this.router.navigate([BreadCrumbService.login().href]).then();
                    })
                    break;
                  case 403:
                    this.sweetAlertService.info('Restricted Role Access, Forbidden Error', 'Sorry, you do not have enough access right to do this action. Please contact System Admin for further handling.',
                      () => {}
                      )
                    break;
                  case 409:
                    this.sweetAlertService.info("File not found", "Please check if the file is exist.",
                      () => {}
                      )
                    break;
                  case 417:
                    this.sweetAlertService.info("Request URL forbidden", "Please don't call http request by directly calling our system.",
                      () => {}
                    )
                    break;
                  // case 500:
                  //   this.sweetAlertService.info('Internal Server Error', 'Sorry, our server is facing some internal server issue. Please contact System Admin for further handling.',
                  //     () => {}
                  //   )
                  //   break;
                  case 504:
                    this.router.navigate([`/${EnumMainPageRoute.Error}`]).then();
                    // this.sweetAlertService.info('Gateway Timeout', 'Sorry, our server is facing some internal server issue. Please contact System Admin for further handling. ')
                    break;
                }
                // if (error.status == 401) {
                //     localStorage.clear()
                //     this.sweetAlertService.info('Timeout!!', 'Sorry, your session timed out after a long time of inactivity. Please sign in again.', () => {
                //         this.router.navigate([BreadCrumbService.login().href]);
                //     })
                //   // this.sweetAlertService.confirm('confirm!!', () => {
                //   //   console.log('ysss')
                //   //       this.router.navigate([BreadCrumbService.login().href]);
                //     // })
                //   // const popUp = this.sweetAlertService.info('Timeout!!', 'Sorry, your session timed out after a long time of inactivity. Please sign in again.')
                //   // console.log('[popUp]',popUp)
                //   // if( popUp ){
                //   //   console.log('[BreadCrumbService.login().href1]',[BreadCrumbService])
                //   //   console.log('[BreadCrumbService.login().href2]',[BreadCrumbService.login()])
                //   //   console.log('[BreadCrumbService.login().href3]',[BreadCrumbService.login().href])
                //   //   this.router.navigate([BreadCrumbService.login().href]);
                //   // }
                // }
                return throwError(error);
            }),
            finalize(() => {
                if (--this.count === 0) {
                    Helpers.setLoading(false);
                }
            })
        );
    }
}

export let GlobalHttpInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: GlobalHttpInterceptorService,
    multi: true
};
