"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var dynamic_aside_menu_config_1 = require("../../configs/dynamic-aside-menu.config");
var i0 = require("@angular/core");
var emptyMenuConfig = {
    items: []
};
var DynamicAsideMenuService = /** @class */ (function () {
    function DynamicAsideMenuService() {
        this.menuConfigSubject = new rxjs_1.BehaviorSubject(emptyMenuConfig);
        this.menuConfig$ = this.menuConfigSubject.asObservable();
        this.loadMenu();
    }
    // Here you able to load your menu from server/data-base/localStorage
    // Default => from DynamicAsideMenuConfig
    DynamicAsideMenuService.prototype.loadMenu = function () {
        this.setMenu(dynamic_aside_menu_config_1.DynamicAsideMenuConfig);
    };
    DynamicAsideMenuService.prototype.setMenu = function (menuConfig) {
        this.menuConfigSubject.next(menuConfig);
    };
    DynamicAsideMenuService.prototype.getMenu = function () {
        return this.menuConfigSubject.value;
    };
    DynamicAsideMenuService.ngInjectableDef = i0.defineInjectable({ factory: function DynamicAsideMenuService_Factory() { return new DynamicAsideMenuService(); }, token: DynamicAsideMenuService, providedIn: "root" });
    return DynamicAsideMenuService;
}());
exports.DynamicAsideMenuService = DynamicAsideMenuService;
