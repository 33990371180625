"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var core_2 = require("../../../../_metronic/core");
var header_1 = require("../../../../../assets/js/layout/base/header");
var header_menu_1 = require("../../../../../assets/js/layout/base/header-menu");
var util_1 = require("../../../../../assets/js/components/util");
var rxjs_1 = require("rxjs");
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(layout, router) {
        var _this = this;
        this.layout = layout;
        this.router = router;
        this.headerMenuHTMLAttributes = {};
        this.loaderSubject = new rxjs_1.BehaviorSubject(0);
        this.unsubscribe = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
        this.loader$ = this.loaderSubject;
        // page progress bar percentage
        var routerSubscription = this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationStart) {
                // set page progress bar loading to start on NavigationStart event router
                _this.loaderSubject.next(10);
            }
            if (event instanceof router_1.RouteConfigLoadStart) {
                _this.loaderSubject.next(65);
            }
            if (event instanceof router_1.RouteConfigLoadEnd) {
                _this.loaderSubject.next(90);
            }
            if (event instanceof router_1.NavigationEnd || event instanceof router_1.NavigationCancel) {
                // set page progress bar loading to end on NavigationEnd event router
                _this.loaderSubject.next(100);
                if (_this.routerLoaderTimout) {
                    clearTimeout(_this.routerLoaderTimout);
                }
                _this.routerLoaderTimout = setTimeout(function () {
                    _this.loaderSubject.next(0);
                }, 300);
            }
        });
        this.unsubscribe.push(routerSubscription);
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.headerContainerCSSClasses = this.layout.getStringCSSClasses('header_container');
        this.headerMenuSelfDisplay = this.layout.getProp('header.menu.self.display');
        // this.headerMenuSelfDisplay = true
        this.headerMenuSelfStatic = this.layout.getProp('header.menu.self.static');
        // this.headerMenuSelfStatic = true;
        this.asideSelfDisplay = this.layout.getProp('aside.self.display');
        this.asideSelfDisplay = true;
        this.headerSelfTheme = this.layout.getProp('header.self.theme') || '';
        this.headerLogo = this.getLogoURL();
        this.headerMenuCSSClasses = this.layout.getStringCSSClasses('header_menu');
        this.headerMenuHTMLAttributes = this.layout.getHTMLAttributes('header_menu');
    };
    HeaderComponent.prototype.getLogoURL = function () {
        var result = 'logo-light.png';
        if (this.headerSelfTheme && this.headerSelfTheme === 'light') {
            result = 'logo-dark.png';
        }
        if (this.headerSelfTheme && this.headerSelfTheme === 'dark') {
            result = 'logo-dark.png';
        }
        return "./assets/media/logos/" + result;
    };
    HeaderComponent.prototype.ngAfterViewInit = function () {
        if (this.ktHeaderMenu) {
            for (var key in this.headerMenuHTMLAttributes) {
                if (this.headerMenuHTMLAttributes.hasOwnProperty(key)) {
                    this.ktHeaderMenu.nativeElement.attributes[key] = this.headerMenuHTMLAttributes[key];
                }
            }
        }
        util_1.KTUtil.ready(function () {
            // Init Desktop & Mobile Headers
            header_1.default.init('kt_header', 'kt_header_mobile');
            // Init Header Menu
            header_menu_1.default.init('kt_header_menu', 'kt_header_menu_wrapper');
        });
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.forEach(function (sb) { return sb.unsubscribe(); });
        if (this.routerLoaderTimout) {
            clearTimeout(this.routerLoaderTimout);
        }
    };
    return HeaderComponent;
}());
exports.HeaderComponent = HeaderComponent;
