"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var filemask_enum_1 = require("../../../enums/filemask.enum");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var forms_1 = require("@angular/forms");
var form_control_helper_1 = require("../../helpers/form-control.helper");
var SetFilemaskModalComponent = /** @class */ (function () {
    function SetFilemaskModalComponent(modal, fb, formControlHelper) {
        this.modal = modal;
        this.fb = fb;
        this.formControlHelper = formControlHelper;
    }
    Object.defineProperty(SetFilemaskModalComponent.prototype, "enumFileMask", {
        get: function () {
            return filemask_enum_1.EnumFileMask;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetFilemaskModalComponent.prototype, "enumDateMask", {
        get: function () {
            return filemask_enum_1.EnumDateMask;
        },
        enumerable: true,
        configurable: true
    });
    SetFilemaskModalComponent.prototype.ngOnInit = function () {
        this.formGroup = this.fb.group({
            filemask: [this.filemask, forms_1.Validators.compose([forms_1.Validators.required])],
            prefixInput: [""],
            character: [""],
            serialNumLength: [""],
            suffix: [""],
            fileMaskSelectList: [""],
        });
    };
    SetFilemaskModalComponent.prototype.addDate = function (dateFormat) {
        this.filemask = (typeof this.filemask == "undefined") ? "" : this.filemask;
        var fmBean = { "type": dateFormat, "value": dateFormat };
        this.filemask += dateFormat;
        this.fileMaskSelectList.push(fmBean);
        this.formGroup.patchValue({ filemask: this.filemask, fileMaskSelectList: this.fileMaskSelectList });
    };
    SetFilemaskModalComponent.prototype.addExpression = function (expression) {
        this.expression = expression;
        var prefixInputControl = this.formGroup.get("prefixInput");
        var characterControl = this.formGroup.get("character");
        var serialNumLengthControl = this.formGroup.get("serialNumLength");
        var suffixControl = this.formGroup.get("suffix");
        var filenameRegex = new RegExp(/^[0-9a-zA-Z ... ]+$/);
        switch (this.expression) {
            case filemask_enum_1.EnumFileMask.prefix:
                prefixInputControl.setValidators([forms_1.Validators.required, ValidatePrefix]);
                characterControl.setValidators(null);
                serialNumLengthControl.setValidators(null);
                suffixControl.setValidators(null);
                break;
            case filemask_enum_1.EnumFileMask.character:
                prefixInputControl.setValidators(null);
                characterControl.setValidators([forms_1.Validators.required, ValidateCharacter]);
                serialNumLengthControl.setValidators(null);
                suffixControl.setValidators(null);
                break;
            case filemask_enum_1.EnumFileMask.serialNo:
                prefixInputControl.setValidators(null);
                characterControl.setValidators(null);
                serialNumLengthControl.setValidators([forms_1.Validators.required, ValidateNumLength]);
                suffixControl.setValidators(null);
                break;
            case filemask_enum_1.EnumFileMask.suffix:
                prefixInputControl.setValidators(null);
                characterControl.setValidators(null);
                serialNumLengthControl.setValidators(null);
                suffixControl.setValidators([forms_1.Validators.required]);
                break;
        }
        prefixInputControl.updateValueAndValidity();
        characterControl.updateValueAndValidity();
        serialNumLengthControl.updateValueAndValidity();
        suffixControl.updateValueAndValidity();
    };
    SetFilemaskModalComponent.prototype.add = function () {
        this.filemask = (this.filemask == null) ? "" : this.filemask;
        var fmBean = { "type": "", "value": "" };
        switch (this.expression) {
            case filemask_enum_1.EnumFileMask.prefix:
                this.isPrefix = true;
                this.filemask += this.formGroup.value.prefixInput;
                fmBean.type = this.expression;
                fmBean.value = this.formGroup.value.prefixInput;
                break;
            case filemask_enum_1.EnumFileMask.character:
                this.filemask += this.formGroup.value.character;
                fmBean.type = this.expression;
                fmBean.value = this.formGroup.value.character;
                break;
            case filemask_enum_1.EnumFileMask.serialNo:
                this.isSerialNo = true;
                // let tempSerialNumLen = "0".substring(0, 2 - this.formGroup.value.serialNumLength.length) + this.formGroup.value.serialNumLength;
                this.filemask += "%s" + this.formGroup.value.serialNumLength;
                fmBean.type = this.expression;
                fmBean.value = "%s" + this.formGroup.value.serialNumLength;
                break;
            case filemask_enum_1.EnumFileMask.suffix:
                this.isSuffix = true;
                this.filemask += this.formGroup.value.suffix;
                fmBean.type = this.expression;
                fmBean.value = this.formGroup.value.suffix;
                break;
        }
        this.fileMaskSelectList.push(fmBean);
        this.expression = "";
        this.formGroup.patchValue({ filemask: this.filemask, fileMaskSelectList: this.fileMaskSelectList });
    };
    SetFilemaskModalComponent.prototype.checkDisableDate = function (dateMask) {
        var tempList = this.fileMaskSelectList.filter(function (filemask) { return filemask.type == dateMask; });
        return tempList.length > 0;
    };
    SetFilemaskModalComponent.prototype.deleteArg = function (index) {
        var targetArg = this.fileMaskSelectList[index];
        switch (targetArg.type) {
            case filemask_enum_1.EnumFileMask.prefix:
                this.fileMaskSelectList.splice(index);
                this.isPrefix = false;
                break;
            case filemask_enum_1.EnumFileMask.suffix:
                this.fileMaskSelectList.splice(index, 1);
                this.isSuffix = false;
                break;
            case filemask_enum_1.EnumFileMask.serialNo:
                this.fileMaskSelectList.splice(index);
                this.isSerialNo = false;
                break;
            default:
                this.fileMaskSelectList.splice(index, 1);
                break;
        }
        var fileMaskStrList = [];
        this.fileMaskSelectList.forEach(function (filemaskStr) { return fileMaskStrList.push(filemaskStr.value); });
        this.filemask = fileMaskStrList.join('');
        this.formGroup.patchValue({ filemask: this.filemask, fileMaskSelectList: this.fileMaskSelectList });
    };
    SetFilemaskModalComponent.prototype.save = function () {
        var response = {
            fileMaskSelectList: this.formGroup.value.fileMaskSelectList,
            filemask: this.formGroup.value.filemask
        };
        this.modal.close(response);
    };
    return SetFilemaskModalComponent;
}());
exports.SetFilemaskModalComponent = SetFilemaskModalComponent;
function ValidatePrefix(control) {
    var rg1 = new RegExp(/^[^\\/:\*\?"<>\|]+$/);
    var rg2 = new RegExp(/^\./);
    var rg3 = new RegExp(/^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i);
    // let filenameRegex = new RegExp(/[\\/:*?"<>|\r\n]+/);
    if (rg1.test(control.value) && !rg2.test(control.value) && !rg3.test(control.value)) {
        return null;
    }
    return { 'prefixInvalid': true };
}
function ValidateNumLength(control) {
    var serialNumLength = control.value;
    if (serialNumLength === undefined || serialNumLength === null || serialNumLength === "") {
        return null;
    }
    if (serialNumLength > 0 && serialNumLength < 20) {
        return null;
    }
    return { 'serialLengthInvalid': true };
}
function ValidateCharacter(control) {
    var rg1 = new RegExp(/^[^\\/:\*\?"<>\|]+$/);
    var rg2 = new RegExp(/^\./);
    var rg3 = new RegExp(/^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i);
    // let filenameRegex = new RegExp(/[\\/:*?"<>|\r\n]+/);
    if (rg1.test(control.value) && !rg2.test(control.value) && !rg3.test(control.value)) {
        return null;
    }
    return { 'characterInvalid': true };
}
