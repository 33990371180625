"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var util_1 = require("../../../../../assets/js/components/util");
var aside_toggle_1 = require("../../../../../assets/js/layout/base/aside-toggle");
var sticky_card_1 = require("../../../../../assets/js/layout/base/sticky-card");
var stretched_card_1 = require("../../../../../assets/js/layout/base/stretched-card");
var core_2 = require("../../../../_metronic/core");
var brand_1 = require("../../../../../assets/js/layout/base/brand");
var aside_1 = require("../../../../../assets/js/layout/base/aside");
var aside_menu_1 = require("../../../../../assets/js/layout/base/aside-menu");
var bread_crumb_service_1 = require("../../../../global-services/bread-crumb.service");
var router_1 = require("@angular/router");
var route_name_service_1 = require("../../../../global-services/route-name.service");
var user_role_enum_1 = require("../../../../enums/user-role.enum");
var context_data_service_1 = require("../../../../global-services/context-data.service");
var route_name_enum_1 = require("../../../../enums/route-name.enum");
var ScriptsInitComponent = /** @class */ (function () {
    function ScriptsInitComponent(layout, activatedRoute, router) {
        var _this = this;
        this.layout = layout;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.asideSelfMinimizeToggle = false;
        this.EnumUserRole = user_role_enum_1.EnumUserRole;
        this.EnumRouteName = route_name_enum_1.EnumRouteName;
        // console.log('activatedRoute init',activatedRoute,activatedRoute.snapshot)
        // activatedRoute.data.subscribe(data => console.log('sub activatedRoute',data.title));
        context_data_service_1.ContextDataService.init(activatedRoute.snapshot.data.rsContextData);
        this.userRole = context_data_service_1.ContextDataService.userRole;
        // load breadcrumbs and construct custom breadcrumbs
        this.breadcrumbs$ = bread_crumb_service_1.BreadCrumbService.getBreadCrumbsObservable().subscribe(function (breadcrumbs) {
            // init custom breadcrumbs and read title
            _this.customBreadCrumbs = breadcrumbs[breadcrumbs.length - 1].customBreadCrumbs(activatedRoute);
            if (breadcrumbs.length > 0) {
                _this.title = _this.customBreadCrumbs[_this.customBreadCrumbs.length - 1].title;
            }
            // get current route name
            _this.currentRouteName = route_name_service_1.RouteNameService.getCurrentRouteName(_this.activatedRoute);
        });
    }
    ScriptsInitComponent.prototype.ngOnInit = function () {
        // console.log('activatedRoute init2',this.activatedRoute,this.activatedRoute.snapshot)
        context_data_service_1.ContextDataService.init(this.activatedRoute.snapshot.data.rsContextData);
        this.asideSelfMinimizeToggle = this.layout.getProp('aside.self.minimize.toggle');
    };
    ScriptsInitComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        util_1.KTUtil.ready(function () {
            // Init Brand Panel For Logo
            brand_1.default.init('kt_brand');
            // Init Aside
            aside_1.default.init('kt_aside');
            // Init Aside Menu
            aside_menu_1.default.init('kt_aside_menu');
            if (_this.asideSelfMinimizeToggle) {
                // Init Aside Menu Toggle
                aside_toggle_1.default.init('kt_aside_toggle');
            }
            // Init Sticky Card
            sticky_card_1.default.init('kt_page_sticky_card');
            // Init Stretched Card
            stretched_card_1.default.init('kt_page_stretched_card');
        });
    };
    return ScriptsInitComponent;
}());
exports.ScriptsInitComponent = ScriptsInitComponent;
