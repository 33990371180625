"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var RouteService = /** @class */ (function () {
    function RouteService(router) {
        this.router = router;
    }
    RouteService.prototype.navigateTo = function (commands, extras) {
        this.router.navigate(commands, extras);
    };
    return RouteService;
}());
exports.RouteService = RouteService;
