export enum EnumFileMask {
  prefix = "PREFIX",
  character = "CHARACTER",
  suffix = "SUFFIX",
  serialNo = "SERIAL_NO"
}

export enum EnumDateMask {
  YYYY = "%Y",
  YY = "%y",
  MM = "%m",
  DD = "%d",
  HH = "%H",
  mm = "%M",
  ss = "%S",
  timestamp13 = "%ts13",
  timestamp10 = "%ts10"
}
