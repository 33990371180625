import { IBreadCrumb } from '../interfaces/custom/bread-crumb.interface';
import {
    ActivatedRoute,
    PRIMARY_OUTLET
} from '@angular/router';
import { EnumRouteName } from '../enums/route-name.enum';

export class RouteNameService {


    // get current route name
    public static getCurrentRouteName(activatedRoute: ActivatedRoute): EnumRouteName {

        let breadcrumbs: IBreadCrumb[] = [];
        let children: ActivatedRoute[] = activatedRoute.root.children;

        // return if there are no more children
        if (children.length === 0) {
            return;
        }

        let child = activatedRoute.firstChild;

        // iterate over each children
        while (child != undefined) {

            if (child.outlet == PRIMARY_OUTLET && child.snapshot.data.hasOwnProperty('breadcrumb')) {
                // add breadcrumb
                breadcrumbs.push(child.snapshot.data.breadcrumb);
            }

            child = child.firstChild;
        }

        return breadcrumbs[breadcrumbs.length - 1].route;
    }
}
