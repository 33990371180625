"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var data_type_enum_1 = require("../../../enums/data-type.enum");
var smart_query_enum_1 = require("../../../enums/smart-query.enum");
var custom_field_model_1 = require("../../model-states/custom-field.model");
var i0 = require("@angular/core");
var CustomFieldMappingService = /** @class */ (function () {
    function CustomFieldMappingService() {
    }
    CustomFieldMappingService.prototype.operatorMappingArgNum = function (operator) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47;
        var argObj = new custom_field_model_1.CustomFieldState();
        var select = smart_query_enum_1.EnumArgumentType.Select;
        var input = smart_query_enum_1.EnumArgumentType.Input;
        var numericalChoice = [data_type_enum_1.EnumDataType.Int, data_type_enum_1.EnumDataType.Long, data_type_enum_1.EnumDataType.Double, data_type_enum_1.EnumDataType.Float];
        var intChoice = [data_type_enum_1.EnumDataType.Int, data_type_enum_1.EnumDataType.Long];
        var booleanChoice = [data_type_enum_1.EnumDataType.Boolean];
        var stringChoice = [data_type_enum_1.EnumDataType.String, data_type_enum_1.EnumDataType.Categorical];
        var dateChoice = [data_type_enum_1.EnumDataType.DateDate, data_type_enum_1.EnumDataType.DateDatetime, data_type_enum_1.EnumDataType.DateTimestamp];
        var anyChoice = [data_type_enum_1.EnumDataType.Long, data_type_enum_1.EnumDataType.Double, data_type_enum_1.EnumDataType.String,
            data_type_enum_1.EnumDataType.Boolean, data_type_enum_1.EnumDataType.DateDate,
            data_type_enum_1.EnumDataType.DateDatetime, data_type_enum_1.EnumDataType.DateTimestamp,
            data_type_enum_1.EnumDataType.Categorical];
        var numberHolder = "<number>";
        var stringHolder = "<string>";
        var dateHolder = "<date>";
        switch (operator) {
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Absolute:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.unlimitedArgNum = false;
                argObj.restrictedFirstArgType = true;
                argObj.argType.push(select);
                (_a = argObj.outputDataTypeChoice).push.apply(_a, intChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Add:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 10;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = true;
                (_b = argObj.outputDataTypeChoice).push.apply(_b, numericalChoice);
                (_c = argObj.placeholder).push.apply(_c, Array(10).fill(numberHolder));
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Acos:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_d = argObj.outputDataTypeChoice).push.apply(_d, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Asin:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_e = argObj.outputDataTypeChoice).push.apply(_e, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Atan:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_f = argObj.outputDataTypeChoice).push.apply(_f, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Ceiling:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_g = argObj.outputDataTypeChoice).push.apply(_g, intChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Cosine:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_h = argObj.outputDataTypeChoice).push.apply(_h, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Divide:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                (_j = argObj.outputDataTypeChoice).push.apply(_j, numericalChoice);
                argObj.placeholder.push(numberHolder, numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Exponential:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_k = argObj.outputDataTypeChoice).push.apply(_k, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Floor:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_l = argObj.outputDataTypeChoice).push.apply(_l, intChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Natural_log:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_m = argObj.outputDataTypeChoice).push.apply(_m, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Log:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select, input);
                (_o = argObj.outputDataTypeChoice).push.apply(_o, numericalChoice);
                argObj.placeholder.push(numberHolder, "<base>");
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Log10:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_p = argObj.outputDataTypeChoice).push.apply(_p, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Mode:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                (_q = argObj.outputDataTypeChoice).push.apply(_q, intChoice);
                argObj.placeholder.push(numberHolder, numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Multiply:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 10;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = true;
                (_r = argObj.outputDataTypeChoice).push.apply(_r, numericalChoice);
                (_s = argObj.placeholder).push.apply(_s, Array(10).fill(numberHolder));
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Power:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_t = argObj.outputDataTypeChoice).push.apply(_t, numericalChoice);
                argObj.placeholder.push(numberHolder, "<exponent>");
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Round:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_u = argObj.outputDataTypeChoice).push.apply(_u, numericalChoice);
                argObj.placeholder.push(numberHolder, "<place>");
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Sine:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_v = argObj.outputDataTypeChoice).push.apply(_v, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Square:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_w = argObj.outputDataTypeChoice).push.apply(_w, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Subtract:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                (_x = argObj.outputDataTypeChoice).push.apply(_x, numericalChoice);
                argObj.placeholder.push(numberHolder, numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Tangent:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_y = argObj.outputDataTypeChoice).push.apply(_y, numericalChoice);
                argObj.placeholder.push(numberHolder);
                break;
            case data_type_enum_1.EnumCustomFieldNumericalOperator.Truncate:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select, input);
                (_z = argObj.outputDataTypeChoice).push.apply(_z, numericalChoice);
                argObj.placeholder.push(numberHolder, "<place>");
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.DayOfYear:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_0 = argObj.outputDataTypeChoice).push.apply(_0, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.DayOfMonth:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_1 = argObj.outputDataTypeChoice).push.apply(_1, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.DayOfWeek:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_2 = argObj.outputDataTypeChoice).push.apply(_2, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.Year:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_3 = argObj.outputDataTypeChoice).push.apply(_3, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.Month:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_4 = argObj.outputDataTypeChoice).push.apply(_4, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.Week:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_5 = argObj.outputDataTypeChoice).push.apply(_5, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.Hour:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_6 = argObj.outputDataTypeChoice).push.apply(_6, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.Minute:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_7 = argObj.outputDataTypeChoice).push.apply(_7, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.Second:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_8 = argObj.outputDataTypeChoice).push.apply(_8, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.Millisecond:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_9 = argObj.outputDataTypeChoice).push.apply(_9, intChoice);
                argObj.placeholder.push(dateHolder);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.DateToString:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 4;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select, input, input, input);
                (_10 = argObj.outputDataTypeChoice).push.apply(_10, stringChoice);
                argObj.placeholder.push("<date>", "<format>", "<timezone>", "<onNull>");
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.Add:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select, input);
                (_11 = argObj.outputDataTypeChoice).push.apply(_11, dateChoice);
                argObj.placeholder.push("<date>", "<milliseconds>");
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.DateAdd:
                argObj.minArgNum = 3;
                argObj.maxArgNum = 4;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_12 = argObj.outputDataTypeChoice).push.apply(_12, dateChoice);
                argObj.placeholder.push("<startDate>", "<unit>", "<amount>", "<timezone>");
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.Now:
                argObj.minArgNum = 0;
                argObj.maxArgNum = 0;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                (_13 = argObj.outputDataTypeChoice).push.apply(_13, dateChoice);
                break;
            case data_type_enum_1.EnumCustomFieldDateOperator.DateDiff:
                argObj.minArgNum = 3;
                argObj.maxArgNum = 4;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_14 = argObj.outputDataTypeChoice).push.apply(_14, intChoice);
                argObj.placeholder.push("<startDate>", "<endDate>", "<unit>", "<timezone>", "<startOfWeek>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.Concat:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 10;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = true;
                (_15 = argObj.outputDataTypeChoice).push.apply(_15, stringChoice);
                (_16 = argObj.placeholder).push.apply(_16, Array(10).fill(stringHolder));
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.IndexOfCP:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 4;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_17 = argObj.outputDataTypeChoice).push.apply(_17, intChoice);
                argObj.placeholder.push(stringHolder, "<substring>", "<startIndex>", "<endIndex>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.StrLenCP:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_18 = argObj.outputDataTypeChoice).push.apply(_18, intChoice);
                argObj.placeholder.push(stringHolder);
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.ToLower:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_19 = argObj.outputDataTypeChoice).push.apply(_19, stringChoice);
                argObj.placeholder.push(stringHolder);
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.ToUpper:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_20 = argObj.outputDataTypeChoice).push.apply(_20, stringChoice);
                argObj.placeholder.push(stringHolder);
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.L_PAD:
                argObj.minArgNum = 3;
                argObj.maxArgNum = 3;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select, input, input);
                (_21 = argObj.outputDataTypeChoice).push.apply(_21, stringChoice);
                argObj.placeholder.push(stringHolder, "<finalPaddedLength>", "<padding>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.R_PAD:
                argObj.minArgNum = 3;
                argObj.maxArgNum = 3;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select, input, input);
                (_22 = argObj.outputDataTypeChoice).push.apply(_22, stringChoice);
                argObj.placeholder.push(stringHolder, "<finalPaddedLength>", "<padding>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.LTrim:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_23 = argObj.outputDataTypeChoice).push.apply(_23, stringChoice);
                argObj.placeholder.push(stringHolder, "<trimCharacters>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.RTrim:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_24 = argObj.outputDataTypeChoice).push.apply(_24, stringChoice);
                argObj.placeholder.push(stringHolder, "<trimCharacters>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.Trim:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_25 = argObj.outputDataTypeChoice).push.apply(_25, stringChoice);
                argObj.placeholder.push(stringHolder, "<trimCharacters>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.ReplaceOne:
                argObj.minArgNum = 3;
                argObj.maxArgNum = 3;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select, input, input);
                (_26 = argObj.outputDataTypeChoice).push.apply(_26, stringChoice);
                argObj.placeholder.push(stringHolder, "<find>", "<replacement>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.Substrcp:
                argObj.minArgNum = 3;
                argObj.maxArgNum = 3;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select, input, input);
                (_27 = argObj.outputDataTypeChoice).push.apply(_27, stringChoice);
                argObj.placeholder.push(stringHolder, "<codePointIndex>", "<codePointCount>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.Substr:
                argObj.minArgNum = 3;
                argObj.maxArgNum = 3;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                (_28 = argObj.outputDataTypeChoice).push.apply(_28, stringChoice);
                argObj.placeholder.push(stringHolder, "<start>", "<length>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.DateFromString:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                (_29 = argObj.outputDataTypeChoice).push.apply(_29, dateChoice);
                argObj.placeholder.push(stringHolder, "<format>");
                break;
            case data_type_enum_1.EnumCustomFieldStrOperator.ReplaceAll:
                argObj.minArgNum = 3;
                argObj.maxArgNum = 3;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_30 = argObj.outputDataTypeChoice).push.apply(_30, stringChoice);
                argObj.placeholder.push(stringHolder, "<find>", "<replacement>");
                break;
            case data_type_enum_1.EnumCustomFieldComparisonOperator.Equal:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_31 = argObj.outputDataTypeChoice).push.apply(_31, booleanChoice);
                argObj.placeholder.push("<expression1>", "<expression2>");
                break;
            case data_type_enum_1.EnumCustomFieldComparisonOperator.Greater:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_32 = argObj.outputDataTypeChoice).push.apply(_32, booleanChoice);
                argObj.placeholder.push("<expression1>", "<expression2>");
                break;
            case data_type_enum_1.EnumCustomFieldComparisonOperator.GreaterOrEqual:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_33 = argObj.outputDataTypeChoice).push.apply(_33, booleanChoice);
                argObj.placeholder.push("<expression1>", "<expression2>");
                break;
            case data_type_enum_1.EnumCustomFieldComparisonOperator.Less:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_34 = argObj.outputDataTypeChoice).push.apply(_34, booleanChoice);
                argObj.placeholder.push("<expression1>", "<expression2>");
                break;
            case data_type_enum_1.EnumCustomFieldComparisonOperator.LessOrEqual:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_35 = argObj.outputDataTypeChoice).push.apply(_35, booleanChoice);
                argObj.placeholder.push("<expression1>", "<expression2>");
                break;
            case data_type_enum_1.EnumCustomFieldComparisonOperator.NotEqual:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_36 = argObj.outputDataTypeChoice).push.apply(_36, booleanChoice);
                argObj.placeholder.push("<expression1>", "<expression2>");
                break;
            case data_type_enum_1.EnumCustomFieldComparisonOperator.IsNull:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_37 = argObj.outputDataTypeChoice).push.apply(_37, booleanChoice);
                argObj.placeholder.push("<expression1>");
                break;
            case data_type_enum_1.EnumCustomFieldComparisonOperator.NotNull:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_38 = argObj.outputDataTypeChoice).push.apply(_38, booleanChoice);
                argObj.placeholder.push("<expression1>");
                break;
            case data_type_enum_1.EnumCustomFieldBooleanOperator.And:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 10;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_39 = argObj.outputDataTypeChoice).push.apply(_39, booleanChoice);
                (_40 = argObj.placeholder).push.apply(_40, Array(10).fill("<expression>"));
                break;
            case data_type_enum_1.EnumCustomFieldBooleanOperator.Or:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 10;
                argObj.restrictedFirstArgType = true;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_41 = argObj.outputDataTypeChoice).push.apply(_41, booleanChoice);
                (_42 = argObj.placeholder).push.apply(_42, Array(10).fill("<expression>"));
                break;
            case data_type_enum_1.EnumCustomFieldBooleanOperator.Not:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_43 = argObj.outputDataTypeChoice).push.apply(_43, booleanChoice);
                argObj.placeholder.push("<expression>");
                break;
            case data_type_enum_1.EnumCustomFieldConditionOperator.IfNull:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 2;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_44 = argObj.outputDataTypeChoice).push.apply(_44, anyChoice);
                argObj.placeholder.push("<expression>", "<replacemenExpressionIfNull>");
                break;
            case data_type_enum_1.EnumCustomFieldConditionOperator.Iif:
                argObj.minArgNum = 3;
                argObj.maxArgNum = 3;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_45 = argObj.outputDataTypeChoice).push.apply(_45, anyChoice);
                argObj.placeholder.push("<if (boolean)>", "<then (true-case)>", "<else (false-case)>");
                break;
            case data_type_enum_1.EnumCustomFieldTypeOperator.Convert:
                argObj.minArgNum = 2;
                argObj.maxArgNum = 4;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                argObj.argType.push(select);
                (_46 = argObj.outputDataTypeChoice).push.apply(_46, anyChoice);
                argObj.placeholder.push("input <expression>", "<to (type expression)>", "<onError (expression)>", "<onNull (expression)>");
                break;
            case data_type_enum_1.EnumCustomFieldConstantOperator.Is:
                argObj.minArgNum = 1;
                argObj.maxArgNum = 1;
                argObj.restrictedFirstArgType = false;
                argObj.unlimitedArgNum = false;
                (_47 = argObj.outputDataTypeChoice).push.apply(_47, anyChoice);
                argObj.placeholder.push("<expression>");
                break;
        }
        return argObj;
    };
    CustomFieldMappingService.ngInjectableDef = i0.defineInjectable({ factory: function CustomFieldMappingService_Factory() { return new CustomFieldMappingService(); }, token: CustomFieldMappingService, providedIn: "root" });
    return CustomFieldMappingService;
}());
exports.CustomFieldMappingService = CustomFieldMappingService;
