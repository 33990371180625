import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormControlHelper} from "../../helpers/form-control.helper";
import {EnumDateFormat, EnumDateSymbol} from "../../../enums/dateFormat.enum";
import {EnumDataType} from "../../../enums/data-type.enum";

@Component({
  selector: "app-date-format-modal",
  templateUrl: './set-date-format-modal.component.html'
})
export class SetDateFormatModalComponent implements OnInit {

  @Input() type: string;
  @Input() dateFormatSelectList: any[];
  @Input() dateFormat: string;

  dateFormatPopTitle: string;
  dateFormatPopContent: string;

  formGroup: FormGroup;

  get enumDataType(): typeof EnumDataType {
    return EnumDataType;
  }

  get enumDateSymbol(): typeof EnumDateSymbol {
    return EnumDateSymbol;
  }

  get enumDateFormat(): typeof EnumDateFormat {
    return EnumDateFormat;
  }

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder,
              public formControlHelper: FormControlHelper) {
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      dateFormat: [this.dateFormat, Validators.compose([Validators.required])],
      dateFormatSelectList: [""]
    })
  }

  loadDateFormatPopover(dateFormat) {
    this.dateFormatPopTitle = dateFormat;
    switch (dateFormat) {
      case EnumDateFormat.MM:
        this.dateFormatPopContent = "Month of the year in a two-digit format";
        break;
      case EnumDateFormat.MMM:
        this.dateFormatPopContent = "Abbreviated month of the year";
        break;
      case EnumDateFormat.MMMM:
        this.dateFormatPopContent = "Month of the year";
        break;
      case EnumDateFormat.dd:
        this.dateFormatPopContent = "Day of the month";
        break;
      case EnumDateFormat.yy:
        this.dateFormatPopContent = "Year in two-digit format";
        break;
      case EnumDateFormat.yyyy:
        this.dateFormatPopContent = "Year in four-digit format";
        break;
      case EnumDateFormat.HH:
        this.dateFormatPopContent = "Hour of the day (0-23)";
        break;
      case EnumDateFormat.mm:
        this.dateFormatPopContent = "Minute";
        break;
      case EnumDateFormat.ss:
        this.dateFormatPopContent = "Second";
        break;
      case EnumDateFormat.SSS:
        this.dateFormatPopContent = "Fraction of a second";
        break;
      case EnumDateFormat.z:
        this.dateFormatPopContent = "UTC time offset";
        break;

    }
  }

  addSymbol(symbol) {
    this.dateFormat = (typeof this.dateFormat == "undefined")? "": this.dateFormat;
    let dfBean = {"type" : "", "value" : ""};
    switch (symbol) {
      case EnumDateSymbol.colon:
        dfBean.type = "COLON";
        break;
      case EnumDateSymbol.dot:
        dfBean.type = "DOT";
        break;
      case EnumDateSymbol.minus:
        dfBean.type = "MINUS";
        break;
      case EnumDateSymbol.slash:
        dfBean.type = "SLASH";
        break;
      case EnumDateSymbol.space:
        dfBean.type = "SPACE";
        break;
    }
    dfBean.value = symbol;
    this.dateFormat += symbol;
    this.dateFormatSelectList.push(dfBean);

    this.formGroup.patchValue({dateFormat: this.dateFormat, dateFormatSelectList: this.dateFormatSelectList});
  }


  addDateFormat(dateFormat) {
    this.dateFormat = (typeof this.dateFormat == "undefined")? "": this.dateFormat;
    let dfBean: {};
    switch (dateFormat) {
      case EnumDateFormat.yyyy:
      case EnumDateFormat.yy:
        dfBean = {"type": "YEAR", "value": dateFormat};
        break;
      case EnumDateFormat.MM:
      case EnumDateFormat.MMM:
      case EnumDateFormat.MMMM:
        dfBean = {"type": "MONTH", "value": dateFormat};
        break;
      case EnumDateFormat.timestamp13:
      case EnumDateFormat.timestamp10:
        dfBean = {"type": "TIMESTAMP", "value": dateFormat};
        break;
      default:
        dfBean = {"type": dateFormat, "value": dateFormat};
        break;
    }

    this.dateFormat += dateFormat;
    this.dateFormatSelectList.push(dfBean);

    this.formGroup.patchValue({dateFormat: this.dateFormat, dateFormatSelectList: this.dateFormatSelectList});
  }

  checkDisableDateFormat(dateFormatType) {
    let tempList = this.dateFormatSelectList.filter(format => format.type == dateFormatType);
    return tempList.length > 0;
  }

  deleteArg(index) {
    this.dateFormatSelectList.splice(index, 1);

    let dateFormatStrList = [];
    this.dateFormatSelectList.forEach(dateFormatStr => dateFormatStrList.push(dateFormatStr.value));
    this.dateFormat = dateFormatStrList.join('');

    this.formGroup.patchValue({dateFormat: this.dateFormat, dateFormatSelectList: this.dateFormatSelectList});
  }

  clearAllArg() {
    this.dateFormat = "";
    this.dateFormatSelectList = [];
    this.formGroup.patchValue({dateFormat: this.dateFormat, dateFormatSelectList: this.dateFormatSelectList});
  }

  save() {
    let response = {
      dateFormatSelectList: this.formGroup.value.dateFormatSelectList,
      dateFormat: this.formGroup.value.dateFormat
    }
    this.modal.close(response);
  }
}
