"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./splash-screen.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./splash-screen.component");
var i3 = require("./splash-screen.service");
var styles_SplashScreenComponent = [i0.styles];
var RenderType_SplashScreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplashScreenComponent, data: {} });
exports.RenderType_SplashScreenComponent = RenderType_SplashScreenComponent;
function View_SplashScreenComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { splashScreen: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["splashScreen", 1]], null, 3, "div", [["id", "splash-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "./assets/media/logos/Datacanva_Logo.webp"], ["style", "width: 300px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["class", "splash-spinner"], ["viewBox", "0 0 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:circle", [["cx", "25"], ["cy", "25"], ["id", "spinner-circle"], ["r", "20"]], null, null, null, null, null))], null, null); }
exports.View_SplashScreenComponent_0 = View_SplashScreenComponent_0;
function View_SplashScreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-splash-screen", [], null, null, null, View_SplashScreenComponent_0, RenderType_SplashScreenComponent)), i1.ɵdid(1, 114688, null, 0, i2.SplashScreenComponent, [i1.ElementRef, i3.SplashScreenService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SplashScreenComponent_Host_0 = View_SplashScreenComponent_Host_0;
var SplashScreenComponentNgFactory = i1.ɵccf("app-splash-screen", i2.SplashScreenComponent, View_SplashScreenComponent_Host_0, {}, {}, []);
exports.SplashScreenComponentNgFactory = SplashScreenComponentNgFactory;
