// import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
// import {Helpers} from "../helpers";
// import {SweetAlertService} from "./sweet-alert.service";
// import {CanDeactivateService} from "./can-deactivate.service";
//
// export interface ComponentCanDeactivate {
//   canDeactivate: () => boolean | Observable<boolean>;
// }
//
// @Injectable()
// export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
//   constructor(private sweetAlertService: SweetAlertService,private canDeactivateService: CanDeactivateService) {
//   }
//
//   canDeactivate(component: ComponentCanDeactivate,
//                 route: ActivatedRouteSnapshot,
//                 state: RouterStateSnapshot): boolean | Observable<boolean>|any {
//     // if there are no pending changes, just allow deactivation; else confirm first
//     console.log('PendingChangesGuard',component,route,state)
//     return confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.')
//
//     // return component.canDeactivate() ?
//       // confirm('1WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.')
//       // :
//       // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
//       // when navigating away from your angular app, the browser will show a generic warning message
//       // see http://stackoverflow.com/a/42207299/7307355
//       // confirm('2WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
//   }
// }
import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs/Observable';
declare var swal: any;

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree |any;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<CanComponentDeactivate> {

  canDeactivate(component: CanComponentDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {
    //can get canDeactivate / state
    // console.log('canDeactivate2',component['unSaved'])
    return component.canDeactivate() ? true : confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  }

}

@Injectable()
export class PendingPipelineChangeGuard implements CanDeactivate<CanComponentDeactivate> {

  canDeactivate(component: CanComponentDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {

    if (component.canDeactivate()) return true;

    swal({
        title: "Warning",
        text: "Preview Pipeline is still processing, please press 'Stop' before you leave!",
        type: "warning",
        showConfirmButton: true,
        confirmButtonClass: 'btn-danger',
        confirmButtonText: "OK",
        closeOnConfirm: false,
      }
    )

    return false;
  }
}


@Injectable()
export class PendingQueryChangeGuard implements CanDeactivate<CanComponentDeactivate> {

  canDeactivate(component: CanComponentDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {

    if (component.canDeactivate()) return true;

    swal({
        title: "Warning",
        text: "Query is still processing, please press 'Stop' before you leave!",
        type: "warning",
        showConfirmButton: true,
        confirmButtonClass: 'btn-danger',
        confirmButtonText: "OK",
        closeOnConfirm: false,
      }
    )

    return false;
  }
}
