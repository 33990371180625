import { IContextData } from '../interfaces/dto/context-data.interface';
import { EnumUserRole } from '../enums/user-role.enum';
import {FunctionCheckList} from "../theme/pages/accessControl/_models/function-list-model";


export class ContextDataService {

    private static _userID: number;
    private static _orgID: number;
    private static _userName: string;
    private static _userRole: EnumUserRole;
    private static _userDisplayName;
    private static _email: string;
    private static _timezone: string;
    private static _roleAccess: FunctionCheckList[];


    public static init(data: IContextData): void {
        if (!data) {
            return
        }

        this._userID = data.userID;
        this._orgID = data.orgID;
        this._userName = data.userName;
        this._userRole = data.userRole;
        this._userDisplayName = data.userDisplayName;
        this._timezone = data.timezone;
        this._roleAccess = data['functionListBeanList'];

    }

    static get userID(): number {
        return this._userID;
    }

    static get orgID(): number {
      return this._orgID;
    }

    static get userName(): string {
        return this._userName;
    }

    static get userRole(): EnumUserRole {
        return this._userRole;
    }

    static get userDisplayName() {
        return this._userDisplayName;
    }

    static get email(): string {
        return this._email;
    }

    static get timezone(): string {
      return this._timezone;
    }

    static get roleAccess(): FunctionCheckList[] {
      return this._roleAccess;
    }
}
