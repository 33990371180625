export interface IParamState {
  draw: number;
  start: number;
  length: number;
  searchValue: string;
  orderColumn: string;
  orderDirection: string;
}

export class ParamState implements IParamState {
  draw = 1;
  start = 0;
  length = 10;
  searchValue = "";
  orderColumn = "";
  orderDirection = "";
}


export interface IParamFilterState {
  draw: number;
  start: number;
  length: number;
  searchValue: string;
  filterKey: string;
  filterValue: string;
  orderColumn: string;
  orderDirection: string;
}

export class ParamFilterState implements IParamFilterState {
  draw = 1;
  start = 0;
  length = 10;
  searchValue = "";
  filterKey = ""
  filterValue = "";
  orderColumn = "";
  orderDirection = "";
}

export interface IParamFilterState1 {
  draw: number;
  start: number;
  length: number;
  searchValue: string;
  filterKey: string;
  filterValue: string;
  filterKey1: string;
  filterValue1: string;
  orderColumn: string;
  orderDirection: string;
}

export class ParamFilterState1 implements IParamFilterState1 {
  draw = 1;
  start = 0;
  length = 10;
  searchValue = "";
  filterKey = ""
  filterValue = "";
  filterKey1 = ""
  filterValue1 = "";
  orderColumn = "";
  orderDirection = "";
}


