import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from "@angular/material";
import {SplashRoutingComponent} from "./splash-routing.component";

@NgModule({
  declarations: [SplashRoutingComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [SplashRoutingComponent],
})
export class SplashRoutingModules {
}
