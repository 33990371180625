"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ParamState = /** @class */ (function () {
    function ParamState() {
        this.draw = 1;
        this.start = 0;
        this.length = 10;
        this.searchValue = "";
        this.orderColumn = "";
        this.orderDirection = "";
    }
    return ParamState;
}());
exports.ParamState = ParamState;
var ParamFilterState = /** @class */ (function () {
    function ParamFilterState() {
        this.draw = 1;
        this.start = 0;
        this.length = 10;
        this.searchValue = "";
        this.filterKey = "";
        this.filterValue = "";
        this.orderColumn = "";
        this.orderDirection = "";
    }
    return ParamFilterState;
}());
exports.ParamFilterState = ParamFilterState;
var ParamFilterState1 = /** @class */ (function () {
    function ParamFilterState1() {
        this.draw = 1;
        this.start = 0;
        this.length = 10;
        this.searchValue = "";
        this.filterKey = "";
        this.filterValue = "";
        this.filterKey1 = "";
        this.filterValue1 = "";
        this.orderColumn = "";
        this.orderDirection = "";
    }
    return ParamFilterState1;
}());
exports.ParamFilterState1 = ParamFilterState1;
