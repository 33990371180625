"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("../../../../_metronic/core");
var quick_search_1 = require("../../../../../assets/js/layout/extended/quick-search");
var quick_notifications_1 = require("../../../../../assets/js/layout/extended/quick-notifications");
var quick_actions_1 = require("../../../../../assets/js/layout/extended/quick-actions");
var quick_cart_1 = require("../../../../../assets/js/layout/extended/quick-cart");
var quick_panel_1 = require("../../../../../assets/js/layout/extended/quick-panel");
var quick_user_1 = require("../../../../../assets/js/layout/extended/quick-user");
var header_topbar_1 = require("../../../../../assets/js/layout/base/header-topbar");
var util_1 = require("../../../../../assets/js/components/util");
var router_1 = require("@angular/router");
var notification_service_1 = require("../../_services/notification.service");
var context_data_service_1 = require("../../../../global-services/context-data.service");
var router_enum_1 = require("../../../../enums/router.enum");
var TopbarComponent = /** @class */ (function () {
    function TopbarComponent(layout, activatedRoute, router, cdr, notificationService) {
        var _this = this;
        this.layout = layout;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.cdr = cdr;
        this.notificationService = notificationService;
        this.now = new Date();
        this.userTimezone = context_data_service_1.ContextDataService.timezone.split(";")[0];
        this.alertNotice = [];
        this.eventNotice = [];
        this.logNotice = [];
        this.noticeSetting = [];
        this.notificationNum = 0;
        setInterval(function () {
            _this.now = new Date();
        }, 1);
        this.activatedRoute.data.subscribe(function (data) {
            if (data.rsContextData) {
                _this.user = data.rsContextData;
                _this.getNotifcationCount();
            }
        });
    }
    TopbarComponent.prototype.ngOnInit = function () {
        // topbar extras
        this.extraSearchDisplay = this.layout.getProp('extras.search.display');
        this.extrasSearchLayout = this.layout.getProp('extras.search.layout');
        this.extrasNotificationsDisplay = this.layout.getProp('extras.notifications.display');
        this.extrasNotificationsLayout = this.layout.getProp('extras.notifications.layout');
        this.extrasQuickActionsDisplay = this.layout.getProp('extras.quickActions.display');
        this.extrasQuickActionsLayout = this.layout.getProp('extras.quickActions.layout');
        this.extrasCartDisplay = this.layout.getProp('extras.cart.display');
        this.extrasCartLayout = this.layout.getProp('extras.cart.layout');
        this.extrasLanguagesDisplay = this.layout.getProp('extras.languages.display');
        this.extrasUserDisplay = this.layout.getProp('extras.user.display');
        this.extrasUserLayout = this.layout.getProp('extras.user.layout');
        this.extrasQuickPanelDisplay = this.layout.getProp('extras.quickPanel.display');
    };
    TopbarComponent.prototype.getNotifcationCount = function () {
        var _this = this;
        this.notificationService.countNotification().subscribe(function (count) {
            _this.notificationNum = count;
        });
    };
    TopbarComponent.prototype.checkDropdown = function (isOpenEvent) {
        if (isOpenEvent)
            this.loadNotification();
    };
    TopbarComponent.prototype.loadNotification = function () {
        var _this = this;
        this.notificationService.getLatestNotification().subscribe(function (res) {
            _this.alertNotice = res.mnuDTOAlertList;
            _this.alertNotice.forEach(function (alert) { alert.hover = false; alert.isChecked = false; });
            _this.eventNotice = res.mnuDTOEventList;
            _this.eventNotice.forEach(function (event) {
                event.hover = false;
                event.isChecked = false;
            });
            _this.logNotice = res.mnuDTOLogList;
            _this.logNotice.forEach(function (log) {
                log.hover = false;
                log.isChecked = false;
            });
            _this.noticeSetting = res.notificationSettingDTOList;
            _this.notificationNum = res.noticeNum;
        });
    };
    TopbarComponent.prototype.updateNotificationNum = function (notificationNum) {
        this.notificationNum = notificationNum;
    };
    TopbarComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        util_1.KTUtil.ready(function () {
            // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
            // Add 'implements AfterViewInit' to the class.
            if (_this.extraSearchDisplay && _this.extrasSearchLayout === 'offcanvas') {
                quick_search_1.default.init('kt_quick_search');
            }
            if (_this.extrasNotificationsDisplay &&
                _this.extrasNotificationsLayout === 'offcanvas') {
                // Init Quick Notifications Offcanvas Panel
                quick_notifications_1.default.init('kt_quick_notifications');
            }
            if (_this.extrasQuickActionsDisplay &&
                _this.extrasQuickActionsLayout === 'offcanvas') {
                // Init Quick Actions Offcanvas Panel
                quick_actions_1.default.init('kt_quick_actions');
            }
            if (_this.extrasCartDisplay && _this.extrasCartLayout === 'offcanvas') {
                // Init Quick Cart Panel
                quick_cart_1.default.init('kt_quick_cart');
            }
            if (_this.extrasQuickPanelDisplay) {
                // Init Quick Offcanvas Panel
                quick_panel_1.default.init('kt_quick_panel');
            }
            if (_this.extrasUserDisplay && _this.extrasUserLayout === 'offcanvas') {
                // Init Quick User Panel
                quick_user_1.default.init('kt_quick_user');
            }
            // Init Header Topbar For Mobile Mode
            header_topbar_1.default.init('kt_header_mobile_topbar_toggle');
        });
    };
    TopbarComponent.prototype.openDoc = function () {
        var currentURL = window.location.href;
        var host = currentURL.split("/");
        var url = host[0] + "//" + host[2] + "/documentation/authentication";
        window.open(url, "_blank", "toolbar,scrollbars,resizable");
    };
    TopbarComponent.prototype.logout = function () {
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }
        localStorage.removeItem('token');
        this.router.navigate(["/" + router_enum_1.EnumMainPageRoute.Auth + "/" + router_enum_1.EnumAuthPageRoute.Login]).then();
    };
    return TopbarComponent;
}());
exports.TopbarComponent = TopbarComponent;
