"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./header.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory");
var i3 = require("@ng-bootstrap/ng-bootstrap");
var i4 = require("@angular/router");
var i5 = require("@angular/common");
var i6 = require("./header-menu/header-menu.component.ngfactory");
var i7 = require("./header-menu/header-menu.component");
var i8 = require("../../../../_metronic/core/services/layout.service");
var i9 = require("./header-menu-dynamic/header-menu-dynamic.component.ngfactory");
var i10 = require("./header-menu-dynamic/header-menu-dynamic.component");
var i11 = require("../../../../_metronic/core/services/dynamic-header-menu.service");
var i12 = require("../topbar/topbar.component.ngfactory");
var i13 = require("../topbar/topbar.component");
var i14 = require("../../_services/notification.service");
var i15 = require("./header.component");
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
exports.RenderType_HeaderComponent = RenderType_HeaderComponent;
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ngb-progressbar", [["class", "loading-bar"], ["height", "3px"]], null, null, null, i2.View_NgbProgressbar_0, i2.RenderType_NgbProgressbar)), i1.ɵdid(2, 49152, null, 0, i3.NgbProgressbar, [i3.NgbProgressbarConfig], { value: [0, "value"], height: [1, "height"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf; var currVal_1 = "3px"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "header-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Logo"]], [[1, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.headerLogo; _ck(_v, 4, 0, currVal_3); }); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header-menu", [["class", "header-menu header-menu-mobile"], ["id", "kt_header_menu"]], null, null, null, i6.View_HeaderMenuComponent_0, i6.RenderType_HeaderMenuComponent)), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 114688, [[1, 4], ["ktHeaderMenu", 4]], 0, i7.HeaderMenuComponent, [i8.LayoutService, i5.Location], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "header-menu header-menu-mobile"; var currVal_1 = _co.headerMenuCSSClasses; _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 3, 0); }, null); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header-menu-dynamic", [["class", "header-menu header-menu-mobile"], ["id", "kt_header_menu"]], null, null, null, i9.View_HeaderMenuDynamicComponent_0, i9.RenderType_HeaderMenuDynamicComponent)), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 245760, [[1, 4], ["ktHeaderMenu", 4]], 0, i10.HeaderMenuDynamicComponent, [i8.LayoutService, i4.Router, i11.DynamicHeaderMenuService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "header-menu header-menu-mobile"; var currVal_1 = _co.headerMenuCSSClasses; _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 3, 0); }, null); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "header-menu-wrapper header-menu-wrapper-left"], ["id", "kt_header_menu_wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.asideSelfDisplay; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.headerMenuSelfStatic; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.headerMenuSelfStatic; _ck(_v, 7, 0, currVal_2); }, null); }
function View_HeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ktHeaderMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "d-flex align-items-stretch justify-content-between"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HeaderComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_6)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-topbar", [["class", "topbar"]], null, null, null, i12.View_TopbarComponent_0, i12.RenderType_TopbarComponent)), i1.ɵdid(11, 4308992, null, 0, i13.TopbarComponent, [i8.LayoutService, i4.ActivatedRoute, i4.Router, i1.ChangeDetectorRef, i14.NotificationService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "d-flex align-items-stretch justify-content-between"; var currVal_1 = _co.headerContainerCSSClasses; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.loader$)); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.headerMenuSelfDisplay; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.headerMenuSelfDisplay; _ck(_v, 9, 0, currVal_4); _ck(_v, 11, 0); }, null); }
exports.View_HeaderComponent_0 = View_HeaderComponent_0;
function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 4440064, null, 0, i15.HeaderComponent, [i8.LayoutService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HeaderComponent_Host_0 = View_HeaderComponent_Host_0;
var HeaderComponentNgFactory = i1.ɵccf("app-header", i15.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
exports.HeaderComponentNgFactory = HeaderComponentNgFactory;
