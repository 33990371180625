import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FormControlHelper {

  isControlValid(formGroup: FormGroup, controlName: string): boolean {
    const control = formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(formGroup: FormGroup, controlName: string, isSubmitted: boolean): boolean {
    const control = formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched || isSubmitted);
  }

  controlHasError(formGroup: FormGroup, validation: string, controlName: string, isSubmitted: boolean) {
    const control = formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched || isSubmitted);
  }

  isControlInvalidWithoutSubmit(formGroup: FormGroup, controlName: string): boolean {
    const control = formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasErrorWithoutSubmit(formGroup: FormGroup, validation: string, controlName: string) {
    const control = formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }
}
