"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var core_2 = require("../../../../../_metronic/core");
function getCurrentURL(location) {
    return location.split(/[?#]/)[0];
}
var HeaderMenuComponent = /** @class */ (function () {
    function HeaderMenuComponent(layout, loc) {
        this.layout = layout;
        this.loc = loc;
        this.location = this.loc;
    }
    HeaderMenuComponent.prototype.ngOnInit = function () {
        this.ulCSSClasses = this.layout.getStringCSSClasses('header_menu_nav');
        this.rootArrowEnabled = this.layout.getProp('header.menu.self.rootArrow');
        this.headerMenuDesktopToggle = this.layout.getProp('header.menu.desktop.toggle');
    };
    HeaderMenuComponent.prototype.getMenuItemActive = function (url) {
        return this.checkIsActive(url) ? 'menu-item-active' : '';
    };
    HeaderMenuComponent.prototype.checkIsActive = function (url) {
        var location = this.location.path();
        var current = getCurrentURL(location);
        if (!current || !url) {
            return false;
        }
        if (current === url) {
            return true;
        }
        if (current.indexOf(url) > -1) {
            return true;
        }
        return false;
    };
    return HeaderMenuComponent;
}());
exports.HeaderMenuComponent = HeaderMenuComponent;
