import {Component, Input, OnInit} from "@angular/core";
import {EnumDateMask, EnumFileMask} from "../../../enums/filemask.enum";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormControlHelper} from "../../helpers/form-control.helper";

@Component({
  selector: 'app-filemask-modal',
  templateUrl: './set-filemask-modal.component.html'
})
export class SetFilemaskModalComponent implements OnInit {

  @Input() id: number;
  @Input() fileMaskSelectList: any[];
  @Input() filemask: string;
  expression: string;
  isPrefix: boolean;
  isSerialNo: boolean;
  isSuffix: boolean;

  formGroup: FormGroup;

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder,
              public formControlHelper: FormControlHelper) {
  }

  get enumFileMask(): typeof EnumFileMask {
    return EnumFileMask;
  }

  get enumDateMask(): typeof EnumDateMask {
    return EnumDateMask;
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      filemask: [this.filemask, Validators.compose([Validators.required])],
      prefixInput: [""],
      character: [""],
      serialNumLength: [""],
      suffix: [""],
      fileMaskSelectList: [""],
    })
  }

  addDate(dateFormat) {
    this.filemask = (typeof this.filemask == "undefined")? "" : this.filemask;
    let fmBean = {"type" : dateFormat, "value" : dateFormat};
    this.filemask += dateFormat;
    this.fileMaskSelectList.push(fmBean);

    this.formGroup.patchValue({filemask: this.filemask, fileMaskSelectList: this.fileMaskSelectList});
  }

  addExpression(expression) {

    this.expression = expression;
    let prefixInputControl = this.formGroup.get("prefixInput");
    let characterControl = this.formGroup.get("character");
    let serialNumLengthControl = this.formGroup.get("serialNumLength");
    let suffixControl = this.formGroup.get("suffix");
    let filenameRegex = new RegExp(/^[0-9a-zA-Z ... ]+$/);
    switch (this.expression) {
      case EnumFileMask.prefix:
        prefixInputControl.setValidators([Validators.required, ValidatePrefix]);
        characterControl.setValidators(null);
        serialNumLengthControl.setValidators(null);
        suffixControl.setValidators(null);
        break;
      case EnumFileMask.character:
        prefixInputControl.setValidators(null);
        characterControl.setValidators([Validators.required, ValidateCharacter]);
        serialNumLengthControl.setValidators(null);
        suffixControl.setValidators(null);
        break;
      case EnumFileMask.serialNo:
        prefixInputControl.setValidators(null);
        characterControl.setValidators(null);
        serialNumLengthControl.setValidators([Validators.required, ValidateNumLength]);
        suffixControl.setValidators(null);
        break;
      case EnumFileMask.suffix:
        prefixInputControl.setValidators(null);
        characterControl.setValidators(null);
        serialNumLengthControl.setValidators(null);
        suffixControl.setValidators([Validators.required]);
        break;
    }
    prefixInputControl.updateValueAndValidity();
    characterControl.updateValueAndValidity();
    serialNumLengthControl.updateValueAndValidity();
    suffixControl.updateValueAndValidity();
  }

  add() {
    this.filemask = (this.filemask == null)? "" : this.filemask;
    let fmBean = {"type" : "", "value" : ""};
    switch (this.expression) {
      case EnumFileMask.prefix:
        this.isPrefix = true;
        this.filemask += this.formGroup.value.prefixInput;
        fmBean.type = this.expression;
        fmBean.value = this.formGroup.value.prefixInput;
        break;
      case EnumFileMask.character:
        this.filemask += this.formGroup.value.character;
        fmBean.type = this.expression;
        fmBean.value = this.formGroup.value.character;
        break;
      case EnumFileMask.serialNo:
        this.isSerialNo = true;
        // let tempSerialNumLen = "0".substring(0, 2 - this.formGroup.value.serialNumLength.length) + this.formGroup.value.serialNumLength;
        this.filemask += `%s${this.formGroup.value.serialNumLength}`;
        fmBean.type = this.expression;
        fmBean.value = `%s${this.formGroup.value.serialNumLength}`;
        break;
      case EnumFileMask.suffix:
        this.isSuffix = true;
        this.filemask += this.formGroup.value.suffix;
        fmBean.type = this.expression;
        fmBean.value = this.formGroup.value.suffix;
        break;
    }
    this.fileMaskSelectList.push(fmBean);

    this.expression = "";
    this.formGroup.patchValue({filemask: this.filemask, fileMaskSelectList: this.fileMaskSelectList});
  }

  checkDisableDate(dateMask) {
    let tempList = this.fileMaskSelectList.filter(filemask => filemask.type == dateMask);
    return tempList.length > 0;
  }

  deleteArg(index) {
    let targetArg = this.fileMaskSelectList[index];

    switch (targetArg.type) {
      case EnumFileMask.prefix:
        this.fileMaskSelectList.splice(index);
        this.isPrefix = false;
        break;
      case EnumFileMask.suffix:
        this.fileMaskSelectList.splice(index, 1);
        this.isSuffix = false;
        break;
      case EnumFileMask.serialNo:
        this.fileMaskSelectList.splice(index);
        this.isSerialNo = false;
        break;
      default:
        this.fileMaskSelectList.splice(index, 1);
        break;
    }
    let fileMaskStrList = [];
    this.fileMaskSelectList.forEach(filemaskStr => fileMaskStrList.push(filemaskStr.value));
    this.filemask = fileMaskStrList.join('');
    this.formGroup.patchValue({filemask: this.filemask, fileMaskSelectList: this.fileMaskSelectList});
  }

  save() {
    let response = {
      fileMaskSelectList: this.formGroup.value.fileMaskSelectList,
      filemask: this.formGroup.value.filemask
    }
    this.modal.close(response);
  }
}

function ValidatePrefix(control: AbstractControl): { [key: string]: any} | null  {
  let rg1 = new RegExp(/^[^\\/:\*\?"<>\|]+$/);
  let rg2 = new RegExp(/^\./);
  let rg3 = new RegExp(/^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i);
  // let filenameRegex = new RegExp(/[\\/:*?"<>|\r\n]+/);
  if (rg1.test(control.value) && !rg2.test(control.value) && !rg3.test(control.value)) {
    return null;
  }
  return {'prefixInvalid': true};
}

function ValidateNumLength(control: AbstractControl): { [key: string]: any} | null {
  let serialNumLength: number | string = control.value;
  if (serialNumLength === undefined || serialNumLength === null || serialNumLength === "") {
    return null;
  }

  if (serialNumLength > 0 && serialNumLength < 20) {
    return null;
  }
  return {'serialLengthInvalid': true};
}

function ValidateCharacter(control: AbstractControl): { [key: string]: any} | null  {
  let rg1 = new RegExp(/^[^\\/:\*\?"<>\|]+$/);
  let rg2 = new RegExp(/^\./);
  let rg3 = new RegExp(/^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i);
  // let filenameRegex = new RegExp(/[\\/:*?"<>|\r\n]+/);
  if (rg1.test(control.value) && !rg2.test(control.value) && !rg3.test(control.value)) {
    return null;
  }
  return {'characterInvalid': true};
}


