import {Injectable} from "@angular/core";
import {
  EnumCustomFieldBooleanOperator,
  EnumCustomFieldComparisonOperator,
  EnumCustomFieldConditionOperator, EnumCustomFieldConstantOperator, EnumCustomFieldDateOperator,
  EnumCustomFieldNumericalOperator,
  EnumCustomFieldStrOperator,
  EnumCustomFieldTypeOperator,
  EnumDataType
} from "../../../enums/data-type.enum";
import {EnumArgumentType} from "../../../enums/smart-query.enum";
import {CustomFieldState} from "../../model-states/custom-field.model";

@Injectable({
  providedIn: 'root'
})
export class CustomFieldMappingService {
  constructor() {
  }

  operatorMappingArgNum(operator: string): CustomFieldState {
    let argObj = new CustomFieldState();
    let select = EnumArgumentType.Select;
    let input = EnumArgumentType.Input;
    let numericalChoice = [EnumDataType.Int, EnumDataType.Long, EnumDataType.Double, EnumDataType.Float];
    let intChoice = [EnumDataType.Int, EnumDataType.Long];
    let booleanChoice = [EnumDataType.Boolean];
    let stringChoice = [EnumDataType.String, EnumDataType.Categorical];
    let dateChoice = [EnumDataType.DateDate, EnumDataType.DateDatetime, EnumDataType.DateTimestamp];
    let anyChoice = [EnumDataType.Long, EnumDataType.Double, EnumDataType.String,
      EnumDataType.Boolean, EnumDataType.DateDate,
      EnumDataType.DateDatetime, EnumDataType.DateTimestamp,
      EnumDataType.Categorical];
    let numberHolder = "<number>";
    let stringHolder = "<string>";
    let dateHolder = "<date>";

    switch (operator) {
      case EnumCustomFieldNumericalOperator.Absolute:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.unlimitedArgNum = false;
        argObj.restrictedFirstArgType = true;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Add:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 10;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = true;
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(...Array(10).fill(numberHolder));
        break;
      case EnumCustomFieldNumericalOperator.Acos:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Asin:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Atan:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Ceiling:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Cosine:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Divide:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder, numberHolder);
        break;
      case EnumCustomFieldNumericalOperator.Exponential:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Floor:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Natural_log:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Log:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select, input);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder, "<base>");
        break;
      case EnumCustomFieldNumericalOperator.Log10:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Mode:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(numberHolder, numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Multiply:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 10;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = true;
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(...Array(10).fill(numberHolder));
        break;
      case EnumCustomFieldNumericalOperator.Power:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder, "<exponent>")
        break;
      case EnumCustomFieldNumericalOperator.Round:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder, "<place>")
        break;
      case EnumCustomFieldNumericalOperator.Sine:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Square:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder)
        break;
      case EnumCustomFieldNumericalOperator.Subtract:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder, numberHolder);
        break;
      case EnumCustomFieldNumericalOperator.Tangent:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder);
        break;
      case EnumCustomFieldNumericalOperator.Truncate:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select, input);
        argObj.outputDataTypeChoice.push(...numericalChoice);
        argObj.placeholder.push(numberHolder, "<place>");
        break;

      case EnumCustomFieldDateOperator.DayOfYear:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.DayOfMonth:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.DayOfWeek:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.Year:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.Month:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.Week:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.Hour:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.Minute:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.Second:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.Millisecond:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(dateHolder);
        break;
      case EnumCustomFieldDateOperator.DateToString:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 4;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select, input, input,input);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push("<date>", "<format>", "<timezone>", "<onNull>");
        break;
      case EnumCustomFieldDateOperator.Add:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select, input);
        argObj.outputDataTypeChoice.push(...dateChoice);
        argObj.placeholder.push("<date>", "<milliseconds>");
        break;
      case EnumCustomFieldDateOperator.DateAdd:
        argObj.minArgNum = 3;
        argObj.maxArgNum = 4;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...dateChoice);
        argObj.placeholder.push("<startDate>", "<unit>", "<amount>", "<timezone>");
        break;
      case EnumCustomFieldDateOperator.Now:
        argObj.minArgNum = 0;
        argObj.maxArgNum = 0;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.outputDataTypeChoice.push(...dateChoice);
        break;
      case EnumCustomFieldDateOperator.DateDiff:
        argObj.minArgNum = 3;
        argObj.maxArgNum = 4;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push("<startDate>", "<endDate>", "<unit>", "<timezone>", "<startOfWeek>");
        break;

      case EnumCustomFieldStrOperator.Concat:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 10;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = true;
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(...Array(10).fill(stringHolder));
        break;
      case EnumCustomFieldStrOperator.IndexOfCP:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 4;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(stringHolder, "<substring>", "<startIndex>", "<endIndex>");
        break;
      case EnumCustomFieldStrOperator.StrLenCP:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...intChoice);
        argObj.placeholder.push(stringHolder);
        break;
      case EnumCustomFieldStrOperator.ToLower:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder);
        break;
      case EnumCustomFieldStrOperator.ToUpper:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder);
        break;
      case EnumCustomFieldStrOperator.L_PAD:
        argObj.minArgNum = 3;
        argObj.maxArgNum = 3;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select, input, input);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder, "<finalPaddedLength>", "<padding>");
        break;
      case EnumCustomFieldStrOperator.R_PAD:
        argObj.minArgNum = 3;
        argObj.maxArgNum = 3;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select, input, input);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder, "<finalPaddedLength>", "<padding>");
        break;
      case EnumCustomFieldStrOperator.LTrim:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder, "<trimCharacters>");
        break;
      case EnumCustomFieldStrOperator.RTrim:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder, "<trimCharacters>");
        break;
      case EnumCustomFieldStrOperator.Trim:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder, "<trimCharacters>");
        break;
      case EnumCustomFieldStrOperator.ReplaceOne:
        argObj.minArgNum = 3;
        argObj.maxArgNum = 3;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select, input, input);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder, "<find>", "<replacement>");
        break;
      case EnumCustomFieldStrOperator.Substrcp:
        argObj.minArgNum = 3;
        argObj.maxArgNum = 3;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select, input, input);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder, "<codePointIndex>", "<codePointCount>");
        break;
      case EnumCustomFieldStrOperator.Substr:
        argObj.minArgNum = 3;
        argObj.maxArgNum = 3;
        argObj.restrictedFirstArgType = false
        argObj.unlimitedArgNum = false;
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder, "<start>", "<length>");
        break;
      case EnumCustomFieldStrOperator.DateFromString:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.outputDataTypeChoice.push(...dateChoice);
        argObj.placeholder.push(stringHolder, "<format>");
        break;
      case EnumCustomFieldStrOperator.ReplaceAll:
        argObj.minArgNum = 3;
        argObj.maxArgNum = 3;
        argObj.restrictedFirstArgType = true
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...stringChoice);
        argObj.placeholder.push(stringHolder, "<find>", "<replacement>");
        break;

      case EnumCustomFieldComparisonOperator.Equal:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push("<expression1>", "<expression2>");
        break;
      case EnumCustomFieldComparisonOperator.Greater:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push("<expression1>", "<expression2>");
        break;
      case EnumCustomFieldComparisonOperator.GreaterOrEqual:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push("<expression1>", "<expression2>");
        break;
      case EnumCustomFieldComparisonOperator.Less:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push("<expression1>", "<expression2>");
        break;
      case EnumCustomFieldComparisonOperator.LessOrEqual:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push("<expression1>", "<expression2>");
        break;
      case EnumCustomFieldComparisonOperator.NotEqual:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push("<expression1>", "<expression2>");
        break;
      case EnumCustomFieldComparisonOperator.IsNull:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push("<expression1>");
        break;
      case EnumCustomFieldComparisonOperator.NotNull:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push("<expression1>");
        break;

      case EnumCustomFieldBooleanOperator.And:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 10;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push(...Array(10).fill("<expression>"));
        break;
      case EnumCustomFieldBooleanOperator.Or:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 10;
        argObj.restrictedFirstArgType = true;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push(...Array(10).fill("<expression>"));
        break;
      case EnumCustomFieldBooleanOperator.Not:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...booleanChoice);
        argObj.placeholder.push("<expression>")
        break;

      case EnumCustomFieldConditionOperator.IfNull:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 2;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...anyChoice);
        argObj.placeholder.push("<expression>", "<replacemenExpressionIfNull>");
        break;
      case EnumCustomFieldConditionOperator.Iif:
        argObj.minArgNum = 3;
        argObj.maxArgNum = 3;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...anyChoice);
        argObj.placeholder.push("<if (boolean)>", "<then (true-case)>", "<else (false-case)>");
        break;

      case EnumCustomFieldTypeOperator.Convert:
        argObj.minArgNum = 2;
        argObj.maxArgNum = 4;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.argType.push(select);
        argObj.outputDataTypeChoice.push(...anyChoice);
        argObj.placeholder.push("input <expression>","<to (type expression)>", "<onError (expression)>", "<onNull (expression)>");
        break;

      case EnumCustomFieldConstantOperator.Is:
        argObj.minArgNum = 1;
        argObj.maxArgNum = 1;
        argObj.restrictedFirstArgType = false;
        argObj.unlimitedArgNum = false;
        argObj.outputDataTypeChoice.push(...anyChoice);
        argObj.placeholder.push("<expression>")
        break;
    }
    return argObj;
  }
}
