import {IBreadCrumb} from '../interfaces/custom/bread-crumb.interface';
import {ActivatedRoute, Params, PRIMARY_OUTLET} from '@angular/router';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs';
import {EnumRouteName} from '../enums/route-name.enum';

export class BreadCrumbService {

    private static pathParams: Params;
    private static queryParams: Params;
    private static subjectBreadCrumbs = new Subject<IBreadCrumb[]>();


    public static refreshBreadCrumbs(activatedRoute: ActivatedRoute) {

        let breadcrumbs: IBreadCrumb[] = [];
        let pathParams: Params = {};
        let queryParams: Params = {};
        let children: ActivatedRoute[] = activatedRoute.children;

        // return if there are no more children
        if (children.length === 0) {
            return;
        }

        let child = activatedRoute.firstChild;

        // iterate over each children
        while (child != undefined) {

            if (child.outlet == PRIMARY_OUTLET && child.snapshot.data.hasOwnProperty('breadcrumb')) {
                // add breadcrumb
                breadcrumbs.push(child.snapshot.data.breadcrumb);
            }

            if (child.snapshot.params !== undefined) {
                // pathParams = child.snapshot.params;
                pathParams = {...pathParams, ...child.snapshot.params};
                queryParams = {...queryParams, ...child.snapshot.queryParams};
            }

            child = child.firstChild;
        }

        this.pathParams = pathParams;
        this.queryParams = queryParams;
        this.subjectBreadCrumbs.next(breadcrumbs);
    }


    public static login(): IBreadCrumb {

        return {
            title: 'Login',
            text: '',
            href: '/auth/login',
            path: 'login',
            customBreadCrumbs: () => {
                return [
                    this.login()
                ]
            }
        }
    }


    public static dashboard(): IBreadCrumb {
        return {
            route: EnumRouteName.Dashboard,
            title: 'Dashboard',
            text: 'Dashboard',
            href: '/dashboard',
            path: 'dashboard',
            customBreadCrumbs: () => {
                return [
                    this.dashboard()
                ]
            }
        };
    }


    public static test(): IBreadCrumb {
        return {
            route: EnumRouteName.Test,
            title: 'Test',
            text: 'Test',
            href: '/test',
            path: 'test',
            customBreadCrumbs: () => {
                return [
                    this.test()
                ]
            }
        };
    }

    public static dataApi(activatedRoute?: ActivatedRoute): IBreadCrumb {
        let dataEntityID = undefined;

        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }

        return {
            route: EnumRouteName.DataApi,
            title: 'Edit Api Setting',
            text: 'Edit Api Setting',
            href: '/data-api/' + dataEntityID,
            path: 'data-api: dataEntityID',
            customBreadCrumbs: (activatedRoute?:ActivatedRoute) => {
                return [
                    this.dataApi(activatedRoute)
                ]
            }
        }
    }

    public static dataWarehouse(): IBreadCrumb {
        return {
            route: EnumRouteName.DataWarehouse,
            title: 'Data Warehouse',
            text: 'Data Warehouse',
            href: '/dataWarehouse',
            path: 'dataWarehouse',
            customBreadCrumbs: () => {
                return [
                    this.dataWarehouse()
                ]
            }
        };
    }


    public static dataEntity(): IBreadCrumb {
        return {
            route: EnumRouteName.DataEntity,
            title: 'Create New Entity',
            text: 'Create New Entity',
            href: '/dataEntity',
            path: 'dataEntity',
            customBreadCrumbs: () => {
                return [
                    this.dataWarehouse()
                ]
            }
        };
    }

    public static dataSource(activatedRoute?: ActivatedRoute): IBreadCrumb {

        let dataEntityID = undefined;

        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }

        return {
            route: EnumRouteName.DataSource,
            title: 'Create New Entity',
            text: 'Create New Entity',
            href: '/dataSource/' + dataEntityID,
            path: 'dataSource:dataEntityID',
            customBreadCrumbs: (activatedRoute?: ActivatedRoute) => {
                return [
                    this.dataSource(activatedRoute)
                ]
            }
        };
    }


    public static dataSchema(activatedRoute?: ActivatedRoute): IBreadCrumb {

        let dataEntityID = undefined;

        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }

        return {
            route: EnumRouteName.DataSource,
            title: 'Create New Schema',
            text: 'Create New Schema',
            href: '/dataSchema' + dataEntityID,
            path: 'dataSchema:dataEntityID',
            customBreadCrumbs: (activatedRoute?: ActivatedRoute) => {
                return [
                    this.dataSchema(activatedRoute)
                ]
            }
        };
    }


    public static dataView(activatedRoute?: ActivatedRoute): IBreadCrumb {

        let dataEntityID = undefined;

        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }

        return {
            route: EnumRouteName.DataView,
            title: 'Data View',
            text: 'Data View',
            href: '/dataView/' + dataEntityID,
            path: 'dataView:dataEntityID',
            customBreadCrumbs: (activatedRoute?: ActivatedRoute) => {
                return [
                    this.dataView(activatedRoute)
                ]
            }
        };
    }

    public static entityHistory(activatedRoute?: ActivatedRoute): IBreadCrumb {
        let dataEntityID = undefined;

        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }

        return {
            route: EnumRouteName.EntityHistory,
            title: 'Entity History',
            text: 'Entity History',
            href: '/entity-history/' + dataEntityID,
            path: 'entity-history: dataEntityID',
            customBreadCrumbs: (activatedRoute?:ActivatedRoute) => {
                return [
                    this.entityHistory(activatedRoute)
                ]
            }
        }
    }


    public static fileHistory(activatedRoute?: ActivatedRoute): IBreadCrumb {

        let dataEntityID = undefined;

        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }

        return {
            route: EnumRouteName.FileHistory,
            title: 'File History',
            text: 'File History',
            href: '/fileHistory/' + dataEntityID,
            path: 'fileHistory: dataEntityID',
            customBreadCrumbs: (activatedRoute?:ActivatedRoute) => {
                return [
                    this.fileHistory(activatedRoute)
                ]
            }
        }
    }

    public static dataPipelineManagement(): IBreadCrumb {
        return {
            route: EnumRouteName.DataPipelineManagement,
            title: 'Data Pipeline',
            text: 'Data Pipeline Management',
            href: '/data-pipeline-management',
            path: 'data-pipeline-management',
            customBreadCrumbs: () => {
                return [
                    this.dataPipelineManagement()
                ]
            }
        };
    }


    public static dataPipelineEditing(activatedRoute?: ActivatedRoute): IBreadCrumb {
        return {
            route: EnumRouteName.DataPipelineEditing,
            title: 'Data Pipeline Editing',
            text: 'Data Pipeline Editing',
            href: '/data-pipeline-editing',
            path: 'data-pipeline-editing',
            customBreadCrumbs: () => {
                return [
                    this.dataPipelineManagement(),
                    this.dataPipelineEditing(activatedRoute)
                ]
            }
        };
    }


    public static getBreadCrumbsObservable(): Observable<IBreadCrumb[]> {
        return this.subjectBreadCrumbs.asObservable();
    }
}
