import { Injectable, ErrorHandler } from "@angular/core";
import {throwError} from "rxjs";
declare var swal: any;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor() {
    }

    handleError(error: any): void {
    }


    handlerRoleAccessError() {
      let errorMessage = "Role Access Denied !"
      GlobalErrorHandler.info("Restricted Access!!",
        "Sorry, you are not authenticated to access this pages. Please notify your organization admin for further access.",
        () => console.log("Confirmed !")
      );
      return throwError(errorMessage);
    }

    static info(title: string, text: string, cbFn: () => void) {
      const alert = swal({
        html: text,
        text: text,
        title: title,
        type: 'info',
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonClass: 'btn-info',
        closeOnConfirm: true,
        confirmButtonText: 'Confirm'
      })

      try {
        alert;
      } catch(e) {
        console.log(e);
      }
      if (cbFn) {
        cbFn();
      }
    }
}
