"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cronstrue_1 = require("cronstrue");
var i0 = require("@angular/core");
var CommonValidator = /** @class */ (function () {
    function CommonValidator() {
    }
    CommonValidator.prototype.validateRegexBlock = function (control) {
        var specials = /[*|":<>[\]{}`\\()';@&$]/;
        if (specials.test(control.value))
            return { "regexBlockCheck": true };
        return null;
    };
    CommonValidator.prototype.validateNameLength = function (control) {
        var nameLength = control.value;
        if (nameLength === undefined || nameLength === null || nameLength === "") {
            return null;
        }
        if (nameLength.length >= 3 && nameLength.length <= 50) {
            return null;
        }
        return { 'nameLengthInvalid': true };
    };
    // case sensitive
    CommonValidator.prototype.validateDuplicateFieldName = function (nameList) {
        return function (control) {
            if (nameList && nameList.indexOf(control.value) >= 0) {
                return { "duplicateNameInvalid": true };
            }
            else {
                return null;
            }
        };
    };
    // case insensitive
    CommonValidator.prototype.validateDuplicateName = function (nameList) {
        return function (control) {
            if (nameList && nameList.indexOf(control.value.toUpperCase()) >= 0) {
                return { "duplicateNameInvalid": true };
            }
            else {
                return null;
            }
        };
    };
    CommonValidator.prototype.validateAddress = function (control) {
        if (control.value === undefined || control.value === null || control.value === "") {
            return null;
        }
        var domainNameCheck = /^(?!\.)^[A-z0-9\-_]*\.[A-z0-9\-_\.]+[A-z0-9\-_]$/;
        var ipAddressCheck = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (domainNameCheck.test(control.value) || ipAddressCheck.test(control.value)) {
            return null;
        }
        return { 'serverAddressInvalid': true };
    };
    CommonValidator.prototype.validatePort = function (control) {
        var portNumber = control.value;
        if (portNumber === undefined || portNumber === null || portNumber === "") {
            return null;
        }
        if (portNumber > 0 && portNumber < 66536) {
            return null;
        }
        return { 'portInvalid': true };
    };
    CommonValidator.prototype.validateCronSchedule = function (control) {
        if (control.value === undefined || control.value === null || control.value === "") {
            return null;
        }
        // let cronRegex = /^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/;
        try {
            cronstrue_1.default.toString(control.value);
            return null;
        }
        catch (_a) {
            return { 'cronScheduleInvalid': true };
        }
    };
    CommonValidator.prototype.validateCronInput = function (control) {
        if (control.value === undefined || control.value === null || control.value === "") {
            return null;
        }
        var cronInputRegex = /^[0-9\,\-\/\*]+$/;
        if (cronInputRegex.test(control.value))
            return null;
        return { 'cronInputInvalid': true };
    };
    CommonValidator.prototype.validateNoAllSpace = function (control) {
        var newVal = control.value.replace(/ /g, '');
        if (!newVal)
            return { "NoAllSpace": true };
        return null;
    };
    CommonValidator.prototype.validatePassword = function (control) {
        if (control.value === undefined || control.value === null || control.value === "") {
            return null;
        }
        var passwordRegex = /[A-Z]/;
        if (control.value.length < 6 || !passwordRegex.test(control.value)) {
            return { "passwordValidationCheck": true };
        }
        return null;
    };
    CommonValidator.prototype.validateEmail = function (control) {
        if (control.value === undefined || control.value === null || control.value === "") {
            return null;
        }
        var emailRegex = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');
        if (!emailRegex.test(control.value)) {
            return { "emailInvalid": true };
        }
        return null;
    };
    CommonValidator.ngInjectableDef = i0.defineInjectable({ factory: function CommonValidator_Factory() { return new CommonValidator(); }, token: CommonValidator, providedIn: "root" });
    return CommonValidator;
}());
exports.CommonValidator = CommonValidator;
