"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var helpers_1 = require("../helpers");
var sweet_alert_service_1 = require("./sweet-alert.service");
var router_1 = require("@angular/router");
var bread_crumb_service_1 = require("./bread-crumb.service");
var router_enum_1 = require("../enums/router.enum");
var GlobalHttpInterceptorService = /** @class */ (function () {
    function GlobalHttpInterceptorService(sweetAlertService, router) {
        this.sweetAlertService = sweetAlertService;
        this.router = router;
        this.count = 0;
    }
    GlobalHttpInterceptorService.prototype.intercept = function (request, next) {
        var _this = this;
        this.count++;
        helpers_1.Helpers.setLoading(true);
        var token = localStorage.getItem('token') || '';
        var newReq = (!request.headers.get('token')) ? request.clone({ headers: request.headers.append('token', token) }) : request.clone({ headers: request.headers.set('token', token) });
        return next.handle(newReq).pipe(operators_1.tap(function (event) {
            if (event instanceof http_1.HttpResponse) {
                var token_1 = event.headers.get('token');
                if (-1 == request.url.indexOf('logout') && token_1 !== undefined && token_1 !== null && token_1.length > 1) {
                    localStorage.setItem('token', token_1);
                }
            }
        }), operators_1.catchError(function (error) {
            switch (error.status) {
                case 401:
                    localStorage.clear();
                    _this.sweetAlertService.info('Timeout', 'You have been logged out for security reason.', function () {
                        _this.router.navigate([bread_crumb_service_1.BreadCrumbService.login().href]).then();
                    });
                    break;
                case 403:
                    _this.sweetAlertService.info('Restricted Role Access, Forbidden Error', 'Sorry, you do not have enough access right to do this action. Please contact System Admin for further handling.', function () { });
                    break;
                case 409:
                    _this.sweetAlertService.info("File not found", "Please check if the file is exist.", function () { });
                    break;
                case 417:
                    _this.sweetAlertService.info("Request URL forbidden", "Please don't call http request by directly calling our system.", function () { });
                    break;
                // case 500:
                //   this.sweetAlertService.info('Internal Server Error', 'Sorry, our server is facing some internal server issue. Please contact System Admin for further handling.',
                //     () => {}
                //   )
                //   break;
                case 504:
                    _this.router.navigate(["/" + router_enum_1.EnumMainPageRoute.Error]).then();
                    // this.sweetAlertService.info('Gateway Timeout', 'Sorry, our server is facing some internal server issue. Please contact System Admin for further handling. ')
                    break;
            }
            // if (error.status == 401) {
            //     localStorage.clear()
            //     this.sweetAlertService.info('Timeout!!', 'Sorry, your session timed out after a long time of inactivity. Please sign in again.', () => {
            //         this.router.navigate([BreadCrumbService.login().href]);
            //     })
            //   // this.sweetAlertService.confirm('confirm!!', () => {
            //   //   console.log('ysss')
            //   //       this.router.navigate([BreadCrumbService.login().href]);
            //     // })
            //   // const popUp = this.sweetAlertService.info('Timeout!!', 'Sorry, your session timed out after a long time of inactivity. Please sign in again.')
            //   // console.log('[popUp]',popUp)
            //   // if( popUp ){
            //   //   console.log('[BreadCrumbService.login().href1]',[BreadCrumbService])
            //   //   console.log('[BreadCrumbService.login().href2]',[BreadCrumbService.login()])
            //   //   console.log('[BreadCrumbService.login().href3]',[BreadCrumbService.login().href])
            //   //   this.router.navigate([BreadCrumbService.login().href]);
            //   // }
            // }
            return rxjs_1.throwError(error);
        }), operators_1.finalize(function () {
            if (--_this.count === 0) {
                helpers_1.Helpers.setLoading(false);
            }
        }));
    };
    return GlobalHttpInterceptorService;
}());
exports.GlobalHttpInterceptorService = GlobalHttpInterceptorService;
exports.GlobalHttpInterceptorProvider = {
    provide: http_1.HTTP_INTERCEPTORS,
    useClass: GlobalHttpInterceptorService,
    multi: true
};
