"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CustomFieldState = /** @class */ (function () {
    function CustomFieldState() {
        this.unlimitedArgNum = false;
        this.minArgNum = 1;
        this.maxArgNum = 1;
        this.restrictedFirstArgType = false;
        this.argType = new Array();
        this.outputDataTypeChoice = new Array();
        this.placeholder = new Array();
    }
    return CustomFieldState;
}());
exports.CustomFieldState = CustomFieldState;
