export enum EnumSmartQueryStatus {
  SmartQueryStatusCreated = "CREATED",
  SmartQueryStatusSaved = "SAVED",
  SmartQueryStatusPending = "PENDING",
  SmartQueryStatusQuerying = "QUERYING",
  SmartQueryStatusQueryReady = "QUERY_READY",
  SmartQueryStatusQueryError = "QUERY_ERROR",
  SmartQueryStatusExporting = "EXPORTING",
  SmartQueryStatusExportFileReady = "EXPORT_FILE_READY"
}

export enum EnumSmartQueryCustomFieldAction {
  ActionAdd = "ADD",
  ActionEdit = "EDIT",
  ActionDelete = "DELETE",
}

export enum EnumSmartQueryMode {
  SmartQueryModePreFilterMain = "PRE_FILTER_MAIN",
  SmartQueryModePreFilterReference = "PRE_FILTER_REFERENCE",
  SmartQueryModePostFilter = "POST_FILTER",
}

export enum EnumSmartQueryDropZone {
  DropZoneField = "field",
  DropZoneMeasure = "measure",
  DropZoneDimension = "dimension",
  DropZoneCustom = "custom"
}

export enum EnumArgumentType {
  Select = "SELECT",
  Input = "INPUT"
}
