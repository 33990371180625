<!--begin::Header-->
<ng-container *ngIf="extrasNotificationsDropdownStyle === 'light'">
  <div class="d-flex flex-column pt-12 bg-dark-o-5 rounded-top">
    <!--begin::Title-->
    <h4 class="d-flex flex-center">
      <span class="text-dark">User Notifications</span>
    </h4>
    <!--end::Title-->

    <!--begin::Tabs-->
    <ul
      class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary mt-3 px-8"
      role="tablist"
    >
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_alerts')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_alerts')"
          >Alerts</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_events')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_events')"
          >Events</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_logs')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_logs')"
          >Logs</a
        >
      </li>
    </ul>
    <!--end::Tabs-->
  </div>
</ng-container>
<!--end::Header-->

<!--begin::Content-->
<div class="tab-content">
  <!--begin::Tabpane-->
  <div
    class="tab-pane"
    id="topbar_notifications_notifications"
    role="tabpanel"
    [ngClass]="getActiveCSSClasses('topbar_notifications_alerts')"
  >
    <!--begin::Scroll-->
    <perfect-scrollbar
      *ngIf="alertNotice.length && alertNotice.length > 0"
      [config]="config" [scrollIndicators]="true" class="scroll-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="float-right">
            <span class="btn btn-link btn-text btn-sm"
                  style="white-space: nowrap;text-align: center;"
                  (click)="navigateToNotificationDashboard(enumNotificationType.Alert)"
            >
             view all
            </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let alert of alertNotice; let i = index">
        <div class="row border">
          <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-center">
              <div class="symbol symbol-40 symbol-light-primary px-3 py-6">
                <span class="symbol-label" [ngSwitch]="alert.realm">
                  <span
                    *ngSwitchCase="enumNotificationRealm.DataWarehouse"
                    [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.DataPipeline"
                    [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-3d.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.SmartQuery"
                    [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.UserGroup"
                    [inlineSVG]="'./assets/media/svg/icons/Communication/Group.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.AccessControl"
                    [inlineSVG]="'./assets/media/svg/icons/General/User.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12 pl-0 pr-5">
                <div class="navi-text">
                    <span *ngIf="!alert.read" class="badge badge-danger top-bar-badge p-1" style="min-width: 30px;">
                      New!
                    </span>
                    <div class="float-right">
                      <span class="font-weight-bold">
                        {{ts - alert.createTs | timeDuration }}
                      </span>
                    </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 py-3 pl-0 pr-5">
                <div class="font-weight-bold" [innerHTML]="alert.message">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div  *ngIf="alertNotice.length == 0"
          class="d-flex flex-center text-center text-muted min-h-200px">
      All caught up!
      <br />
      No new alert notifications.
    </div>
  </div>
  <!--end::Tabpane-->

  <!--begin::Tabpane-->
  <div
    class="tab-pane"
    id="topbar_notifications_events"
    role="tabpanel"
    [ngClass]="getActiveCSSClasses('topbar_notifications_events')"
  >
    <!--begin::Scroll-->
    <perfect-scrollbar *ngIf="eventNotice.length && eventNotice.length > 0"
                       [config]="config" [scrollIndicators]="true" class="scroll-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="float-right">
              <span class="btn btn-link btn-text btn-sm"
                    style="white-space: nowrap;text-align: center;"
                    (click)="navigateToNotificationDashboard(enumNotificationType.Event)"
              >
               view all
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let event of eventNotice; let i = index">
        <div class="row border">
          <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-center">
              <div class="symbol symbol-40 symbol-light-primary px-3 py-6">
                <span class="symbol-label" [ngSwitch]="event.realm">
                  <span
                    *ngSwitchCase="enumNotificationRealm.DataWarehouse"
                    [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.DataPipeline"
                    [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-3d.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.SmartQuery"
                    [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.UserGroup"
                    [inlineSVG]="'./assets/media/svg/icons/Communication/Group.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.AccessControl"
                    [inlineSVG]="'./assets/media/svg/icons/General/User.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12 pl-0 pr-5">
                <div class="navi-text">
                    <span *ngIf="!event.read" class="badge badge-danger top-bar-badge p-1" style="min-width: 30px;">
                      New!
                    </span>
                  <div class="float-right">
                      <span class="font-weight-bold">
                        {{ts - event.createTs | timeDuration }}
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 py-3 pl-0 pr-5">
                <div class="font-weight-bold" [innerHTML]="event.message">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="d-flex flex-center text-center text-muted min-h-200px"
         *ngIf="eventNotice.length == 0">
      All caught up!
      <br />
      No new event notifications.
    </div>
  </div>
  <!--end::Tabpane-->

  <!--begin::Tabpane-->
  <div
    class="tab-pane"
    id="topbar_notifications_logs"
    role="tabpanel"
    [ngClass]="getActiveCSSClasses('topbar_notifications_logs')"
  >
    <!--begin::Scroll-->
    <perfect-scrollbar
      *ngIf="logNotice.length && logNotice.length > 0"
      [config]="config" [scrollIndicators]="true" class="scroll-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="float-right">
            <span class="btn btn-link btn-text btn-sm"
                  style="white-space: nowrap;text-align: center;"
                  (click)="navigateToNotificationDashboard(enumNotificationType.Log)"
            >
             view all
            </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let log of logNotice; let i = index">
        <div class="row border">
          <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-center">
              <div class="symbol symbol-40 symbol-light-primary px-3 py-6">
                <span class="symbol-label" [ngSwitch]="log.realm">
                  <span
                    *ngSwitchCase="enumNotificationRealm.DataWarehouse"
                    [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.DataPipeline"
                    [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-3d.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.SmartQuery"
                    [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.UserGroup"
                    [inlineSVG]="'./assets/media/svg/icons/Communication/Group.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                  <span
                    *ngSwitchCase="enumNotificationRealm.AccessControl"
                    [inlineSVG]="'./assets/media/svg/icons/General/User.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-xl svg-icon-danger"
                  ></span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12 pl-0 pr-5">
                <div class="navi-text">
                    <span *ngIf="!log.read" class="badge badge-danger top-bar-badge p-1" style="min-width: 30px;">
                      New!
                    </span>
                  <div class="float-right">
                      <span class="font-weight-bold">
                        {{ts - log.createTs | timeDuration }}
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 py-3 pl-0 pr-5">
                <div class="font-weight-bold" [innerHTML]="log.message"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div  *ngIf="logNotice.length == 0"
      class="d-flex flex-center text-center text-muted min-h-200px">
      All caught up!
      <br />
      No new log notifications.
    </div>
  </div>
  <!--end::Tabpane-->

  <!--begin::Tabpane-->
<!--  <div-->
<!--    class="tab-pane"-->
<!--    id="topbar_notifications_settings"-->
<!--    role="tabpanel"-->
<!--    [ngClass]="getActiveCSSClasses('topbar_notifications_settings')"-->
<!--  >-->
<!--    <div-->
<!--      class="scroll pr-7 mr-n7 ps"-->
<!--      data-scroll="true"-->
<!--      perfectScrollbar-->
<!--      style="max-height: 300px; position: relative"-->
<!--    >-->
<!--      <div *ngFor="let setting of noticeSetting; let i = index">-->
<!--        <div class="row">-->
<!--          <div class="col-md-9">-->
<!--            <div class="d-flex align-items-center">-->
<!--              &lt;!&ndash;begin::Symbol&ndash;&gt;-->
<!--              <div class="symbol symbol-40 symbol-light-primary mr-5 ml-3 mb-6">-->
<!--                <span class="symbol-label" [ngSwitch]="setting.realm">-->
<!--                  <span-->
<!--                    *ngSwitchCase="enumNotificationRealm.DataWarehouse"-->
<!--                    [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"-->
<!--                    cacheSVG="true"-->
<!--                    class="svg-icon svg-icon-xl svg-icon-danger"-->
<!--                  ></span>-->
<!--                  <span-->
<!--                    *ngSwitchCase="enumNotificationRealm.DataPipeline"-->
<!--                    [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-3d.svg'"-->
<!--                    cacheSVG="true"-->
<!--                    class="svg-icon svg-icon-xl svg-icon-danger"-->
<!--                  ></span>-->
<!--                  <span-->
<!--                    *ngSwitchCase="enumNotificationRealm.SmartQuery"-->
<!--                    [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"-->
<!--                    cacheSVG="true"-->
<!--                    class="svg-icon svg-icon-xl svg-icon-danger"-->
<!--                  ></span>-->
<!--                  <span-->
<!--                    *ngSwitchCase="enumNotificationRealm.UserGroup"-->
<!--                    [inlineSVG]="'./assets/media/svg/icons/Communication/Group.svg'"-->
<!--                    cacheSVG="true"-->
<!--                    class="svg-icon svg-icon-xl svg-icon-danger"-->
<!--                  ></span>-->
<!--                  <span-->
<!--                    *ngSwitchCase="enumNotificationRealm.AccessControl"-->
<!--                    [inlineSVG]="'./assets/media/svg/icons/General/User.svg'"-->
<!--                    cacheSVG="true"-->
<!--                    class="svg-icon svg-icon-xl svg-icon-danger"-->
<!--                  ></span>-->
<!--                </span>-->
<!--              </div>-->
<!--              &lt;!&ndash;end::Symbol&ndash;&gt;-->
<!--              &lt;!&ndash;begin::Text&ndash;&gt;-->
<!--              <div class="navi-text mb-6">-->
<!--                <div class="font-weight-bold">-->
<!--                  {{setting.realm}}-->
<!--                </div>-->
<!--                <span class="text-muted">-->
<!--                  {{setting.type}}-->
<!--                </span>-->
<!--              </div>-->
<!--              &lt;!&ndash;end::Text&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-3 mt-3">-->
<!--            <div class="d-flex flex-column">-->
<!--              <mat-slide-toggle [(ngModel)]="setting.emailEnabled" ></mat-slide-toggle>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <!--end::Tabpane-->

</div>
<!--end::Content-->
