"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumSmartQueryStatus;
(function (EnumSmartQueryStatus) {
    EnumSmartQueryStatus["SmartQueryStatusCreated"] = "CREATED";
    EnumSmartQueryStatus["SmartQueryStatusSaved"] = "SAVED";
    EnumSmartQueryStatus["SmartQueryStatusPending"] = "PENDING";
    EnumSmartQueryStatus["SmartQueryStatusQuerying"] = "QUERYING";
    EnumSmartQueryStatus["SmartQueryStatusQueryReady"] = "QUERY_READY";
    EnumSmartQueryStatus["SmartQueryStatusQueryError"] = "QUERY_ERROR";
    EnumSmartQueryStatus["SmartQueryStatusExporting"] = "EXPORTING";
    EnumSmartQueryStatus["SmartQueryStatusExportFileReady"] = "EXPORT_FILE_READY";
})(EnumSmartQueryStatus = exports.EnumSmartQueryStatus || (exports.EnumSmartQueryStatus = {}));
var EnumSmartQueryCustomFieldAction;
(function (EnumSmartQueryCustomFieldAction) {
    EnumSmartQueryCustomFieldAction["ActionAdd"] = "ADD";
    EnumSmartQueryCustomFieldAction["ActionEdit"] = "EDIT";
    EnumSmartQueryCustomFieldAction["ActionDelete"] = "DELETE";
})(EnumSmartQueryCustomFieldAction = exports.EnumSmartQueryCustomFieldAction || (exports.EnumSmartQueryCustomFieldAction = {}));
var EnumSmartQueryMode;
(function (EnumSmartQueryMode) {
    EnumSmartQueryMode["SmartQueryModePreFilterMain"] = "PRE_FILTER_MAIN";
    EnumSmartQueryMode["SmartQueryModePreFilterReference"] = "PRE_FILTER_REFERENCE";
    EnumSmartQueryMode["SmartQueryModePostFilter"] = "POST_FILTER";
})(EnumSmartQueryMode = exports.EnumSmartQueryMode || (exports.EnumSmartQueryMode = {}));
var EnumSmartQueryDropZone;
(function (EnumSmartQueryDropZone) {
    EnumSmartQueryDropZone["DropZoneField"] = "field";
    EnumSmartQueryDropZone["DropZoneMeasure"] = "measure";
    EnumSmartQueryDropZone["DropZoneDimension"] = "dimension";
    EnumSmartQueryDropZone["DropZoneCustom"] = "custom";
})(EnumSmartQueryDropZone = exports.EnumSmartQueryDropZone || (exports.EnumSmartQueryDropZone = {}));
var EnumArgumentType;
(function (EnumArgumentType) {
    EnumArgumentType["Select"] = "SELECT";
    EnumArgumentType["Input"] = "INPUT";
})(EnumArgumentType = exports.EnumArgumentType || (exports.EnumArgumentType = {}));
