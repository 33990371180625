"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var context_data_service_1 = require("../../global-services/context-data.service");
var error_handler_service_1 = require("../../global-services/error-handler.service");
var user_role_enum_1 = require("../../enums/user-role.enum");
var i0 = require("@angular/core");
var RoleAccessHelper = /** @class */ (function () {
    function RoleAccessHelper() {
    }
    RoleAccessHelper.prototype.isEnabledAccess = function (roleAccess) {
        var newArray = context_data_service_1.ContextDataService.roleAccess.filter(function (ra) {
            return ra['functionName'] === roleAccess;
        });
        return newArray[0]['enabled'];
    };
    RoleAccessHelper.prototype.roleAccessNotSetError = function (role) {
        if (role === user_role_enum_1.EnumUserRole.SystemAdmin) {
            error_handler_service_1.GlobalErrorHandler.info("Restricted Access!!", "Sorry, " + role + " access right setting is not present'. Please restart the backend program.", function () { });
            return;
        }
        var authorizeRole = (role === user_role_enum_1.EnumUserRole.OrganizationAdmin) ? user_role_enum_1.EnumUserRole.SystemAdmin : user_role_enum_1.EnumUserRole.OrganizationAdmin;
        error_handler_service_1.GlobalErrorHandler.info("Restricted Access!!", "Sorry, " + role + " access right setting is not present'. Please notify your " + authorizeRole + " for further access.", function () { });
    };
    RoleAccessHelper.prototype.roleAccessErrorHandling = function (roleAccess) {
        error_handler_service_1.GlobalErrorHandler.info("Restricted Access!!", "Sorry, you are not authenticated to '" + roleAccess + "'. Please notify your organization admin for further access.", function () { return console.log("Confirmed !"); });
    };
    RoleAccessHelper.ngInjectableDef = i0.defineInjectable({ factory: function RoleAccessHelper_Factory() { return new RoleAccessHelper(); }, token: RoleAccessHelper, providedIn: "root" });
    return RoleAccessHelper;
}());
exports.RoleAccessHelper = RoleAccessHelper;
