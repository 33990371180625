"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumDateSymbol;
(function (EnumDateSymbol) {
    EnumDateSymbol["colon"] = ":";
    EnumDateSymbol["dot"] = ".";
    EnumDateSymbol["minus"] = "-";
    EnumDateSymbol["slash"] = "/";
    EnumDateSymbol["space"] = " ";
})(EnumDateSymbol = exports.EnumDateSymbol || (exports.EnumDateSymbol = {}));
var EnumDateFormat;
(function (EnumDateFormat) {
    EnumDateFormat["MM"] = "MM";
    EnumDateFormat["MMM"] = "MMM";
    EnumDateFormat["MMMM"] = "MMMM";
    EnumDateFormat["dd"] = "dd";
    EnumDateFormat["yy"] = "yy";
    EnumDateFormat["yyyy"] = "yyyy";
    EnumDateFormat["HH"] = "HH";
    EnumDateFormat["mm"] = "mm";
    EnumDateFormat["ss"] = "ss";
    EnumDateFormat["SSS"] = "SSS";
    EnumDateFormat["z"] = "z";
    EnumDateFormat["timestamp13"] = "Timestamp-13-digit";
    EnumDateFormat["timestamp10"] = "Timestamp-10-digit";
})(EnumDateFormat = exports.EnumDateFormat || (exports.EnumDateFormat = {}));
