"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var dynamic_header_menu_config_1 = require("../../configs/dynamic-header-menu.config");
var i0 = require("@angular/core");
var emptyMenuConfig = {
    items: []
};
var DynamicHeaderMenuService = /** @class */ (function () {
    function DynamicHeaderMenuService() {
        this.menuConfigSubject = new rxjs_1.BehaviorSubject(emptyMenuConfig);
        this.menuConfig$ = this.menuConfigSubject.asObservable();
        this.loadMenu();
    }
    // Here you able to load your menu from server/data-base/localeStorage
    // Default => from DynamicHeaderMenuConfig
    DynamicHeaderMenuService.prototype.loadMenu = function () {
        this.setMenu(dynamic_header_menu_config_1.DynamicHeaderMenuConfig);
    };
    DynamicHeaderMenuService.prototype.setMenu = function (menuConfig) {
        this.menuConfigSubject.next(menuConfig);
    };
    DynamicHeaderMenuService.prototype.getMenu = function () {
        return this.menuConfigSubject.value;
    };
    DynamicHeaderMenuService.ngInjectableDef = i0.defineInjectable({ factory: function DynamicHeaderMenuService_Factory() { return new DynamicHeaderMenuService(); }, token: DynamicHeaderMenuService, providedIn: "root" });
    return DynamicHeaderMenuService;
}());
exports.DynamicHeaderMenuService = DynamicHeaderMenuService;
