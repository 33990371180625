"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var layout_service_1 = require("./layout.service");
var i0 = require("@angular/core");
var i1 = require("./layout.service");
var LayoutInitService = /** @class */ (function () {
    function LayoutInitService(layout) {
        this.layout = layout;
    }
    LayoutInitService.prototype.init = function () {
        this.layout.initConfig();
        this.preInitLayout();
        // init base layout
        this.initLayout();
        this.initLoader();
        // init header and subheader menu
        this.initHeader();
        this.initSubheader();
        // init content
        this.initContent();
        // init aside and aside menu
        this.initAside();
        // init footer
        this.initFooter();
        this.initSkins();
    };
    // init base layout
    LayoutInitService.prototype.preInitLayout = function () {
        var config = this.layout.getConfig();
        var updatedConfig = Object.assign({}, config);
        var subheaderFixed = this.layout.getProp('subheader.fixed');
        var headerSelfFixedDesktop = this.layout.getProp('header.self.fixed.desktop');
        if (subheaderFixed && headerSelfFixedDesktop) {
            updatedConfig.subheader.style = 'solid';
        }
        else {
            updatedConfig.subheader.fixed = false;
        }
        this.layout.setConfigWithoutLocalStorageChanges(updatedConfig);
    };
    LayoutInitService.prototype.initLayout = function () {
        var selfBodyBackgroundImage = this.layout.getProp('self.body.background-image');
        if (selfBodyBackgroundImage) {
            document.body.style.backgroundImage = "url(\"" + selfBodyBackgroundImage + "\")";
        }
        var selfBodyClass = (this.layout.getProp('self.body.class') || '').toString();
        if (selfBodyClass) {
            var bodyClasses = selfBodyClass.split(' ');
            bodyClasses.forEach(function (cssClass) { return document.body.classList.add(cssClass); });
        }
    };
    LayoutInitService.prototype.initLoader = function () { };
    // init header and subheader menu
    LayoutInitService.prototype.initHeader = function () {
        // Fixed header
        var headerSelfFixedDesktop = this.layout.getProp('header.self.fixed.desktop');
        if (headerSelfFixedDesktop) {
            document.body.classList.add('header-fixed');
            this.layout.setCSSClass('header', 'header-fixed');
        }
        else {
            document.body.classList.add('header-static');
        }
        var headerSelfFixedMobile = this.layout.getProp('header.self.fixed.mobile');
        if (headerSelfFixedMobile) {
            document.body.classList.add('header-mobile-fixed');
            this.layout.setCSSClass('header_mobile', 'header-mobile-fixed');
        }
        // Menu
        var headerMenuSelfDisplay = this.layout.getProp('header.menu.self.display');
        var headerMenuSelfLayout = this.layout.getProp('header.menu.self.layout');
        if (headerMenuSelfDisplay) {
            this.layout.setCSSClass('header_menu', "header-menu-layout-" + headerMenuSelfLayout);
            if (this.layout.getProp('header.menu.self.rootArrow')) {
                this.layout.setCSSClass('header_menu', 'header-menu-root-arrow');
            }
        }
        if (this.layout.getProp('header.self.width') === 'fluid') {
            this.layout.setCSSClass('header_container', 'container-fluid');
        }
        else {
            this.layout.setCSSClass('header_container', 'container');
        }
    };
    LayoutInitService.prototype.initSubheader = function () {
        var subheaderDisplay = this.layout.getProp('subheader.display');
        if (subheaderDisplay) {
            document.body.classList.add('subheader-enabled');
        }
        else {
            return;
        }
        // Fixed content head
        var subheaderFixed = this.layout.getProp('subheader.fixed');
        var headerSelfFixedDesktop = this.layout.getProp('header.self.fixed.desktop');
        if (subheaderFixed && headerSelfFixedDesktop) {
            document.body.classList.add('subheader-fixed');
        }
        var subheaderStyle = this.layout.getProp('subheader.style');
        if (subheaderStyle) {
            this.layout.setCSSClass('subheader', "subheader-" + subheaderStyle);
        }
        if (this.layout.getProp('subheader.width') === 'fluid') {
            this.layout.setCSSClass('subheader_container', 'container-fluid');
        }
        else {
            this.layout.setCSSClass('subheader_container', 'container');
        }
        if (this.layout.getProp('subheader.clear')) {
            this.layout.setCSSClass('subheader', 'mb-0');
        }
    };
    // init content
    LayoutInitService.prototype.initContent = function () {
        if (this.layout.getProp('content.fit-top')) {
            this.layout.setCSSClass('content', 'pt-0');
        }
        if (this.layout.getProp('content.fit-bottom')) {
            this.layout.setCSSClass('content', 'pb-0');
        }
        if (this.layout.getProp('content.width') === 'fluid') {
            this.layout.setCSSClass('content_container', 'container-fluid');
        }
        else {
            this.layout.setCSSClass('content_container', 'container');
        }
    };
    // init aside and aside menu
    LayoutInitService.prototype.initAside = function () {
        if (this.layout.getProp('aside.self.display') !== true) {
            return;
        }
        // Enable Aside
        document.body.classList.add('aside-enabled');
        // Fixed Aside
        if (this.layout.getProp('aside.self.fixed')) {
            document.body.classList.add('aside-fixed');
            this.layout.setCSSClass('aside', 'aside-fixed');
        }
        else {
            document.body.classList.add('aside-static');
        }
        // Check Aside
        if (this.layout.getProp('aside.self.display') !== true) {
            return;
        }
        // Default fixed
        if (this.layout.getProp('aside.self.minimize.default')) {
            document.body.classList.add('aside-minimize');
        }
        if (this.layout.getProp('aside.self.minimize.hoverable')) {
            document.body.classList.add('aside-minimize-hoverable');
        }
        // Menu
        // Dropdown Submenu
        var asideMenuDropdown = this.layout.getProp('aside.menu.dropdown');
        if (asideMenuDropdown) {
            this.layout.setCSSClass('aside_menu', 'aside-menu-dropdown');
            this.layout.setHTMLAttribute('aside_menu', 'data-menu-dropdown', '1');
        }
        // Scrollable Menu
        if (asideMenuDropdown !== true) {
            this.layout.setHTMLAttribute('aside_menu', 'data-menu-scroll', '1');
        }
        else {
            this.layout.setHTMLAttribute('aside_menu', 'data-menu-scroll', '0');
        }
        var asideMenuSubmenuDropdownHoverTimout = this.layout.getProp('aside.menu.submenu.dropdown.hoverTimeout');
        if (asideMenuSubmenuDropdownHoverTimout) {
            this.layout.setHTMLAttribute('aside_menu', 'data-menu-dropdown-timeout', asideMenuSubmenuDropdownHoverTimout);
        }
    };
    // init footer
    LayoutInitService.prototype.initFooter = function () {
        // Fixed header
        if (this.layout.getProp('footer.fixed') === true) {
            document.body.classList.add('footer-fixed');
        }
        if (this.layout.getProp('footer.width') === 'fluid') {
            this.layout.setCSSClass('footer_container', 'container-fluid');
        }
        else {
            this.layout.setCSSClass('footer_container', 'container');
        }
    };
    /**
     * Set the body class name based on page skin options
     */
    LayoutInitService.prototype.initSkins = function () {
        var headerSelfTheme = this.layout.getProp('header.self.theme') || '';
        var brandSelfTheme = this.layout.getProp('brand.self.theme') || '';
        var asideSelfDisplay = this.layout.getProp('aside.self.display');
        if (asideSelfDisplay === false) {
            document.body.classList.add("brand-" + headerSelfTheme);
        }
        else {
            document.body.classList.add("brand-" + brandSelfTheme);
        }
    };
    LayoutInitService.ngInjectableDef = i0.defineInjectable({ factory: function LayoutInitService_Factory() { return new LayoutInitService(i0.inject(i1.LayoutService)); }, token: LayoutInitService, providedIn: "root" });
    return LayoutInitService;
}());
exports.LayoutInitService = LayoutInitService;
