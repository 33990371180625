import {NgModule} from "@angular/core";
import {NotEmptyValidator} from "./validators/not-empty.validator";
import {NumericValidator} from "./validators/numeric.validator";
import {NoRepeatValidator} from "./validators/no-repeat.validator";
import {TelephoneValidator} from "./validators/telephone.validator";
import {PasswordCheckValidator} from "./validators/password-check.validator";
import {AddressCheckValidator} from "./validators/address-check.validator";
import {PortCheckValidator} from "./validators/port-check.validator";
import {FolderPathCheckValidator} from "./validators/folderPath-check.validator";
import {LogicSequenceCheckValidator} from "./validators/logic-sequence-check.validator";
import {EntityNameValidator} from "./validators/entity-name.validator";
import {CronExpressionCheckValidator} from "./validators/cron-expression-check.validator";
import {NewColumnNameValidator} from "./validators/new-column-name.validator";
import {DuplicateNameCheckValidator} from "./validators/duplicate-name-check.validator";
import {EmptyFieldCheckValidator} from "./validators/empty-field-check.validator";
import {RegexBlockCheckValidator} from "./validators/regex-block-check.validator";
import {NoAllSpaceValidator} from "./validators/no-all-space.validator";
import {EmailValidator} from "./validators/email.validator";
import {ObjectCheckValidator} from "./validators/object-check.validator";

@NgModule({
  imports: [
  ],
  declarations: [
    NotEmptyValidator,
    ObjectCheckValidator,
    NumericValidator,
    NoRepeatValidator,
    TelephoneValidator,
    PasswordCheckValidator,
    AddressCheckValidator,
    PortCheckValidator,
    FolderPathCheckValidator,
    LogicSequenceCheckValidator,
    EntityNameValidator,
    CronExpressionCheckValidator,
    NewColumnNameValidator,
    DuplicateNameCheckValidator,
    EmptyFieldCheckValidator,
    RegexBlockCheckValidator,
    NoAllSpaceValidator,
    EmailValidator,
  ],
  exports: [
    NotEmptyValidator,
    ObjectCheckValidator,
    NumericValidator,
    NoRepeatValidator,
    TelephoneValidator,
    PasswordCheckValidator,
    AddressCheckValidator,
    PortCheckValidator,
    FolderPathCheckValidator,
    LogicSequenceCheckValidator,
    EntityNameValidator,
    CronExpressionCheckValidator,
    NewColumnNameValidator,
    DuplicateNameCheckValidator,
    EmptyFieldCheckValidator,
    RegexBlockCheckValidator,
    NoAllSpaceValidator,
    EmailValidator,
  ]
})

export class ValidatorsModule {}
