import {Component, OnInit} from "@angular/core";
import {SplashRoutingService} from "./splash-routing.service";
import {Observable, Observer} from "rxjs";

@Component({
  selector: 'app-splash-routing',
  templateUrl: './splash-routing.component.html',
  styleUrls: ['./splash-routing.component.scss'],
})
export class SplashRoutingComponent implements OnInit {
  isShow: boolean = false;
  isShowObs = new Observable((observer: Observer<any>) => {
    setInterval(() => observer.next(this.isShow), 150)
  })

  constructor(private splashRoutingService: SplashRoutingService) {
  }

  ngOnInit() {
    this.splashRoutingService.splashShowChange.subscribe((isShow: boolean) => {
      this.isShow = isShow;
    })
  }
}
