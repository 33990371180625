import {AbstractControl, ValidatorFn} from "@angular/forms";
import {Injectable} from "@angular/core";
import cronstrue from "cronstrue";

@Injectable({
  providedIn: 'root'
})
export class CommonValidator {


  validateRegexBlock(control: AbstractControl): { [key: string]: any} | null  {
    let specials=/[*|":<>[\]{}`\\()';@&$]/;
    if (specials.test(control.value)) return  {"regexBlockCheck": true};
    return null;
  }

  validateNameLength(control: AbstractControl): { [key: string]: any} | null {
    let nameLength: string = control.value;
    if (nameLength === undefined || nameLength === null || nameLength === "") {
      return null;
    }

    if (nameLength.length >= 3 && nameLength.length <= 50) {
      return null;
    }
    return {'nameLengthInvalid': true};
  }

  // case sensitive
  validateDuplicateFieldName(nameList: any): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (nameList && nameList.indexOf(control.value) >= 0) {
        return {"duplicateNameInvalid": true};
      } else {
        return null;
      }
    }
  }

  // case insensitive
  validateDuplicateName(nameList: any): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (nameList && nameList.indexOf(control.value.toUpperCase()) >= 0) {
        return {"duplicateNameInvalid": true};
      } else {
        return null;
      }
    }
  }

  validateAddress(control: AbstractControl): { [key: string]: any } | null {
    if (control.value === undefined || control.value === null || control.value === "") {
      return null;
    }

    let domainNameCheck = /^(?!\.)^[A-z0-9\-_]*\.[A-z0-9\-_\.]+[A-z0-9\-_]$/;
    let ipAddressCheck = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (domainNameCheck.test(control.value) || ipAddressCheck.test(control.value)) {
      return null;
    }
    return {'serverAddressInvalid' : true};
  }

  validatePort(control: AbstractControl): { [key: string]: any} | null {
    let portNumber: number | string = control.value;
    if (portNumber === undefined || portNumber === null || portNumber === "") {
      return null;
    }

    if (portNumber > 0 && portNumber < 66536) {
      return null;
    }
    return {'portInvalid': true};
  }

  validateCronSchedule(control: AbstractControl): { [key: string]: any} | null {
    if (control.value === undefined || control.value === null || control.value === "") {
      return null;
    }

    // let cronRegex = /^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/;
    try {
      cronstrue.toString(control.value);
      return null
    } catch {
      return {'cronScheduleInvalid': true};
    }
  }

  validateCronInput(control: AbstractControl): { [key: string]: any} | null {
    if (control.value === undefined || control.value === null || control.value === "") {
      return null;
    }

    let cronInputRegex = /^[0-9\,\-\/\*]+$/;
    if (cronInputRegex.test(control.value)) return null;
    return {'cronInputInvalid': true};
  }

  validateNoAllSpace(control: AbstractControl): { [key: string]: any } | null {
    let newVal = control.value.replace(/ /g, '');
    if (!newVal) return {"NoAllSpace": true};
    return null;
  }

  validatePassword(control: AbstractControl): { [key: string]: any } | null {
    if (control.value === undefined || control.value === null || control.value === "") {
      return null;
    }

    let passwordRegex = /[A-Z]/;
    if (control.value.length < 6 || !passwordRegex.test(control.value)) {
      return {"passwordValidationCheck": true};
    }
    return null;
  }

  validateEmail(control: AbstractControl): {[key: string]: any} | null {
    if (control.value === undefined || control.value === null || control.value === "") {
      return null;
    }

    let emailRegex = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');
    if (!emailRegex.test(control.value)) {
      return {"emailInvalid": true};
    }
    return null;
  }

}
