import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({name: 'timeDuration'})
export class TimeDurationPipe implements PipeTransform {


    transform1(value: Object): string {

        let time = Number(value) / 1000;

        let minute: number = Math.floor(time / 60);
        let second: number = time % 60;

        if (time < 60) {
            return time.toFixed(1) + ' Second(s)';
        }

        if (second > 0) {
            return minute + ' Minute(s) ' + second.toFixed(1) + ' Second(s)';
        } else {
            return minute + ' Minute(s)';
        }
    }

  transform(value: Object): string {
      let second = Number(value) / 1000;

      if (second <= 0) {
        return 'Just Now';
      }

      let minute = Math.floor(second / 60);

      if (minute <= 0) {
        return 'Just Now';
      }

      let hour = Math.floor(second / 60 / 60);

      if (hour <= 0) {
        return minute + ' minute(s) ago';
      }

      let day =  Math.floor(second / 60 / 60 / 24);

      if (day <= 0) {
        return hour + ' hour(s) ago';
      }

      let month = Math.floor(second / 60 / 60 / 24 / 30);

      if (month <= 0) {
        return day + ' day(s) ago';
      }

      let year = Math.floor(second / 60 / 60 / 24 / 365);

      if (year <= 0) {
        return month + ' month(s) ago';
      }

      return year + ' year(s) ago';
  }
}

