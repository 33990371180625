"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var core_2 = require("../../../../../_metronic/core");
var HeaderMenuDynamicComponent = /** @class */ (function () {
    function HeaderMenuDynamicComponent(layout, router, menu, cdr) {
        this.layout = layout;
        this.router = router;
        this.menu = menu;
        this.cdr = cdr;
        this.subscriptions = [];
    }
    HeaderMenuDynamicComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ulCSSClasses = this.layout.getStringCSSClasses('header_menu_nav');
        this.rootArrowEnabled = this.layout.getProp('header.menu.self.rootArrow');
        this.headerMenuDesktopToggle = this.layout.getProp('header.menu.desktop.toggle');
        // router subscription
        this.currentUrl = this.router.url.split(/[?#]/)[0];
        var routerSubscr = this.router.events.pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd; })).subscribe(function (event) {
            _this.currentUrl = event.url;
            _this.cdr.detectChanges();
        });
        this.subscriptions.push(routerSubscr);
        // menu load
        var menuSubscr = this.menu.menuConfig$.subscribe(function (res) {
            _this.menuConfig = res;
            _this.cdr.detectChanges();
        });
        this.subscriptions.push(menuSubscr);
    };
    HeaderMenuDynamicComponent.prototype.isMenuItemActive = function (path) {
        if (!this.currentUrl || !path) {
            return false;
        }
        if (this.currentUrl === path) {
            return true;
        }
        if (this.currentUrl.indexOf(path) > -1) {
            return true;
        }
        return false;
    };
    HeaderMenuDynamicComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sb) { return sb.unsubscribe(); });
    };
    return HeaderMenuDynamicComponent;
}());
exports.HeaderMenuDynamicComponent = HeaderMenuDynamicComponent;
