<ng-container *ngIf="isLoading">
  <div class="progress progress-modal">
    <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
         style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</ng-container>

<div class="modal-header">
  <div class="modal-title h4">
    Set Schedule
    <app-popover-info
      [popoverTitle]="'Notes for crontab schedule'"
      [popoverContent]="notesForSchedule"
      [placement]="'right'"
    >
    </app-popover-info>
  </div>
</div>
<div class="modal-body">
  <form class="form" [formGroup]="formGroup" (ngSubmit)="save(id)">
    <div class="row">
      <div class="col-lg-12 margin-bottom-10">
        <label>Schedule   <span class="text-secondary">{{notesForSchedule}}</span></label>
        <input
          title
          type="text"
          class="form-control form-control-lg form-control-solid"
          autocomplete="on"
          placeholder="Schedule in crontab format (* * * * *)"
          name="Schedule"
          [(ngModel)]="schedule"
          [ngModelOptions]="{standalone: true}"
          disabled
        />
      </div>
      <div class="col-lg-12 margin-bottom-10 mt-5 mb-5">
        {{cronTranslate}}
      </div>
      <div class="col-lg-2">
        <input
          title
          type="text"
          class="form-control form-group-lg form-control-solid"
          placeholder="minutes"
          name="minutes"
          [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'minutes')"
          [class.is-valid]="formControlHelper.isControlValid(formGroup, 'minutes')"
          formControlName="minutes"
          (ngModelChange)="expressionChange()"
          (click)="onClick('MINUTES')"
        />
        <label>Minutes</label>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'minutes')"
        >
          Minutes is required
        </span>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'cronInputInvalid', 'minutes')"
        >
          Minutes should only contains *,-/ or numbers
        </span>
      </div>
      <div class="col-lg-2">
        <input
          title
          type="text"
          class="form-control form-group-lg form-control-solid"
          placeholder="hours"
          name="hours"
          [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'hours')"
          [class.is-valid]="formControlHelper.isControlValid(formGroup, 'hours')"
          formControlName="hours"
          (ngModelChange)="expressionChange()"
          (click)="onClick('HOURS')"
        />
        <label>Hours</label>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'hours')"
        >
          Hours is required
        </span>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'cronInputInvalid', 'hours')"
        >
          Hours should only contains *,-/ or numbers
        </span>
      </div>
      <div class="col-lg-2">
        <input
          title
          type="text"
          class="form-control form-group-lg form-control-solid"
          placeholder="dayOfMonth"
          name="dayOfMonth"
          [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'dayOfMonth')"
          [class.is-valid]="formControlHelper.isControlValid(formGroup, 'dayOfMonth')"
          formControlName="dayOfMonth"
          (ngModelChange)="expressionChange()"
          (click)="onClick('DAY_OF_MONTH')"
        />
        <label>Day of month</label>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'dayOfMonth')"
        >
          Day of month is required
        </span>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'cronInputInvalid', 'dayOfMonth')"
        >
          Day of month should only contains *,-/ or numbers
        </span>
      </div>
      <div class="col-lg-2">
        <input
          title
          type="text"
          class="form-control form-group-lg form-control-solid"
          placeholder="month"
          name="month"
          [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'month')"
          [class.is-valid]="formControlHelper.isControlValid(formGroup, 'month')"
          formControlName="month"
          (ngModelChange)="expressionChange()"
          (click)="onClick('MONTH')"
        />
        <label>Month</label>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'month')"
        >
          Month is required
        </span>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'cronInputInvalid', 'month')"
        >
          Month should only contains *,-/ or numbers
        </span>
      </div>
      <div class="col-lg-2">
        <input
          title
          type="text"
          class="form-control form-group-lg form-control-solid"
          placeholder="dayOfWeek"
          name="dayOfWeek"
          [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'dayOfWeek')"
          [class.is-valid]="formControlHelper.isControlValid(formGroup, 'dayOfWeek')"
          formControlName="dayOfWeek"
          (ngModelChange)="expressionChange()"
          (click)="onClick('DAY_OF_WEEK')"
        />
        <label>Day of week</label>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'dayOfWeek')"
        >
          Day of week is required
        </span>
        <span
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'cronInputInvalid', 'dayOfWeek')"
        >
          Day of week should only contains *,-/ or numbers
        </span>
      </div>
      <div *ngIf="cronTabTableName !== ''" class="col-lg-8 margin-top-20">
        <h5>{{cronTabTableName}}</h5>
        <table
          class="table table-head-custom table-vertical-center overflow-hidden"
        >
          <thead>
          <th></th>
          <th></th>
          </thead>
          <tbody>
          <tr *ngFor="let info of cronTabFieldTable">
            <td style="width: 30%">{{info.param}}</td>
            <td>{{info.description}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>

</div>

<div class="modal-footer">
  <div>
    <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">
      Cancel
    </button>&nbsp;
    <button type="button" class="btn xx btn-danger xx btn-elevate" [disabled]="findInvalidControls()" (click)="save(id)">
      OK
    </button>
  </div>
</div>
