"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]     .loading-bar{position:absolute;top:0;left:0;right:0;width:100%}[_nghost-%COMP%]     .loading-bar .progress-bar{background-color:#5d78ff}[_nghost-%COMP%]     .topbar-item{height:100%}@media (min-width:1025px){[_nghost-%COMP%]     .topbar, [_nghost-%COMP%]     .topbar-item-wrapper{height:100%;z-index:5}}"];
exports.styles = styles;
