"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var splash_routing_service_1 = require("./splash-routing.service");
var rxjs_1 = require("rxjs");
var SplashRoutingComponent = /** @class */ (function () {
    function SplashRoutingComponent(splashRoutingService) {
        var _this = this;
        this.splashRoutingService = splashRoutingService;
        this.isShow = false;
        this.isShowObs = new rxjs_1.Observable(function (observer) {
            setInterval(function () { return observer.next(_this.isShow); }, 150);
        });
    }
    SplashRoutingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.splashRoutingService.splashShowChange.subscribe(function (isShow) {
            _this.isShow = isShow;
        });
    };
    return SplashRoutingComponent;
}());
exports.SplashRoutingComponent = SplashRoutingComponent;
