"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ɵ0 = {
    // cont know if in need
    // coreLibraryLoader: () => import('highlight.js/lib/core'),
    languages: {
    // xml: () => import('highlight.js/lib/languages/xml'),
    // typescript: () => import('highlight.js/lib/languages/typescript'),
    // scss: () => import('highlight.js/lib/languages/scss'),
    // json: () => import('highlight.js/lib/languages/json')
    },
};
exports.ɵ0 = ɵ0;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
