"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = {
    production: true,
    appVersion: 'v717demo1',
    USERDATA_KEY: 'authf649fc9a5f55',
    isMockEnabled: true,
    apiUrl: 'api'
    // apiUrl: 'mysite.com/api'
};
