import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {InlineSVGModule} from 'ng-inline-svg';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ThemeComponent} from './theme/theme.component';
import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {SplashScreenModule} from './shared/components/splash-screen/splash-screen.module';
// #fake-start#
import {ToastrService} from "./global-services/toastr.service";
import {RouteParamsService} from "./global-services/route-params.service";
import {GlobalHttpInterceptorProvider} from "./global-services/global-http-interceptor.service";
import {ScriptLoaderService} from "./global-services/script-loader.service";
import {SweetAlertService} from "./global-services/sweet-alert.service";
import {BreadCrumbService} from "./global-services/bread-crumb.service";
import {CanDeactivateService} from "./global-services/can-deactivate.service";
import {DataTableAjaxService} from "./global-services/data-table-ajax.service";
import {RouteService} from "./global-services/route.service";
import {ThemeRoutingModule} from "./theme/theme-routing.module";
import {PendingChangesGuard} from "./global-services/can-deactivate-guard.service";
import {ContextDataResolver} from "./theme/context-data.resolver";
import {GlobalErrorHandler} from "./global-services/error-handler.service";
import {DocumentationModule} from "./theme/pages/documentation/documentation.module";
import {DashboardModule} from "./theme/pages/dashboard/dashboard.module";
import {NotificationDashboardModule} from "./theme/pages/notificationDashboard/notificationDashboard.module";
import {DataWareHouseMainModule} from "./theme/pages/dataWareHouse/dataWareHouseMain.module";
import {DataWareHouseMainRoutingModule} from "./theme/pages/dataWareHouse/dataWareHouseMain-routing.module";
import {DataPipelineMainModule} from "./theme/pages/dataPipeline/dataPipelineMain.module";
import {DataPipelineMainRoutingModule} from "./theme/pages/dataPipeline/dataPipelineMain-routing.module";
import {UserGroupMainModule} from "./theme/pages/userGroup/userGroupMain.module";
import {UserGroupMainRoutingModule} from "./theme/pages/userGroup/userGroupMain-routing.module";
import {SmartQueryMainModule} from "./theme/pages/smartQuery/smartQueryMain.module";
import {SmartQueryMainRoutingModule} from "./theme/pages/smartQuery/smartQueryMain-routing.module";
import {AccountMainModule} from "./theme/pages/account/accountMain.module";
import {AccountMainRoutingModule} from "./theme/pages/account/accountMain-routing.module";
import {AuthModule} from "./theme/pages/auth/auth.module";
import {AuthRoutingModule} from "./theme/pages/auth/auth-routing.module";
import {CustomerDashboardMainModule} from "./theme/pages/customerDashboard/customerDashboardMain.module";
import {CustomerDashboardMainRoutingModule} from "./theme/pages/customerDashboard/customerDashboardMain-routing.module";
import {AdminSettingMainModule} from "./theme/pages/adminSetting/adminSettingMain.module";
import {AdminSettingMainRoutingModule} from "./theme/pages/adminSetting/adminSettingMain-routing.module";
import {ErrorsRoutingModule} from "./theme/pages/errors/errors-routing.module";
import {ErrorsModule} from "./theme/pages/errors/errors.module";
import {AccessControlModule} from "./theme/pages/accessControl/accessControl.module";
import {SplashRoutingModules} from "./shared/components/splash-routing/splash-routing.modules";
import {LayoutModule} from "./theme/_layout/layout.module";


@NgModule({
  declarations: [AppComponent, ThemeComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        SplashRoutingModules,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        LayoutModule,
        ThemeRoutingModule,
    ],
  providers: [
    ScriptLoaderService,
    ToastrService,
    GlobalHttpInterceptorProvider,
    SweetAlertService,
    BreadCrumbService,
    CanDeactivateService,
    RouteParamsService,
    DataTableAjaxService,
    RouteService,
    PendingChangesGuard,
    ContextDataResolver,
    GlobalErrorHandler,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        // cont know if in need
        // coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          // xml: () => import('highlight.js/lib/languages/xml'),
          // typescript: () => import('highlight.js/lib/languages/typescript'),
          // scss: () => import('highlight.js/lib/languages/scss'),
          // json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
