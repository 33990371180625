import { SweetAlertService } from './sweet-alert.service';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Injectable } from '@angular/core';
import { Helpers } from '../helpers';

@Injectable()
export class CanDeactivateService {

    constructor(private sweetAlertService: SweetAlertService) {
    }


    confirmToLeave(customStr?: string): Observable<boolean> {
      console.log('confirmToLeave1')

        return new Observable<boolean>((sender: Observer<boolean>) => {
            let confirmationString = 'Are you sure to leave without saving the changes of this page?';
            console.log('confirmToLeave2')
            if (customStr!=null){
                confirmationString = customStr;
            }

            this.sweetAlertService.confirm(confirmationString,
                () => sender.next(true),
                () => {
                    sender.next(false);
                    Helpers.setLoading(false);
                }
            );
        });
    }

    deactivate() {
      console.log('deactivate')

      return new Observable<boolean>((sender: Observer<boolean>) => sender.next(true));
        // return new Observable<boolean>((sender: Observer<boolean>) => sender.next(true));
    }
}
