"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumFunctionList;
(function (EnumFunctionList) {
    EnumFunctionList["DataWarehousePage"] = "DATA_WAREHOUSE_PAGE";
    EnumFunctionList["SimpleQueryPage"] = "SIMPLE_QUERY_PAGE";
    EnumFunctionList["ApiFetching"] = "API_FETCHING";
    EnumFunctionList["HistoryPage"] = "HISTORY_PAGE";
    EnumFunctionList["EntityHistory"] = "ENTITY_HISTORY";
    EnumFunctionList["DownloadErrorReport"] = "DOWNLOAD_ERROR_REPORT";
    EnumFunctionList["FileHistory"] = "FILE_HISTORY";
    EnumFunctionList["DownloadFile"] = "DOWNLOAD_FILE";
    EnumFunctionList["DeleteFile"] = "DELETE_FILE";
    EnumFunctionList["ApiJsonHistory"] = "API_JSON_HISTORY";
    EnumFunctionList["DownloadApiJsonLog"] = "DOWNLOAD_API_JSON_LOG";
    EnumFunctionList["AddEntity"] = "ADD_ENTITY";
    EnumFunctionList["EditEntity"] = "EDIT_ENTITY";
    EnumFunctionList["SubmitForValidationButton"] = "SUBMIT_FOR_VALIDATION_BUTTON";
    EnumFunctionList["RetrySubmissionButton"] = "RETRY_SUBMISSION_BUTTON";
    EnumFunctionList["ViewDataSource"] = "VIEW_DATA_SOURCE";
    EnumFunctionList["EditDataSource"] = "EDIT_DATA_SOURCE";
    EnumFunctionList["EnableDataset"] = "ENABLE_DATASET";
    EnumFunctionList["SourceByGoogleSheet"] = "SOURCE_BY_GOOGLE_SHEET";
    EnumFunctionList["SourceByHttpRequest"] = "SOURCE_BY_HTTP_REQUEST";
    EnumFunctionList["SourceBySftp"] = "SOURCE_BY_SFTP";
    EnumFunctionList["SourceByApiFile"] = "SOURCE_BY_API_FILE";
    EnumFunctionList["SourceByDBConnector"] = "SOURCE_BY_DB_CONNECTOR";
    EnumFunctionList["EnableFileUpload"] = "ENABLE_FILE_UPLOAD";
    EnumFunctionList["EnableApiJson"] = "ENABLE_API_JSON";
    EnumFunctionList["EnableRealTimeApi"] = "ENABLE_REAL_TIME_API";
    EnumFunctionList["BasicRealTimeApi"] = "BASIC_REAL_TIME_API";
    EnumFunctionList["WoocommerceWebhook"] = "WOOCOMMERCE_WEBHOOK";
    EnumFunctionList["ShopifyWebhook"] = "SHOPIFY_WEBHOOK";
    EnumFunctionList["EnableTrackerApi"] = "ENABLE_TRACKER_API";
    EnumFunctionList["ViewDataSchema"] = "VIEW_DATA_SCHEMA";
    EnumFunctionList["EditDataSchema"] = "EDIT_DATA_SCHEMA";
    EnumFunctionList["DeleteEntity"] = "DELETE_ENTITY";
    EnumFunctionList["ViewEntityRelation"] = "VIEW_ENTITY_RELATION";
    EnumFunctionList["AddEntityRelation"] = "ADD_ENTITY_RELATION";
    EnumFunctionList["EditEntityRelation"] = "EDIT_ENTITY_RELATION";
    EnumFunctionList["DeleteEntityRelation"] = "DELETE_ENTITY_RELATION";
    EnumFunctionList["ViewEntityPipelineRelation"] = "VIEW_ENTITY_PIPELINE_RELATION";
    EnumFunctionList["ViewEntityQueryRelation"] = "VIEW_ENTITY_QUERY_RELATION";
    EnumFunctionList["ViewEntityAccess"] = "VIEW_ENTITY_ACCESS";
    EnumFunctionList["AddEntityAccess"] = "ADD_ENTITY_ACCESS";
    EnumFunctionList["EditEntityAccess"] = "EDIT_ENTITY_ACCESS";
    EnumFunctionList["DeleteEntityAccess"] = "DELETE_ENTITY_ACCESS";
    EnumFunctionList["ViewEntityArchive"] = "VIEW_ENTITY_ARCHIVE";
    EnumFunctionList["EditEntityArchive"] = "EDIT_ENTITY_ARCHIVE";
    EnumFunctionList["ViewArchiveHistory"] = "VIEW_ARCHIVE_HISTORY";
    EnumFunctionList["ExportEntityMeta"] = "EXPORT_ENTITY_META";
    EnumFunctionList["ImportEntityMeta"] = "IMPORT_ENTITY_META";
    EnumFunctionList["ViewEntityRecycleBin"] = "VIEW_ENTITY_RECYCLE_BIN";
    EnumFunctionList["RecoverEntity"] = "RECOVER_ENTITY";
    EnumFunctionList["PurgeEntity"] = "PURGE_ENTITY";
    EnumFunctionList["DataPipelinePage"] = "DATA_PIPELINE_PAGE";
    EnumFunctionList["AddPipeline"] = "ADD_PIPELINE";
    EnumFunctionList["EditPipeline"] = "EDIT_PIPELINE";
    EnumFunctionList["DeletePipeline"] = "DELETE_PIPELINE";
    EnumFunctionList["StartPipeline"] = "START_PIPELINE";
    EnumFunctionList["StopPipeline"] = "STOP_PIPELINE";
    EnumFunctionList["ViewPipelineConsole"] = "VIEW_PIPELINE_CONSOLE";
    EnumFunctionList["ClonePipeline"] = "CLONE_PIPELINE";
    EnumFunctionList["ExportPipelineMeta"] = "EXPORT_PIPELINE_META";
    EnumFunctionList["ImportPipelineMeta"] = "IMPORT_PIPELINE_META";
    EnumFunctionList["PipelineHistory"] = "VIEW_PIPELINE_HISTORY";
    EnumFunctionList["DownloadPipelineErrorReport"] = "DOWNLOAD_PIPELINE_ERROR_REPORT";
    EnumFunctionList["PipelinePreviewRun"] = "VIEW_PIPELINE_PREVIEW_RUN";
    EnumFunctionList["EditPipelinePreviewRun"] = "EDIT_PIPELINE_PREVIEW_RUN";
    EnumFunctionList["StartPipelinePreviewRun"] = "START_PIPELINE_PREVIEW_RUN";
    EnumFunctionList["WorkflowControl"] = "VIEW_WORKFLOW_CONTROL";
    EnumFunctionList["EditWorkflowControl"] = "EDIT_WORKFLOW_CONTROL";
    EnumFunctionList["StartWorkflowControl"] = "START_WORKFLOW_CONTROL";
    EnumFunctionList["StopWorkflowControl"] = "STOP_WORKFLOW_CONTROL";
    EnumFunctionList["ViewPipelineAccess"] = "VIEW_PIPELINE_ACCESS";
    EnumFunctionList["AddPipelineAccess"] = "ADD_PIPELINE_ACCESS";
    EnumFunctionList["EditPipelineAccess"] = "EDIT_PIPELINE_ACCESS";
    EnumFunctionList["DeletePipelineAccess"] = "DELETE_PIPELINE_ACCESS";
    EnumFunctionList["ViewPipelineRecycleBin"] = "VIEW_PIPELINE_RECYCLE_BIN";
    EnumFunctionList["RecoverPipeline"] = "RECOVER_PIPELINE";
    EnumFunctionList["PurgePipeline"] = "PURGE_PIPELINE";
    EnumFunctionList["UserGroup"] = "USER_GROUP";
    EnumFunctionList["AddUser"] = "ADD_USER";
    EnumFunctionList["EditUser"] = "EDIT_USER";
    EnumFunctionList["DeleteUser"] = "DELETE_USER";
    EnumFunctionList["AddGroup"] = "ADD_GROUP";
    EnumFunctionList["EditGroup"] = "EDIT_GROUP";
    EnumFunctionList["DeleteGroup"] = "DELETE_GROUP";
    EnumFunctionList["AccessRightManagement"] = "ACCESS_RIGHT_MANAGEMENT";
    EnumFunctionList["AccessControl"] = "ACCESS_CONTROL";
    EnumFunctionList["EditAccessControl"] = "EDIT_ACCESS_CONTROL";
    EnumFunctionList["SmartQueryPage"] = "SMART_QUERY_PAGE";
    EnumFunctionList["ViewSmartQuery"] = "VIEW_SMART_QUERY";
    EnumFunctionList["ExportToFile"] = "EXPORT_TO_FILE";
    EnumFunctionList["DownloadQueryErrorReport"] = "DOWNLOAD_QUERY_ERROR_REPORT";
    EnumFunctionList["AddSmartQuery"] = "ADD_SMART_QUERY";
    EnumFunctionList["EditSmartQuery"] = "EDIT_SMART_QUERY";
    EnumFunctionList["DeleteSmartQuery"] = "DELETE_SMART_QUERY";
    EnumFunctionList["PreviewSmartQuery"] = "PREVIEW_SMART_QUERY";
    EnumFunctionList["StartSmartQuery"] = "START_SMART_QUERY";
    EnumFunctionList["StopSmartQuery"] = "STOP_SMART_QUERY";
    EnumFunctionList["ViewQueryAccess"] = "VIEW_QUERY_ACCESS";
    EnumFunctionList["AddQueryAccess"] = "ADD_QUERY_ACCESS";
    EnumFunctionList["EditQueryAccess"] = "EDIT_QUERY_ACCESS";
    EnumFunctionList["DeleteQueryAccess"] = "DELETE_QUERY_ACCESS";
    EnumFunctionList["SmartSearchPage"] = "SMART_SEARCH_PAGE";
    EnumFunctionList["MyProfilePage"] = "MY_PROFILE_PAGE";
    EnumFunctionList["PersonalInfo"] = "PERSONAL_INFO";
    EnumFunctionList["ChangePassword"] = "CHANGE_PASSWORD";
    EnumFunctionList["ArchiveSetting"] = "ARCHIVE_SETTING";
    EnumFunctionList["NotificationSetting"] = "NOTIFICATION_SETTING";
    EnumFunctionList["AdminSettingPage"] = "ADMIN_SETTING_PAGE";
    EnumFunctionList["ViewSmartSearchSetting"] = "VIEW_SMART_SEARCH_SETTING";
    EnumFunctionList["AddSmartSearchSetting"] = "ADD_SMART_SEARCH_SETTING";
    EnumFunctionList["EditSmartSearchSetting"] = "EDIT_SMART_SEARCH_SETTING";
    EnumFunctionList["DeleteSmartSearchSetting"] = "DELETE_SMART_SEARCH_SETTING";
    EnumFunctionList["ViewSearchAccess"] = "VIEW_SEARCH_ACCESS";
    EnumFunctionList["AddSearchAccess"] = "ADD_SEARCH_ACCESS";
    EnumFunctionList["EditSearchAccess"] = "EDIT_SEARCH_ACCESS";
    EnumFunctionList["DeleteSearchAccess"] = "DELETE_SEARCH_ACCESS";
    EnumFunctionList["NotificationUserSetting"] = "NOTIFICATION_USER_SETTING";
    EnumFunctionList["ViewWatchdogSetting"] = "VIEW_WATCHDOG_SETTING";
})(EnumFunctionList = exports.EnumFunctionList || (exports.EnumFunctionList = {}));
