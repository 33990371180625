"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("src/environments/environment");
var rxjs_1 = require("rxjs");
var default_layout_config_1 = require("../../configs/default-layout.config");
var objectPath = require("object-path");
var i0 = require("@angular/core");
var LAYOUT_CONFIG_LOCAL_STORAGE_KEY = environment_1.environment.appVersion + "-layoutConfig";
var LayoutService = /** @class */ (function () {
    function LayoutService() {
        this.layoutConfigSubject = new rxjs_1.BehaviorSubject(undefined);
        // scope list of css classes
        this.classes = {
            header: [],
            header_container: [],
            header_mobile: [],
            header_menu: [],
            aside_menu: [],
            subheader: [],
            subheader_container: [],
            content: [],
            content_container: [],
            footer_container: [],
        };
        // scope list of html attributes
        this.attrs = {
            aside_menu: {},
        };
    }
    LayoutService.prototype.initConfig = function () {
        var configFromLocalStorage = localStorage.getItem(LAYOUT_CONFIG_LOCAL_STORAGE_KEY);
        if (configFromLocalStorage) {
            try {
                this.layoutConfigSubject.next(JSON.parse(configFromLocalStorage));
                return;
            }
            catch (error) {
                this.removeConfig();
                console.error('config parse from local storage', error);
            }
        }
        this.layoutConfigSubject.next(default_layout_config_1.DefaultLayoutConfig);
    };
    LayoutService.prototype.removeConfig = function () {
        localStorage.removeItem(LAYOUT_CONFIG_LOCAL_STORAGE_KEY);
    };
    LayoutService.prototype.refreshConfigToDefault = function () {
        this.setConfigWithPageRefresh(undefined);
    };
    LayoutService.prototype.getConfig = function () {
        var config = this.layoutConfigSubject.value;
        if (!config) {
            return default_layout_config_1.DefaultLayoutConfig;
        }
        return config;
    };
    LayoutService.prototype.setConfig = function (config) {
        if (!config) {
            this.removeConfig();
        }
        else {
            localStorage.setItem(LAYOUT_CONFIG_LOCAL_STORAGE_KEY, JSON.stringify(config));
        }
        this.layoutConfigSubject.next(config);
    };
    LayoutService.prototype.setConfigWithoutLocalStorageChanges = function (config) {
        this.layoutConfigSubject.next(config);
    };
    LayoutService.prototype.setConfigWithPageRefresh = function (config) {
        this.setConfig(config);
        document.location.reload();
    };
    LayoutService.prototype.getProp = function (path) {
        return objectPath.get(this.layoutConfigSubject.value, path);
    };
    LayoutService.prototype.setCSSClass = function (path, classesInStr) {
        var _this = this;
        var cssClasses = this.classes[path];
        if (!cssClasses) {
            this.classes[path] = [];
        }
        classesInStr
            .split(' ')
            .forEach(function (cssClass) { return _this.classes[path].push(cssClass); });
    };
    LayoutService.prototype.getCSSClasses = function (path) {
        var cssClasses = this.classes[path];
        if (!cssClasses) {
            return [];
        }
        return cssClasses;
    };
    LayoutService.prototype.getStringCSSClasses = function (path) {
        return this.getCSSClasses(path).join(' ');
    };
    LayoutService.prototype.getHTMLAttributes = function (path) {
        var attributesObj = this.attrs[path];
        if (!attributesObj) {
            return {};
        }
        return attributesObj;
    };
    LayoutService.prototype.setHTMLAttribute = function (path, attrKey, attrValue) {
        var attributesObj = this.attrs[path];
        if (!attributesObj) {
            this.attrs[path] = {};
        }
        this.attrs[path][attrKey] = attrValue;
    };
    LayoutService.ngInjectableDef = i0.defineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(); }, token: LayoutService, providedIn: "root" });
    return LayoutService;
}());
exports.LayoutService = LayoutService;
