import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LayoutService} from '../../../../_metronic/core';
import KTLayoutQuickSearch from '../../../../../assets/js/layout/extended/quick-search';
import KTLayoutQuickNotifications from '../../../../../assets/js/layout/extended/quick-notifications';
import KTLayoutQuickActions from '../../../../../assets/js/layout/extended/quick-actions';
import KTLayoutQuickCartPanel from '../../../../../assets/js/layout/extended/quick-cart';
import KTLayoutQuickPanel from '../../../../../assets/js/layout/extended/quick-panel';
import KTLayoutQuickUser from '../../../../../assets/js/layout/extended/quick-user';
import KTLayoutHeaderTopbar from '../../../../../assets/js/layout/base/header-topbar';
import {KTUtil} from '../../../../../assets/js/components/util';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from "../../_services/notification.service";
import {ContextDataService} from "../../../../global-services/context-data.service";
import {EnumAuthPageRoute, EnumMainPageRoute} from "../../../../enums/router.enum";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, AfterViewInit {
  user: any;
  now: Date = new Date();
  userTimezone: string = ContextDataService.timezone.split(";")[0];
  // tobbar extras
  extraSearchDisplay: boolean;
  extrasSearchLayout: 'offcanvas' | 'dropdown';
  extrasNotificationsDisplay: boolean;
  extrasNotificationsLayout: 'offcanvas' | 'dropdown';
  extrasQuickActionsDisplay: boolean;
  extrasQuickActionsLayout: 'offcanvas' | 'dropdown';
  extrasCartDisplay: boolean;
  extrasCartLayout: 'offcanvas' | 'dropdown';
  extrasQuickPanelDisplay: boolean;
  extrasLanguagesDisplay: boolean;
  extrasUserDisplay: boolean;
  extrasUserLayout: 'offcanvas' | 'dropdown';

  alertNotice: any[] = [];
  eventNotice: any[] = [];
  logNotice: any[] = [];

  noticeSetting: any[] = [];
  notificationNum: number = 0;

  constructor(private layout: LayoutService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private notificationService: NotificationService
  ) {
    setInterval(() => {
      this.now = new Date();
    }, 1);
    this.activatedRoute.data.subscribe((data) => {
      if(data.rsContextData){

        this.user = data.rsContextData;
        this.getNotifcationCount();
      }
    })
  }

  ngOnInit(): void {
    // topbar extras
    this.extraSearchDisplay = this.layout.getProp('extras.search.display');
    this.extrasSearchLayout = this.layout.getProp('extras.search.layout');
    this.extrasNotificationsDisplay = this.layout.getProp(
      'extras.notifications.display'
    );
    this.extrasNotificationsLayout = this.layout.getProp(
      'extras.notifications.layout'
    );
    this.extrasQuickActionsDisplay = this.layout.getProp(
      'extras.quickActions.display'
    );
    this.extrasQuickActionsLayout = this.layout.getProp(
      'extras.quickActions.layout'
    );
    this.extrasCartDisplay = this.layout.getProp('extras.cart.display');
    this.extrasCartLayout = this.layout.getProp('extras.cart.layout');
    this.extrasLanguagesDisplay = this.layout.getProp(
      'extras.languages.display'
    );
    this.extrasUserDisplay = this.layout.getProp('extras.user.display');
    this.extrasUserLayout = this.layout.getProp('extras.user.layout');
    this.extrasQuickPanelDisplay = this.layout.getProp(
      'extras.quickPanel.display'
    );
  }

  getNotifcationCount() {
    this.notificationService.countNotification().subscribe((count: number) => {
      this.notificationNum = count;
    })
  }

  checkDropdown(isOpenEvent) {
    if (isOpenEvent) this.loadNotification();
  }

  loadNotification() {
    this.notificationService.getLatestNotification().subscribe((res: any) => {
      this.alertNotice = res.mnuDTOAlertList;
      this.alertNotice.forEach(alert => {alert.hover = false; alert.isChecked = false;});

      this.eventNotice = res.mnuDTOEventList;
      this.eventNotice.forEach(event => {
        event.hover = false;
        event.isChecked = false;
      });

      this.logNotice = res.mnuDTOLogList;
      this.logNotice.forEach(log => {
        log.hover = false;
        log.isChecked = false;
      });

      this.noticeSetting = res.notificationSettingDTOList;
      this.notificationNum = res.noticeNum;
    })
  }

  updateNotificationNum(notificationNum) {
    this.notificationNum = notificationNum;
  }

  ngAfterViewInit(): void {
    KTUtil.ready(() => {
      // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
      // Add 'implements AfterViewInit' to the class.
      if (this.extraSearchDisplay && this.extrasSearchLayout === 'offcanvas') {
        KTLayoutQuickSearch.init('kt_quick_search');
      }

      if (
        this.extrasNotificationsDisplay &&
        this.extrasNotificationsLayout === 'offcanvas'
      ) {
        // Init Quick Notifications Offcanvas Panel
        KTLayoutQuickNotifications.init('kt_quick_notifications');
      }

      if (
        this.extrasQuickActionsDisplay &&
        this.extrasQuickActionsLayout === 'offcanvas'
      ) {
        // Init Quick Actions Offcanvas Panel
        KTLayoutQuickActions.init('kt_quick_actions');
      }

      if (this.extrasCartDisplay && this.extrasCartLayout === 'offcanvas') {
        // Init Quick Cart Panel
        KTLayoutQuickCartPanel.init('kt_quick_cart');
      }

      if (this.extrasQuickPanelDisplay) {
        // Init Quick Offcanvas Panel
        KTLayoutQuickPanel.init('kt_quick_panel');
      }

      if (this.extrasUserDisplay && this.extrasUserLayout === 'offcanvas') {
        // Init Quick User Panel
        KTLayoutQuickUser.init('kt_quick_user');
      }

      // Init Header Topbar For Mobile Mode
      KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');
    });
  }

  openDoc() {
    let currentURL = window.location.href;
    let host = currentURL.split("/");
    let url = `${host[0]}//${host[2]}/documentation/authentication`;
    window.open(url, "_blank", "toolbar,scrollbars,resizable");
  }

  logout() {
    if(!this.cdr['destroyed']){
      this.cdr.detectChanges();
    }
    localStorage.removeItem('token');
    this.router.navigate([`/${EnumMainPageRoute.Auth}/${EnumAuthPageRoute.Login}`]).then();
  }
}
