"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var ContextDataResolver = /** @class */ (function () {
    function ContextDataResolver(http) {
        this.http = http;
    }
    ContextDataResolver.prototype.resolve = function (route) {
        return this.http.get('/data-canva-api/protected/context/get');
    };
    return ContextDataResolver;
}());
exports.ContextDataResolver = ContextDataResolver;
