<!-- begin::Header Nav -->
<ul class="menu-nav" [ngClass]="ulCSSClasses">
  <!-- begin::1 Level -->
  <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
    <a class="menu-link" routerLink="/dashboard" routerLinkActive="active">
      <span class="menu-text">DashBoard</span>
      <ng-container *ngIf="rootArrowEnabled">
        <i class="menu-arrow"></i>
      </ng-container>
    </a>
  </li>
  <!-- end::1 Level -->

  <!-- begin::1 Level -->
  <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
    <a class="menu-link" routerLink="/builder" routerLinkActive="active">
      <span class="menu-text">Builder</span>
      <ng-container *ngIf="rootArrowEnabled">
        <i class="menu-arrow"></i>
      </ng-container>
    </a>
  </li>
  <!-- end::1 Level -->

  <!-- Classic submenu -->
  <!-- begin::1 Level -->
  <li
    [attr.data-menu-toggle]="headerMenuDesktopToggle"
    aria-haspopup="true"
    class="menu-item menu-item-submenu menu-item-rel"
    routerLinkActive="menu-item-active"
  >
    <a class="menu-link menu-toggle" href="/material" routerLinkActive="active">
      <span class="menu-text">Material UI</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu menu-submenu-classic menu-submenu-left">
      <ul class="menu-subnav">
        <!-- Form Controls -->
        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          data-menu-toggle="hover"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
        >
          <a
            title="Edit"
            class="menu-link menu-toggle"
            href="/material/form-controls/autocomplete"
            routerLinkActive="active"
          >
            <span
              [inlineSVG]="'./assets/media/svg/icons/Design/PenAndRuller.svg'"
              cacheSVG="true"
              class="svg-icon menu-icon"
            ></span>
            <span class="menu-text">Form Controls</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/autocomplete"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Autocomplete</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/checkbox"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Checkbox</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/radiobutton"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Radio Button</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/datepicker"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Datepicker</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/formfield"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Form Field</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/input"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Input</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/select"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Select</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/slider"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Slider</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/slidertoggle"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Slider Toggle</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->

        <!-- Navigation  -->
        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          data-menu-toggle="hover"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
        >
          <a
            class="menu-link menu-toggle"
            routerLinkActive="active"
            href="/material/navigation"
          >
            <span
              [inlineSVG]="'./assets/media/svg/icons/Navigation/Up-down.svg'"
              cacheSVG="true"
              class="svg-icon menu-icon"
            ></span>
            <span class="menu-text">Navigation</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/navigation/menu"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Menu</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/navigation/sidenav"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Sidenav</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/navigation/toolbar"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Toolbar</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->

        <!-- Layout -->
        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          data-menu-toggle="hover"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
        >
          <a
            class="menu-link menu-toggle"
            routerLinkActive="active"
            href="/material/layout"
          >
            <span
              [inlineSVG]="
                './assets/media/svg/icons/Layout/Layout-right-panel-2.svg'
              "
              cacheSVG="true"
              class="svg-icon menu-icon"
            ></span>
            <span class="menu-text">Layout</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/layout/card"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Card</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/layout/divider"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Divider</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/form-controls/radiobutton"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Radio Button</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/layout/expansion-panel"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Expansion panel</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/layout/grid-list"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Grid list</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/layout/list"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">List</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/layout/tabs"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Tabs</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/layout/stepper"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Stepper</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/layout/tree"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Tree</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->

        <!-- Buttons and Indicators -->
        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          data-menu-toggle="hover"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
        >
          <a
            class="menu-link menu-toggle"
            routerLinkActive="active"
            href="/material/buttons-and-indicators"
          >
            <span
              [inlineSVG]="'./assets/media/svg/icons/Home/Cupboard.svg'"
              cacheSVG="true"
              class="svg-icon menu-icon"
            ></span>
            <span class="menu-text">Buttons and Indicators</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/buttons-and-indicators/button-toggle"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Button</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/buttons-and-indicators/chips"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Chips</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/buttons-and-indicators/icon"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Icon</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/buttons-and-indicators/progress-bar"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Progress bar</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/buttons-and-indicators/progress-spinner"
                  routerLinkActive="true"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Progress spinner</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/buttons-and-indicators/ripples"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Ripples</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->

        <!-- Popups and Modals -->
        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          data-menu-toggle="hover"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
        >
          <a
            class="menu-link menu-toggle"
            routerLinkActive="active"
            href="/material/popups-and-modals"
          >
            <span
              [inlineSVG]="'./assets/media/svg/icons/General/Duplicate.svg'"
              cacheSVG="true"
              class="svg-icon menu-icon"
            ></span>
            <span class="menu-text">Popups and Modals</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/popups-and-modals/bottom-sheet"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Bottom sheet</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/popups-and-modals/dialog"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Dialog</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/popups-and-modals/snackbar"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Snackbar</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/popups-and-modals/tooltip"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Tooltip</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->

        <!-- Data table -->
        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          data-menu-toggle="hover"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
        >
          <a
            class="menu-link menu-toggle"
            routerLinkActive="active"
            href="/material/data-table"
          >
            <span
              [inlineSVG]="
                './assets/media/svg/icons/Layout/Layout-top-panel-4.svg'
              "
              cacheSVG="true"
              class="svg-icon menu-icon"
            ></span>
            <span class="menu-text">Data table</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/data-table/paginator"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Paginator</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/data-table/sort-header"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Sort header</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/material/data-table/table"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Table</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->
      </ul>
    </div>
  </li>
  <!-- end::1 Level -->

  <!-- Mega submenu -->
  <!-- begin::1 Level -->
  <li
    class="menu-item menu-item-submenu menu-item-rel"
    routerLinkActive="menu-item-active"
    [attr.data-menu-toggle]="headerMenuDesktopToggle"
    aria-haspopup="true"
  >
    <a
      class="menu-link menu-toggle"
      routerLinkActive="active"
      href="/ngbootstrap"
    >
      <span class="menu-text">NgBootstrap</span>
      <i class="menu-arrow"></i>
    </a>
    <div
      class="menu-submenu menu-submenu-fixed menu-submenu-left"
      style="width: '1000px'"
    >
      <div class="menu-subnav">
        <ul class="menu-content">
          <li class="menu-item">
            <h3 class="menu-heading menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">A ... C</span>
              <i class="menu-arrow"></i>
            </h3>
            <ul class="menu-inner">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ngbootstrap/accordion"
                  routerLinkActive="active"
                >
                  <span class="menu-text">Accordion</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLinkActive="active"
                  routerLink="/ngbootstrap/alert"
                >
                  <span class="menu-text">Alert</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLinkActive="active"
                  routerLink="/ngbootstrap/buttons"
                >
                  <span class="menu-text">Buttons</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ngbootstrap/carousel"
                  routerLinkActive="active"
                >
                  <span class="menu-text">Carousel</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </li>

          <li class="menu-item">
            <h3 class="menu-heading menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">C ... M</span>
              <i class="menu-arrow"></i>
            </h3>
            <ul class="menu-inner">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ngbootstrap/collapse"
                  routerLinkActive="active"
                >
                  <span class="menu-text">Collapse</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ngbootstrap/datepicker"
                  routerLinkActive="active"
                >
                  <span class="menu-text">Datepicker</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ngbootstrap/dropdown"
                  routerLinkActive="active"
                >
                  <span class="menu-text">Dropdown</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ngbootstrap/modal"
                  routerLinkActive="active"
                >
                  <span class="menu-text">Modal</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </li>

          <li class="menu-item">
            <h3 class="menu-heading menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">P ... R</span>
              <i class="menu-arrow"></i>
            </h3>
            <ul class="menu-inner">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ngbootstrap/pagination"
                  routerLinkActive="active"
                >
                  <span class="menu-text">Pagination</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ngbootstrap/popover"
                  routerLinkActive="active"
                >
                  <span class="menu-text">Popover</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="active"
                  routerLinkActive="/ngbootstrap/progressbar"
                >
                  <span class="menu-text">Progressbar</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLinkActive="active"
                  routerLink="/ngbootstrap/rating"
                >
                  <span class="menu-text">Rating</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </li>

          <li class="menu-item">
            <h3 class="menu-heading menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">T ... Z</span>
              <i class="menu-arrow"></i>
            </h3>
            <ul class="menu-inner">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLinkActive="active"
                  routerLink="/ngbootstrap/timepicker"
                >
                  <span class="menu-text">Timepicker</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLinkActive="active"
                  routerLink="/ngbootstrap/tooltip"
                >
                  <span class="menu-text">Tooltips</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLinkActive="active"
                  routerLink="/ngbootstrap/typehead"
                >
                  <span class="menu-text">Typehead</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </li>
  <!-- end::1 Level -->

  <!-- Classic submenu -->
  <!-- begin::1 Level -->
  <li
    class="menu-item menu-item-submenu menu-item-rel"
    routerLinkActive="menu-item-active"
    [attr.data-menu-toggle]="headerMenuDesktopToggle"
    aria-haspopup="true"
  >
    <a class="menu-link menu-toggle" routerLinkActive="active">
      <span class="menu-text">Custom</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu menu-submenu-classic menu-submenu-left">
      <ul class="menu-subnav">
        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          routerLinkActive="menu-item-active"
          data-menu-toggle="hover"
          aria-haspopup="true"
        >
          <a
            class="menu-link menu-toggle"
            routerLinkActive="active"
            href="/ecommerce"
          >
            <span class="menu-text"> eCommerce </span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ecommerce/customers"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Customers</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/ecommerce/products"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Products</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->

        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          routerLinkActive="menu-item-active"
          data-menu-toggle="hover"
          aria-haspopup="true"
        >
          <a
            class="menu-link menu-toggle"
            routerLinkActive="active"
            href="/user-management"
          >
            <span class="menu-text"> User Management </span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/user-management/users"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Users</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/user-management/roles"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Roles</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->

        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          routerLinkActive="menu-item-active"
          data-menu-toggle="hover"
          aria-haspopup="true"
        >
          <a
            routerLinkActive="active"
            class="menu-link menu-toggle"
            href="/error"
          >
            <span class="menu-text"> Error Pages </span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/error/error-1"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Error 1</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/error/error-2"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Error 2</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/error/error-3"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Error 3</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/error/error-4"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Error 4</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/error/error-5"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Error 5</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/error/error-6"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Error 6</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->

        <!-- Wizards -->
        <!-- begin::2 Level -->
        <li
          class="menu-item menu-item-submenu"
          routerLinkActive="menu-item-active"
          data-menu-toggle="hover"
          aria-haspopup="true"
        >
          <a
            routerLinkActive="active"
            class="menu-link menu-toggle"
            href="/wizards"
          >
            <span class="menu-text"> Wizards </span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/wizards/wizard-1"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Wizard 1</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/wizards/wizard-2"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Wizard 2</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/wizards/wizard-3"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Wizard 3</span>
                </a>
              </li>
              <!-- end::3 Level -->

              <!-- begin::3 Level -->
              <li class="menu-item" routerLinkActive="menu-item-active">
                <a
                  class="menu-link"
                  routerLink="/wizards/wizard-4"
                  routerLinkActive="active"
                >
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">Wizard 4</span>
                </a>
              </li>
              <!-- end::3 Level -->
            </ul>
          </div>
        </li>
        <!-- end::2 Level -->
      </ul>
    </div>
  </li>
  <!-- end::1 Level -->
</ul>
<!-- end::Header Nav -->
