import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InlineSVGModule} from 'ng-inline-svg';
import {ThemeRoutingModule} from '../theme-routing.module';
import {NgbDropdownModule, NgbProgressbarModule,} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from "../../shared/shared.module"
// import { TranslationModule } from '../../modules/i18n/translation.module';
import {ScriptsInitComponent} from './init/scipts-init/scripts-init.component';
import {HeaderMobileComponent} from './components/header-mobile/header-mobile.component';
import {AsideComponent} from './components/aside/aside.component';
// import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {HeaderMenuComponent} from './components/header/header-menu/header-menu.component';
import {TopbarComponent} from './components/topbar/topbar.component';
import {CoreModule} from '../../_metronic/core';
// import {SubheaderModule} from './components/subheader/subheader.module';
import {AsideDynamicComponent} from './components/aside-dynamic/aside-dynamic.component';
import {HeaderMenuDynamicComponent} from './components/header/header-menu-dynamic/header-menu-dynamic.component';
import {
  NotificationsDropdownComponent
} from "./components/topbar/notifications-dropdown/notifications-dropdown.component";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";

@NgModule({
  declarations: [
    ScriptsInitComponent,
    HeaderMobileComponent,
    AsideComponent,
    // FooterComponent,
    HeaderComponent,
    HeaderMenuComponent,
    TopbarComponent,
    // LanguageSelectorComponent,
    AsideDynamicComponent,
    HeaderMenuDynamicComponent,
    NotificationsDropdownComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    ThemeRoutingModule,
    // TranslationModule,
    InlineSVGModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    CoreModule,
    PerfectScrollbarModule,
    // SubheaderModule,
  ],
  exports: [
    HeaderMobileComponent,
    AsideComponent,
    AsideDynamicComponent,
    HeaderComponent,
    // FooterComponent,
    ScriptsInitComponent
  ]
})
export class LayoutModule { }
