"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@ng-bootstrap/ng-bootstrap");
var i2 = require("@angular/common");
var i3 = require("../../directives/href-prevent-default/href-prevent-default.directive");
var i4 = require("./popover-info.component");
var styles_PopoverInfoComponent = [];
var RenderType_PopoverInfoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopoverInfoComponent, data: {} });
exports.RenderType_PopoverInfoComponent = RenderType_PopoverInfoComponent;
function View_PopoverInfoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popoverTitle; _ck(_v, 0, 0, currVal_0); }); }
function View_PopoverInfoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popoverContent; _ck(_v, 0, 0, currVal_0); }); }
function View_PopoverInfoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, [["popTitle", 2]], null, 0, null, View_PopoverInfoComponent_1)), (_l()(), i0.ɵand(0, [["popContent", 2]], null, 0, null, View_PopoverInfoComponent_2)), (_l()(), i0.ɵeld(2, 16777216, null, null, 3, "a", [["href", "#"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).preventDefault($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 737280, null, 0, i1.NgbPopover, [i0.ElementRef, i0.Renderer2, i0.Injector, i0.ComponentFactoryResolver, i0.ViewContainerRef, i1.NgbPopoverConfig, i0.NgZone, i2.DOCUMENT, i0.ChangeDetectorRef, i0.ApplicationRef], { ngbPopover: [0, "ngbPopover"], popoverTitle: [1, "popoverTitle"], placement: [2, "placement"] }, null), i0.ɵdid(4, 16384, null, 0, i3.HrefPreventDefaultDirective, [], { href: [0, "href"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "fas fa-exclamation-circle ml-2"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1); var currVal_1 = i0.ɵnov(_v, 0); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.placement, ""); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "#"; _ck(_v, 4, 0, currVal_3); }, null); }
exports.View_PopoverInfoComponent_0 = View_PopoverInfoComponent_0;
function View_PopoverInfoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popover-info", [], null, null, null, View_PopoverInfoComponent_0, RenderType_PopoverInfoComponent)), i0.ɵdid(1, 573440, null, 0, i4.PopoverInfoComponent, [], null, null)], null, null); }
exports.View_PopoverInfoComponent_Host_0 = View_PopoverInfoComponent_Host_0;
var PopoverInfoComponentNgFactory = i0.ɵccf("app-popover-info", i4.PopoverInfoComponent, View_PopoverInfoComponent_Host_0, { popoverTitle: "popoverTitle", popoverContent: "popoverContent", placement: "placement" }, {}, []);
exports.PopoverInfoComponentNgFactory = PopoverInfoComponentNgFactory;
