import {Injectable,} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ContextDataResolver implements Resolve<any> {

    constructor(private http: HttpClient) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.http.get('/data-canva-api/protected/context/get');
    }
}
