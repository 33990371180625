import {Location} from '@angular/common';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LayoutService} from '../../../../_metronic/core';
import {Router} from "@angular/router";
import {ContextDataService} from "../../../../global-services/context-data.service";
import {EnumFunctionList} from "../../../../enums/function-list.enum";
import {RoleAccessHelper} from "../../../../shared/helpers/role-access.helper";
// @ts-ignore
import {version} from "../../../../../../package.json";
import {EnumUserRole} from "../../../../enums/user-role.enum";
import {EnumAuthPageRoute, EnumMainPageRoute} from "../../../../enums/router.enum";

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent implements OnInit {
  disableAsideSelfDisplay: boolean;
  headerLogo: string;
  brandSkin: string;
  ulCSSClasses: string;
  location: Location;
  asideMenuHTMLAttributes: any = {};
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  brandClasses: string;
  asideMenuScroll = 1;
  asideSelfMinimizeToggle = false;
  userId: string;

  userRole: string = ContextDataService.userRole;

  version: string = version;
  currentYear: number = new Date().getFullYear();

  // For role Access
  isDataWareHouse: boolean = null;
  isDataPipeline: boolean = null;
  isUserGroup: boolean = null;
  isAccessControl: boolean = null;
  isSmartQuery: boolean = null;
  isSmartSearch: boolean = null;
  isProfile: boolean = null;
  isAdminSetting: boolean = null;

  constructor(private layout: LayoutService,
              private loc: Location,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private roleAccessHelper: RoleAccessHelper) {
  }

  get enumUserRole(): typeof EnumUserRole {
    return EnumUserRole;
  }

  ngOnInit(): void {
    this.loadAllRoleAccess();
    this.userId = String(ContextDataService.userID)
    // load view settings
    this.disableAsideSelfDisplay =
      this.layout.getProp('aside.self.display') === false;
    this.brandSkin = this.layout.getProp('brand.self.theme');
    this.headerLogo = this.getLogo();
    this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
    this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown') ? '1' : '0';
    this.brandClasses = this.layout.getProp('brand');
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
    this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
    // this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
    // Routing
    this.location = this.loc;
  }

  loadAllRoleAccess() {
    try {
      this.isDataWareHouse = this.roleAccessHelper.isEnabledAccess(EnumFunctionList.DataWarehousePage);
      this.isDataPipeline = this.roleAccessHelper.isEnabledAccess(EnumFunctionList.DataPipelinePage);
      this.isSmartQuery = this.roleAccessHelper.isEnabledAccess(EnumFunctionList.SmartQueryPage);
      this.isSmartSearch = this.roleAccessHelper.isEnabledAccess(EnumFunctionList.SmartSearchPage);
      this.isUserGroup = this.roleAccessHelper.isEnabledAccess(EnumFunctionList.UserGroup);
      this.isAccessControl = this.roleAccessHelper.isEnabledAccess(EnumFunctionList.AccessControl);
      this.isProfile = this.roleAccessHelper.isEnabledAccess(EnumFunctionList.MyProfilePage);
      this.isAdminSetting = this.roleAccessHelper.isEnabledAccess(EnumFunctionList.AdminSettingPage);
    } catch (e) {
      this.roleAccessHelper.roleAccessNotSetError(ContextDataService.userRole);
    }
  }

  navigateToAdminSetting() {
    let isViewSmartSearchSetting = this.roleAccessHelper.isEnabledAccess(EnumFunctionList.ViewSmartSearchSetting);

    if (isViewSmartSearchSetting) {
      this.router.navigate([`/${EnumMainPageRoute.AdminSetting}`, "smart-search"]).then();
      return;
    } else {
      this.router.navigate([`/${EnumMainPageRoute.AdminSetting}`, "notification"]).then();
    }
  }

  private getLogo() {
    if (this.brandSkin === 'light') {
      return './assets/media/logos/Datacanva_Logo.webp';
    } else {
      return './assets/media/logos/Datacanva_Logo.webp';
    }
  }

  logout() {
    console.log('this.cdr',this.cdr)
    if(!this.cdr['destroyed']){
      this.cdr.detectChanges();
    }
    localStorage.removeItem('token');
    this.router.navigate([`/${EnumMainPageRoute.Auth}/${EnumAuthPageRoute.Login}`]).then();
  }
}
