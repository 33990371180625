"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var animations_1 = require("@angular/animations");
var i0 = require("@angular/core");
var i1 = require("@angular/animations");
var SplashScreenService = /** @class */ (function () {
    /**
     * Service constructor
     *
     * @param animationBuilder: AnimationBuilder
     */
    function SplashScreenService(animationBuilder) {
        this.animationBuilder = animationBuilder;
    }
    /**
     * Init
     *
     * @param element: ElementRef
     */
    SplashScreenService.prototype.init = function (element) {
        this.el = element;
    };
    /**
     * Hide
     */
    SplashScreenService.prototype.hide = function () {
        var _this = this;
        if (this.stopped || !this.el) {
            return;
        }
        var player = this.animationBuilder
            .build([animations_1.style({ opacity: '1' }), animations_1.animate(800, animations_1.style({ opacity: '0' }))])
            .create(this.el.nativeElement);
        player.onDone(function () {
            if (typeof _this.el.nativeElement.remove === 'function') {
                _this.el.nativeElement.remove();
            }
            else {
                _this.el.nativeElement.style.display = 'none !important';
            }
            _this.stopped = true;
        });
        setTimeout(function () { return player.play(); }, 100);
    };
    SplashScreenService.prototype.show = function () {
        var player = this.animationBuilder
            .build([animations_1.style({ opacity: '1' }), animations_1.animate(800, animations_1.style({ opacity: '0' }))])
            .create(this.el.nativeElement);
        setTimeout(function () { return player.play(); }, 100);
        this.stopped = false;
        this.el.nativeElement.style.display = 'flex !important';
        this.init(this.el);
    };
    SplashScreenService.ngInjectableDef = i0.defineInjectable({ factory: function SplashScreenService_Factory() { return new SplashScreenService(i0.inject(i1.AnimationBuilder)); }, token: SplashScreenService, providedIn: "root" });
    return SplashScreenService;
}());
exports.SplashScreenService = SplashScreenService;
