export enum EnumDataType {
  Int = 'INT',
  Long = 'LONG',
  Float = 'FLOAT',
  Double = 'DOUBLE',
  String = 'STRING',
  Boolean = 'BOOLEAN',
  DateDate = 'DATE.DATE',
  DateDatetime = 'DATE.DATETIME',
  DateTimestamp = 'DATE.TIMESTAMP',
  Categorical = 'CATEGORICAL'
}

export enum EnumBooleanValue {
  False = 'false',
  True = 'true'
}

export enum EnumBooleanOperator {
  Is = "$eq"
}

export enum EnumBooleanViewOperator {
  Is = "is (for boolean)"
}

export enum EnumNumOperator {
  Gte = "$gte",
  Gt = "$gt",
  Lte = "$lte",
  Lt = "$lt",
  Eq = "$eq",
  Ne = "$ne"
}

export enum EnumNumViewOperator {
  Gte = ">=",
  Gt = ">",
  Lte = "<=",
  Lt = "<",
  Eq = "==",
  Ne = "!="
}

export enum EnumCategoricalOperator {
  Eq = "$eq",
  Ne = "$ne",
}

export enum EnumCategoricalViewOperator {
  Eq = "equal",
  Ne = "not equal",
}

export enum EnumStrOperator {
  Eq = "$eq",
  Ne = "$ne",
  StartWith = "$startWith",
  EndWith = "$endWith",
  Contains = "$contains",
  NotContains = "$notContains",
  PatternMatch = "$patternMatch",
  In = "$in",
  NotIn = "$nin",
}

export enum EnumStrViewOperator {
  Eq = "equal",
  Ne = "not equal",
  StartWith = "start with",
  EndWith = "end with",
  Contains = "contains",
  NotContains = "not contains",
  PatternMatch = "pattern match",
  In = "in",
  NotIn = "not in"
}

export enum EnumDateOperator {
  LastDate = "LAST_DATE"
}

export enum EnumDateViewOperator {
  LastDate = "last date"
}

export enum EnumAggregateOperator {
  Min = "$min",
  Max = "$max",
  Avg = "$avg",
  Sum = "$sum",
  Count = "$count",
  CountDistinct = "$countDistinct",
  MinIf = "$minIf",
  MaxIf = "$maxIf",
  AvgIf = "$avgIf",
  SumIf = "$sumIf",
  CountIf = "$countIf",
  CountDistinctIf = "$countDistinctIf"
}

export enum EnumNullOperator {
  Is = "$isNull", // customized
  IsNot = "$notNull", // customized
}

export enum EnumNullViewOperator {
  Is = "is",
  IsNot = "is not"
}

export enum EnumDateMode {
  DatePicker = "DATEPICKER",
  YearOf = "YEAR OF",
  MonthOf = "MONTH OF",
  DayOf = "DAY OF",
  HourOf = "HOUR OF",
  MinuteOf = "MINUTE OF",
  SecondOf = "SECOND OF",
}

export enum EnumDateUnit {
  Year = "YEAR",
  Month = "MONTH",
  Day = "DAY",
  Hour = "HOUR",
  Minute = "MINUTE",
  Second = "SECOND",
}

export enum EnumNull {
  Null = "null"
}

export enum EnumCustomFieldExpressType {
  Arithmetic = "ARITHMETIC",
  Boolean = "BOOLEAN",
  Comparison = "COMPARISON",
  Constant = "CONSTANT",
  Condition = "CONDITION",
  Date = "DATE",
  String = "STRING",
  Type = "TYPE",
}

export enum EnumCustomFieldNumericalOperator {
  Absolute = "ABS",
  Add = "ADD",
  Acos = "ACOS",
  Asin = "ASIN",
  Atan = "ATAN",
  Ceiling = "CEILING",
  Cosine = "COSINE",
  Divide = "DIVIDE",
  Exponential = "EXPONENTIAL",
  Floor = "FLOOR",
  Natural_log = "NATURAL_LOG",
  Log = "LOG",
  Log10 = "LOG10",
  Mode = "MODE",
  Multiply = "MULTIPLY",
  Power = "POWER",
  Round = "ROUND",
  Sine = "SINE",
  Square = "SQUARE",
  Subtract = "SUBTRACT",
  Tangent = "TANGENT",
  Truncate = "TRUNCATE",
}

export enum EnumCustomFieldStrOperator {
  Concat = "CONCAT",
  IndexOfCP = "INDEX_OF_CP",
  StrLenCP = "STR_LEN_CP",
  ToLower = "TO_LOWER",
  ToUpper = "TO_UPPER",
  R_PAD = "R_PAD",
  L_PAD = "L_PAD",
  LTrim = "L_TRIM",
  RTrim = "R_TRIM",
  Trim = "TRIM",
  ReplaceOne = "REPLACE_ONE",
  Substrcp = "SUB_STR_CP",
  Substr = "SUB_STR",
  DateFromString = "DATE_FROM_STR",
  ReplaceAll = "REPLACE_ALL",

  //DataPipeline Only (Smart Query not provide)
  // DateToString = 'DATE_TO_STRING',
}

export enum EnumCustomFieldDateOperator {
  DayOfYear = "DAY_OF_YEAR",
  DayOfMonth = "DAY_OF_MONTH",
  DayOfWeek = "DAY_OF_WEEK",
  Year = "YEAR",
  Month = "MONTH",
  Week = "WEEK",
  Hour = "HOUR",
  Minute = "MINUTE",
  Second = "SECOND",
  Millisecond = "MILLISECOND",
  DateToString = "DATE_TO_STRING",
  Add = "ADD_MILLISECONDS",
  DateAdd = "DATE_ADD",
  Now = "CURRENT_DATE_TIME",
  DateDiff = "DATE_DIFF",
}

export enum EnumCustomFieldComparisonOperator {
  // Compare = "COMPARE",
  Equal = "EQUAL",
  Greater = "GREATER",
  GreaterOrEqual = "GREATER_OR_EQUAL",
  Less = "LESS",
  LessOrEqual = "LESS_OR_EQUAL",
  NotEqual = "NOT_EQUAL",
  IsNull = "IS_NULL",
  NotNull = "NOT_NULL",
}

export enum EnumCustomFieldBooleanOperator {
  And = "AND",
  Or = "OR",
  Not = "NOT",
}

export enum EnumCustomFieldConditionOperator {
  IfNull = "IF_NULL",
  Iif = "IIF",
}

export enum EnumCustomFieldTypeOperator {
  Convert = "CONVERT",
}

export enum EnumCustomFieldConstantOperator {
  Is = "IS",
}
