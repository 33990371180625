export enum EnumRouteName {
  Dashboard = 'Dashboard',
  Test = 'Test',
  DataApi = 'DataApi',
  DataWarehouse = 'DataWarehouse',
  DataEntity = 'DataEntity',
  DataSource = 'DataSource',
  DataView = 'DataView',
  EntityHistory = 'EntityHistory',
  FileHistory = 'FileHistory',
  DataPipelineManagement = 'DataPipelineManagement',
  DataPipelineEditing = 'DataPipelineEditing'
}

export const getEnum = (name) => {
  switch (name) {
    case 'dashboard':
      return EnumRouteDashBoard
    case 'data-ware-house':
      return EnumRouteDataWareHouse
    case 'data-pipeline':
      return EnumRouteDataPipeline
    case 'admin':
      return EnumRouteAdmin
    case 'smart-query':
      return EnumRouteSmartQuery
    case 'account':
      return EnumRouteAccount
    default:
      return EnumRouteDefault
  }
}

export enum EnumRouteDashBoard {
  'main' = 'Dashboard',
}

export enum EnumRouteDataWareHouse {
  'main' = 'Data Warehouse',
  'data-entity' = 'Data Entity',
  'recycle-bin' = 'Recycle Bin',
  'data-view' = 'Data View',
  'data-schema' = 'Data Schema',
  'history' = 'History',
  'access-right' = 'Access Right',
  'entity-relation' = 'Entity Relation',
  'entity-relation-create' = 'Entity Relation Create',
  'entity-relation-edit' = 'Entity Relation Edit',
}

export enum EnumRouteDataPipeline {
  'main' = 'Data Pipeline',
  'access-right' = 'Access Right',
  'recycle-bin' = 'Recycle Bin',
  'edit' = 'Editing',
  'console' = 'Console',
}

export enum EnumRouteAdmin {
  'main' = 'Admin',
  'user-group' = 'Management',
  'role-access' = 'Role Access',
  'group-details-management' = 'Group Details Management',
  'user-details-management' = 'User Details Management',
}

export enum EnumRouteSmartQuery {
  'main' = 'Smart Query',
  'smart-query-editing' = 'Smart Query Editing',
  'editing-query' = 'Editing Query',
}

export enum EnumRouteAccount {
  'main' = 'Account',
  'my-profile' = 'My Profile',
}

export enum EnumRouteDefault {
  'main' = '',
}

export enum EnumRouteNameToTitle {
  'dashboard' = 'Dashboard',
  'data-ware-house' = 'Data Warehouse',
  'data-pipeline' = 'DataApi',
  'user-group' = 'Admin Management',
  'role-access' = 'Role Access',
  'smart-query' = 'Smart Query',
}
