"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PendingChangesGuard = /** @class */ (function () {
    function PendingChangesGuard() {
    }
    PendingChangesGuard.prototype.canDeactivate = function (component, route, state) {
        //can get canDeactivate / state
        // console.log('canDeactivate2',component['unSaved'])
        return component.canDeactivate() ? true : confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
    };
    return PendingChangesGuard;
}());
exports.PendingChangesGuard = PendingChangesGuard;
var PendingPipelineChangeGuard = /** @class */ (function () {
    function PendingPipelineChangeGuard() {
    }
    PendingPipelineChangeGuard.prototype.canDeactivate = function (component, route, state) {
        if (component.canDeactivate())
            return true;
        swal({
            title: "Warning",
            text: "Preview Pipeline is still processing, please press 'Stop' before you leave!",
            type: "warning",
            showConfirmButton: true,
            confirmButtonClass: 'btn-danger',
            confirmButtonText: "OK",
            closeOnConfirm: false,
        });
        return false;
    };
    return PendingPipelineChangeGuard;
}());
exports.PendingPipelineChangeGuard = PendingPipelineChangeGuard;
var PendingQueryChangeGuard = /** @class */ (function () {
    function PendingQueryChangeGuard() {
    }
    PendingQueryChangeGuard.prototype.canDeactivate = function (component, route, state) {
        if (component.canDeactivate())
            return true;
        swal({
            title: "Warning",
            text: "Query is still processing, please press 'Stop' before you leave!",
            type: "warning",
            showConfirmButton: true,
            confirmButtonClass: 'btn-danger',
            confirmButtonText: "OK",
            closeOnConfirm: false,
        });
        return false;
    };
    return PendingQueryChangeGuard;
}());
exports.PendingQueryChangeGuard = PendingQueryChangeGuard;
