"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
exports.routes = [
    { path: "auth", loadChildren: "./theme/pages/auth/auth.module#AuthModule" },
    { path: "documentation/:type", loadChildren: "./theme/pages/documentation/documentation.module#DocumentationModule" },
    { path: 'error', loadChildren: "./theme/pages/errors/errors.module#ErrorsModule" },
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full'
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;
