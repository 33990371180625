export enum EnumDateSymbol {
  colon = ":",
  dot = ".",
  minus = "-",
  slash = "/",
  space = " "
}

export enum EnumDateFormat {
  MM = "MM",
  MMM = "MMM",
  MMMM = "MMMM",
  dd = "dd",
  yy = "yy",
  yyyy = "yyyy",
  HH = "HH",
  mm = "mm",
  ss = "ss",
  SSS = "SSS",
  z = "z",
  timestamp13 = "Timestamp-13-digit",
  timestamp10 = "Timestamp-10-digit"
}
