"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./shared/components/splash-routing/splash-routing.component.ngfactory");
var i3 = require("./shared/components/splash-routing/splash-routing.component");
var i4 = require("./shared/components/splash-routing/splash-routing.service");
var i5 = require("./shared/components/splash-screen/splash-screen.component.ngfactory");
var i6 = require("./shared/components/splash-screen/splash-screen.component");
var i7 = require("./shared/components/splash-screen/splash-screen.service");
var i8 = require("@angular/router");
var i9 = require("./app.component");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-splash-routing", [], null, null, null, i2.View_SplashRoutingComponent_0, i2.RenderType_SplashRoutingComponent)), i1.ɵdid(1, 114688, null, 0, i3.SplashRoutingComponent, [i4.SplashRoutingService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-splash-screen", [], null, null, null, i5.View_SplashScreenComponent_0, i5.RenderType_SplashScreenComponent)), i1.ɵdid(3, 114688, null, 0, i6.SplashScreenComponent, [i1.ElementRef, i7.SplashScreenService], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "body", [["root", ""]], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i9.AppComponent, [i7.SplashScreenService, i4.SplashRoutingService, i8.Router], null, null)], null, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("body[root]", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
