"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumRouteName;
(function (EnumRouteName) {
    EnumRouteName["Dashboard"] = "Dashboard";
    EnumRouteName["Test"] = "Test";
    EnumRouteName["DataApi"] = "DataApi";
    EnumRouteName["DataWarehouse"] = "DataWarehouse";
    EnumRouteName["DataEntity"] = "DataEntity";
    EnumRouteName["DataSource"] = "DataSource";
    EnumRouteName["DataView"] = "DataView";
    EnumRouteName["EntityHistory"] = "EntityHistory";
    EnumRouteName["FileHistory"] = "FileHistory";
    EnumRouteName["DataPipelineManagement"] = "DataPipelineManagement";
    EnumRouteName["DataPipelineEditing"] = "DataPipelineEditing";
})(EnumRouteName = exports.EnumRouteName || (exports.EnumRouteName = {}));
exports.getEnum = function (name) {
    switch (name) {
        case 'dashboard':
            return EnumRouteDashBoard;
        case 'data-ware-house':
            return EnumRouteDataWareHouse;
        case 'data-pipeline':
            return EnumRouteDataPipeline;
        case 'admin':
            return EnumRouteAdmin;
        case 'smart-query':
            return EnumRouteSmartQuery;
        case 'account':
            return EnumRouteAccount;
        default:
            return EnumRouteDefault;
    }
};
var EnumRouteDashBoard;
(function (EnumRouteDashBoard) {
    EnumRouteDashBoard["main"] = "Dashboard";
})(EnumRouteDashBoard = exports.EnumRouteDashBoard || (exports.EnumRouteDashBoard = {}));
var EnumRouteDataWareHouse;
(function (EnumRouteDataWareHouse) {
    EnumRouteDataWareHouse["main"] = "Data Warehouse";
    EnumRouteDataWareHouse["data-entity"] = "Data Entity";
    EnumRouteDataWareHouse["recycle-bin"] = "Recycle Bin";
    EnumRouteDataWareHouse["data-view"] = "Data View";
    EnumRouteDataWareHouse["data-schema"] = "Data Schema";
    EnumRouteDataWareHouse["history"] = "History";
    EnumRouteDataWareHouse["access-right"] = "Access Right";
    EnumRouteDataWareHouse["entity-relation"] = "Entity Relation";
    EnumRouteDataWareHouse["entity-relation-create"] = "Entity Relation Create";
    EnumRouteDataWareHouse["entity-relation-edit"] = "Entity Relation Edit";
})(EnumRouteDataWareHouse = exports.EnumRouteDataWareHouse || (exports.EnumRouteDataWareHouse = {}));
var EnumRouteDataPipeline;
(function (EnumRouteDataPipeline) {
    EnumRouteDataPipeline["main"] = "Data Pipeline";
    EnumRouteDataPipeline["access-right"] = "Access Right";
    EnumRouteDataPipeline["recycle-bin"] = "Recycle Bin";
    EnumRouteDataPipeline["edit"] = "Editing";
    EnumRouteDataPipeline["console"] = "Console";
})(EnumRouteDataPipeline = exports.EnumRouteDataPipeline || (exports.EnumRouteDataPipeline = {}));
var EnumRouteAdmin;
(function (EnumRouteAdmin) {
    EnumRouteAdmin["main"] = "Admin";
    EnumRouteAdmin["user-group"] = "Management";
    EnumRouteAdmin["role-access"] = "Role Access";
    EnumRouteAdmin["group-details-management"] = "Group Details Management";
    EnumRouteAdmin["user-details-management"] = "User Details Management";
})(EnumRouteAdmin = exports.EnumRouteAdmin || (exports.EnumRouteAdmin = {}));
var EnumRouteSmartQuery;
(function (EnumRouteSmartQuery) {
    EnumRouteSmartQuery["main"] = "Smart Query";
    EnumRouteSmartQuery["smart-query-editing"] = "Smart Query Editing";
    EnumRouteSmartQuery["editing-query"] = "Editing Query";
})(EnumRouteSmartQuery = exports.EnumRouteSmartQuery || (exports.EnumRouteSmartQuery = {}));
var EnumRouteAccount;
(function (EnumRouteAccount) {
    EnumRouteAccount["main"] = "Account";
    EnumRouteAccount["my-profile"] = "My Profile";
})(EnumRouteAccount = exports.EnumRouteAccount || (exports.EnumRouteAccount = {}));
var EnumRouteDefault;
(function (EnumRouteDefault) {
    EnumRouteDefault["main"] = "";
})(EnumRouteDefault = exports.EnumRouteDefault || (exports.EnumRouteDefault = {}));
var EnumRouteNameToTitle;
(function (EnumRouteNameToTitle) {
    EnumRouteNameToTitle["dashboard"] = "Dashboard";
    EnumRouteNameToTitle["data-ware-house"] = "Data Warehouse";
    EnumRouteNameToTitle["data-pipeline"] = "DataApi";
    EnumRouteNameToTitle["user-group"] = "Admin Management";
    EnumRouteNameToTitle["role-access"] = "Role Access";
    EnumRouteNameToTitle["smart-query"] = "Smart Query";
})(EnumRouteNameToTitle = exports.EnumRouteNameToTitle || (exports.EnumRouteNameToTitle = {}));
