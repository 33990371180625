import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LayoutService} from '../../../../../_metronic/core';
import {EnumNotificationRealm, EnumNotificationType} from "../../../../../enums/notification.enum";
import {Router} from "@angular/router";
import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {EnumMainPageRoute} from "../../../../../enums/router.enum";

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss'],
})
export class NotificationsDropdownComponent implements OnInit {
  @Input() alertNotice
  @Input() eventNotice
  @Input() logNotice
  @Input() noticeSetting
  @Input() notificationNum
  @Output() notificationUpdateNum =  new EventEmitter();
  extrasNotificationsDropdownStyle: 'light' | 'dark' = 'dark';
  activeTabId:
    | 'topbar_notifications_alerts'
    | 'topbar_notifications_events'
    | 'topbar_notifications_logs' = 'topbar_notifications_alerts';


  hover: boolean = false;
  isLoading: boolean = false;

  config: PerfectScrollbarConfigInterface = {
    minScrollbarLength: 100,
    maxScrollbarLength: 500
  }

  ts = new Date().getTime();

  constructor(private layout: LayoutService,
              private router: Router) {}

  get enumNotificationRealm(): typeof EnumNotificationRealm {
    return EnumNotificationRealm;
  }

  get enumNotificationType(): typeof EnumNotificationType {
    return EnumNotificationType;
  }

  ngOnInit(): void {
    this.extrasNotificationsDropdownStyle = this.layout.getProp(
      'extras.notifications.dropdown.style'
    );
  }

  setActiveTabId(tabId) {
    this.activeTabId = tabId;
  }

  getActiveCSSClasses(tabId) {
    if (tabId !== this.activeTabId) {
      return '';
    }
    return 'active show';
  }

  navigateToNotificationDashboard(type) {
    this.router.navigate([`/${EnumMainPageRoute.Notification}`, type.toLowerCase()]).then();
  }

  isChecked(type, index) {
    switch (type) {
      case EnumNotificationType.Alert:
        this.alertNotice[index].isChecked = !this.alertNotice[index].isChecked;
        break;
      case EnumNotificationType.Event:
        this.eventNotice[index].isChecked = !this.eventNotice[index].isChecked;
        break;
      case EnumNotificationType.Log:
      default:
        this.logNotice[index].isChecked = !this.logNotice[index].isChecked;
        break;
    }
  }
}
