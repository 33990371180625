"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sweet_alert_service_1 = require("./sweet-alert.service");
var router_1 = require("@angular/router");
var bread_crumb_service_1 = require("./bread-crumb.service");
var DataTableAjaxService = /** @class */ (function () {
    function DataTableAjaxService(router, sweetAlertService) {
        this.router = router;
        this.sweetAlertService = sweetAlertService;
    }
    DataTableAjaxService.prototype.ajaxReload = function (id, url) {
        $('#' + id).DataTable().ajax.url(url).load();
    };
    DataTableAjaxService.prototype.statusCodeHandle = function () {
        var _this = this;
        return {
            401: function () {
                _this.sweetAlertService.sessionTimeout(function () {
                    _this.router.navigate([bread_crumb_service_1.BreadCrumbService.login().href]);
                });
            },
            500: function () {
                _this.sweetAlertService.serverError();
            }
        };
    };
    DataTableAjaxService.prototype.errorHandling = function (xhr) {
        // if (xhr.status === 500) {
        //     this.sweetAlertService.serverError();
        // } else if (xhr.status === 401) {
        //     this.sweetAlertService.sessionTimeout(() => {
        //         this.router.navigate([BreadCrumbService.login().href]);
        //     });
        // }
    };
    return DataTableAjaxService;
}());
exports.DataTableAjaxService = DataTableAjaxService;
