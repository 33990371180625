"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumUserRole;
(function (EnumUserRole) {
    EnumUserRole["SystemAdmin"] = "SYSTEM_ADMIN";
    EnumUserRole["OrganizationAdmin"] = "ORGANIZATION_ADMIN";
    EnumUserRole["Manager"] = "MANAGER";
    EnumUserRole["User"] = "USER";
})(EnumUserRole = exports.EnumUserRole || (exports.EnumUserRole = {}));
var EnumEntityRole;
(function (EnumEntityRole) {
    EnumEntityRole["EntityOwner"] = "ENTITY_OWNER";
    EnumEntityRole["EntityEditor"] = "ENTITY_EDITOR";
    EnumEntityRole["EntityViewer"] = "ENTITY_VIEWER";
})(EnumEntityRole = exports.EnumEntityRole || (exports.EnumEntityRole = {}));
var EnumPipelineRole;
(function (EnumPipelineRole) {
    EnumPipelineRole["PipelineOwner"] = "PIPELINE_OWNER";
    EnumPipelineRole["PipelineEditor"] = "PIPELINE_EDITOR";
    EnumPipelineRole["PipelineViewer"] = "PIPELINE_VIEWER";
})(EnumPipelineRole = exports.EnumPipelineRole || (exports.EnumPipelineRole = {}));
var EnumQueryRole;
(function (EnumQueryRole) {
    EnumQueryRole["QueryOwner"] = "QUERY_OWNER";
    EnumQueryRole["QueryEditor"] = "QUERY_EDITOR";
    EnumQueryRole["QueryViewer"] = "QUERY_VIEWER";
})(EnumQueryRole = exports.EnumQueryRole || (exports.EnumQueryRole = {}));
var EnumSearchRole;
(function (EnumSearchRole) {
    EnumSearchRole["SearchOwner"] = "SEARCH_OWNER";
    EnumSearchRole["SearchEditor"] = "SEARCH_EDITOR";
    EnumSearchRole["SearchViewer"] = "SEARCH_VIEWER";
})(EnumSearchRole = exports.EnumSearchRole || (exports.EnumSearchRole = {}));
