import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";

@Component({
  selector: 'app-popover-info',
  templateUrl: './popover-info.component.html',
})
export class PopoverInfoComponent implements OnChanges {

  @Input() popoverTitle;
  @Input() popoverContent;
  @Input() placement;

  ngOnChanges(changes: SimpleChanges) {
  }
}
