"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sweet_alert_service_1 = require("./sweet-alert.service");
var Observable_1 = require("rxjs/Observable");
var helpers_1 = require("../helpers");
var CanDeactivateService = /** @class */ (function () {
    function CanDeactivateService(sweetAlertService) {
        this.sweetAlertService = sweetAlertService;
    }
    CanDeactivateService.prototype.confirmToLeave = function (customStr) {
        var _this = this;
        console.log('confirmToLeave1');
        return new Observable_1.Observable(function (sender) {
            var confirmationString = 'Are you sure to leave without saving the changes of this page?';
            console.log('confirmToLeave2');
            if (customStr != null) {
                confirmationString = customStr;
            }
            _this.sweetAlertService.confirm(confirmationString, function () { return sender.next(true); }, function () {
                sender.next(false);
                helpers_1.Helpers.setLoading(false);
            });
        });
    };
    CanDeactivateService.prototype.deactivate = function () {
        console.log('deactivate');
        return new Observable_1.Observable(function (sender) { return sender.next(true); });
        // return new Observable<boolean>((sender: Observer<boolean>) => sender.next(true));
    };
    return CanDeactivateService;
}());
exports.CanDeactivateService = CanDeactivateService;
