"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var forms_1 = require("@angular/forms");
var cronstrue_1 = require("cronstrue");
var schedule_info_1 = require("../../infos/schedule.info");
var common_validator_1 = require("../../helpers/common-validator");
var form_control_helper_1 = require("../../helpers/form-control.helper");
var SetScheduleModalComponent = /** @class */ (function () {
    function SetScheduleModalComponent(modal, fb, cv, formControlHelper, scheduleInfo) {
        this.modal = modal;
        this.fb = fb;
        this.cv = cv;
        this.formControlHelper = formControlHelper;
        this.scheduleInfo = scheduleInfo;
        this.isLoading = false;
        this.cronTabTableName = "";
        this.cronTabFieldTable = [];
    }
    SetScheduleModalComponent.prototype.ngOnInit = function () {
        console.log(this.schedule);
        var scheduleSplit = ["*", "*", "*", "*", "*"];
        if (this.schedule) {
            scheduleSplit = this.schedule.split(" ");
        }
        else {
            this.schedule = "* * * * *";
        }
        this.formGroup = this.fb.group({
            minutes: [scheduleSplit[0], forms_1.Validators.compose([forms_1.Validators.required, this.cv.validateCronInput])],
            hours: [scheduleSplit[1], forms_1.Validators.compose([forms_1.Validators.required, this.cv.validateCronInput])],
            dayOfMonth: [scheduleSplit[2], forms_1.Validators.compose([forms_1.Validators.required, this.cv.validateCronInput])],
            month: [scheduleSplit[3], forms_1.Validators.compose([forms_1.Validators.required, this.cv.validateCronInput])],
            dayOfWeek: [scheduleSplit[4], forms_1.Validators.compose([forms_1.Validators.required, this.cv.validateCronInput])],
        });
        this.cronTranslate = cronstrue_1.default.toString(this.schedule);
    };
    SetScheduleModalComponent.prototype.expressionChange = function () {
        var tempSchedule = "";
        tempSchedule += this.formGroup.value.minutes;
        tempSchedule += " " + this.formGroup.value.hours;
        tempSchedule += " " + this.formGroup.value.dayOfMonth;
        tempSchedule += " " + this.formGroup.value.month;
        tempSchedule += " " + this.formGroup.value.dayOfWeek;
        this.schedule = tempSchedule;
        try {
            this.cronTranslate = cronstrue_1.default.toString(this.schedule);
        }
        catch (e) {
            this.cronTranslate = "";
        }
    };
    SetScheduleModalComponent.prototype.onClick = function (unit) {
        switch (unit) {
            case "MINUTES":
                this.cronTabTableName = "Crontab Minute Field";
                this.cronTabFieldTable = this.scheduleInfo.getCronTabMinuteField();
                break;
            case "HOURS":
                this.cronTabTableName = "Crontab Hour Field";
                this.cronTabFieldTable = this.scheduleInfo.getCronTabHourField();
                break;
            case "DAY_OF_MONTH":
                this.cronTabTableName = "Crontab Day Of Month Field";
                this.cronTabFieldTable = this.scheduleInfo.getCronTabDayOfMonthField();
                break;
            case "MONTH":
                this.cronTabTableName = "Crontab Month Field";
                this.cronTabFieldTable = this.scheduleInfo.getCronTabMonthField();
                break;
            case "DAY_OF_WEEK":
                this.cronTabTableName = "Crontab Day Of Week Field";
                this.cronTabFieldTable = this.scheduleInfo.getCronTabDayOfWeekField();
                break;
        }
    };
    SetScheduleModalComponent.prototype.findInvalidControls = function () {
        var invalid = [];
        var controls = this.formGroup.controls;
        for (var name_1 in controls) {
            if (controls[name_1].invalid) {
                invalid.push(name_1);
            }
        }
        return invalid.length > 0;
    };
    SetScheduleModalComponent.prototype.save = function (id) {
        var response = { id: id, schedule: this.schedule };
        this.modal.close(response);
    };
    return SetScheduleModalComponent;
}());
exports.SetScheduleModalComponent = SetScheduleModalComponent;
