"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var context_data_resolver_1 = require("./context-data.resolver");
var theme_component_1 = require("./theme.component");
// two way for load module
// 1. preLoad: loadChildren: () => import().then()
// 2. lazyLoad: loadChildren: ""
var routes = [
    {
        path: '',
        component: theme_component_1.ThemeComponent,
        resolve: {
            rsContextData: context_data_resolver_1.ContextDataResolver
        },
        children: [
            // {
            //   path: '',
            //   redirectTo: 'dashboard',
            //   pathMatch: 'full',
            // },
            // {path: "error", loadChildren: "./pages/error/error.module#ErrorsModule"},
            // {path: "documentation/:type", loadChildren: "./pages/documentation/documentation.module#DocumentationModule"},
            { path: "dashboard", loadChildren: "./pages/dashboard/dashboard.module#DashboardModule" },
            { path: "notification/:type", loadChildren: "./pages/notificationDashboard/notificationDashboard.module#NotificationDashboardModule" },
            { path: "data-ware-house", loadChildren: "./pages/dataWareHouse/dataWareHouseMain.module#DataWareHouseMainModule" },
            { path: "data-pipeline", loadChildren: "./pages/dataPipeline/dataPipelineMain.module#DataPipelineMainModule" },
            { path: "user-group", loadChildren: "./pages/userGroup/userGroupMain.module#UserGroupMainModule" },
            { path: "access-control", loadChildren: "./pages/accessControl/accessControl.module#AccessControlModule" },
            { path: "admin-setting/:type", loadChildren: "./pages/adminSetting/adminSettingMain.module#AdminSettingMainModule" },
            { path: "smart-query", loadChildren: "./pages/smartQuery/smartQueryMain.module#SmartQueryMainModule" },
            { path: "smart-search", loadChildren: "./pages/customerDashboard/customerDashboardMain.module#CustomerDashboardMainModule" },
            { path: "account", loadChildren: "./pages/account/accountMain.module#AccountMainModule" },
        ],
    },
    {
        path: '**',
        redirectTo: 'dashboard',
    },
];
var ThemeRoutingModule = /** @class */ (function () {
    function ThemeRoutingModule() {
    }
    return ThemeRoutingModule;
}());
exports.ThemeRoutingModule = ThemeRoutingModule;
