"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var splash_screen_service_1 = require("./splash-screen.service");
var SplashScreenComponent = /** @class */ (function () {
    function SplashScreenComponent(el, splashScreenService) {
        this.el = el;
        this.splashScreenService = splashScreenService;
    }
    SplashScreenComponent.prototype.ngOnInit = function () {
        this.splashScreenService.init(this.splashScreen);
    };
    return SplashScreenComponent;
}());
exports.SplashScreenComponent = SplashScreenComponent;
