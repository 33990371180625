"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var core_2 = require("../../../../_metronic/core");
var AsideDynamicComponent = /** @class */ (function () {
    function AsideDynamicComponent(layout, router, menu, cdr) {
        this.layout = layout;
        this.router = router;
        this.menu = menu;
        this.cdr = cdr;
        this.subscriptions = [];
        this.asideMenuHTMLAttributes = {};
        this.asideMenuScroll = 1;
        this.asideSelfMinimizeToggle = false;
    }
    AsideDynamicComponent.prototype.ngOnInit = function () {
        var _this = this;
        // load view settings
        this.disableAsideSelfDisplay =
            this.layout.getProp('aside.self.display') === false;
        this.brandSkin = this.layout.getProp('brand.self.theme');
        this.headerLogo = this.getLogo();
        this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
        this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
        this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
        this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown') ? '1' : '0';
        this.brandClasses = this.layout.getProp('brand');
        this.asideSelfMinimizeToggle = this.layout.getProp('aside.self.minimize.toggle');
        this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
        // this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
        // router subscription
        this.currentUrl = this.router.url.split(/[?#]/)[0];
        var routerSubscr = this.router.events.pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd; })).subscribe(function (event) {
            _this.currentUrl = event.url;
            _this.cdr.detectChanges();
        });
        this.subscriptions.push(routerSubscr);
        // menu load
        var menuSubscr = this.menu.menuConfig$.subscribe(function (res) {
            _this.menuConfig = res;
            _this.cdr.detectChanges();
        });
        this.subscriptions.push(menuSubscr);
    };
    AsideDynamicComponent.prototype.getLogo = function () {
        if (this.brandSkin === 'light') {
            return './assets/media/logos/Datacanva_Logo.webp';
        }
        else {
            return './assets/media/logos/Datacanva_Logo.webp';
        }
    };
    AsideDynamicComponent.prototype.isMenuItemActive = function (path) {
        if (!this.currentUrl || !path) {
            return false;
        }
        if (this.currentUrl === path) {
            return true;
        }
        if (this.currentUrl.indexOf(path) > -1) {
            return true;
        }
        return false;
    };
    AsideDynamicComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sb) { return sb.unsubscribe(); });
    };
    return AsideDynamicComponent;
}());
exports.AsideDynamicComponent = AsideDynamicComponent;
