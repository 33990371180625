"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var SweetAlertService = /** @class */ (function () {
    function SweetAlertService() {
    }
    SweetAlertService.prototype.success = function (msg) {
        swal({
            html: msg,
            title: 'Success',
            type: 'success',
            allowOutsideClick: true,
            showConfirmButton: true,
            confirmButtonClass: 'btn-danger',
            cancelButtonClass: 'btn-secondary',
            closeOnConfirm: true,
            confirmButtonText: 'OK'
        });
    };
    SweetAlertService.prototype.confirm = function (msg, cbFnConfirm, cbFnCancel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        alert = swal({
                            html: msg,
                            title: 'Confirmation',
                            type: 'warning',
                            allowOutsideClick: true,
                            showConfirmButton: true,
                            showCancelButton: true,
                            confirmButtonClass: 'btn-danger',
                            cancelButtonClass: 'btn-secondary',
                            confirmButtonText: 'Confirm',
                            cancelButtonText: 'Cancel',
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, alert];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.log('e', e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        if (cbFnConfirm) {
                            console.log('cbFnConfirm', cbFnConfirm);
                            cbFnConfirm();
                        }
                        if (cbFnCancel) {
                            console.log('cbFnCancel', cbFnCancel);
                            cbFnCancel();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SweetAlertService.prototype.confirmAction = function (title, text, cbFnConfirm, cbFnCancel) {
        swal({
            title: title,
            text: text,
            type: "warning",
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonClass: 'btn-danger',
            cancelButtonClass: 'btn-secondary',
            confirmButtonText: "Confirm",
            cancelButtonText: 'Cancel',
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                cbFnConfirm();
            }
        });
    };
    SweetAlertService.prototype.confirmActionWithInfo = function (title, text, cbFnConfirm, cbFnCancel) {
        swal({
            title: title,
            text: text,
            type: "warning",
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonClass: 'btn-danger',
            cancelButtonClass: 'btn-secondary',
            confirmButtonText: "Confirm",
            cancelButtonText: 'Cancel',
            closeOnConfirm: false,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                cbFnConfirm();
            }
        });
    };
    SweetAlertService.prototype.warningWithConfirm = function (title, text, confirmButton, cancelButton, cbFnConfirm) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                swal({
                    title: title,
                    text: text,
                    type: "warning",
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonClass: 'btn-danger',
                    cancelButtonClass: 'btn-secondary',
                    confirmButtonText: confirmButton,
                    cancelButtonText: cancelButton,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        cbFnConfirm();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    SweetAlertService.prototype.restrictedAccess = function (title, text, cbFnConfirm) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        alert = swal({
                            title: title,
                            text: text,
                            type: 'warning',
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            showCancelButton: false,
                            confirmButtonClass: 'btn-danger',
                            cancelButtonClass: 'btn-secondary',
                            closeOnConfirm: true,
                            confirmButtonText: 'OK'
                        }, function (isConfirm) {
                            if (isConfirm) {
                                cbFnConfirm();
                            }
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, alert];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SweetAlertService.prototype.info = function (title, text, cbFn) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        alert = swal({
                            title: title,
                            text: text,
                            type: 'info',
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            showCancelButton: false,
                            confirmButtonClass: 'btn-danger',
                            cancelButtonClass: 'btn-secondary',
                            closeOnConfirm: true,
                            confirmButtonText: 'OK'
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, alert];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3 /*break*/, 4];
                    case 4:
                        if (cbFn) {
                            cbFn();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SweetAlertService.prototype.successManual = function (title, text) {
        swal({
            title: title,
            text: text,
            type: "success"
        });
    };
    SweetAlertService.prototype.successConnection = function (title, text, confirmButton, cancelButton, cbFnConfirm) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                swal({
                    title: title,
                    text: text,
                    type: "success",
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonClass: 'btn-danger',
                    cancelButtonClass: 'btn-secondary',
                    confirmButtonText: confirmButton,
                    cancelButtonText: cancelButton,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        cbFnConfirm();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    SweetAlertService.prototype.successConnectionNoDownload = function (title, text) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                swal({
                    title: title,
                    text: text,
                    type: "success",
                    allowOutsideClick: false,
                    confirmButtonText: 'OK',
                });
                return [2 /*return*/];
            });
        });
    };
    SweetAlertService.prototype.errorManual = function (title, text) {
        swal({
            title: title,
            text: text,
            type: "error",
            allowOutsideClick: false,
            confirmButtonText: 'OK'
        });
    };
    SweetAlertService.prototype.error = function (title, msg) {
        swal({
            html: msg,
            title: title,
            type: 'error',
            allowOutsideClick: false,
            confirmButtonText: 'OK'
        });
    };
    SweetAlertService.prototype.warning = function (title, msg) {
        swal({
            // html: msg,
            title: title,
            text: msg,
            type: 'warning',
            allowOutsideClick: false,
            confirmButtonText: 'OK'
        });
    };
    SweetAlertService.prototype.autoCloseInfo = function (title, text) {
        swal({
            title: title,
            text: text,
            timer: 1000,
            confirmButtonClass: 'btn-danger',
            cancelButtonClass: 'btn-secondary',
        });
    };
    SweetAlertService.prototype.warningNotAllowAccess = function (title, text, cbFn) {
        swal({
            title: title,
            type: 'warning',
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: false,
            closeOnConfirm: true,
            confirmButtonText: 'OK'
        }).then(function () {
            if (cbFn) {
                cbFn();
            }
        });
    };
    SweetAlertService.prototype.deleteNotice = function (title, text, keyword, mode, cbFnConfirm) {
        swal({
            title: title,
            text: text,
            type: "input",
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonClass: 'btn-danger',
            cancelButtonClass: 'btn-secondary',
            animation: "slide-from-top",
            inputPlaceholder: "Please input '" + keyword + "'",
        }, function (inputValue) {
            if (inputValue === false)
                return false;
            if (inputValue === "" || inputValue !== keyword) {
                swal.showInputError("Please input '" + keyword + "' !");
                return false;
            }
            swal("Nice!", "This " + mode + " has been deleted!", "success");
            cbFnConfirm();
        });
    };
    // for video upload
    // block ui and show loading event
    SweetAlertService.prototype.videoUpload = function () {
        swal({
            // html: 'Hello 123',
            title: 'Video Uploading...',
            type: 'info',
            allowOutsideClick: false,
            showConfirmButton: false,
            confirmButtonClass: 'btn-danger',
            cancelButtonClass: 'btn-secondary',
        });
        // show loading event
        swal.showLoading();
    };
    // for copy page course loading
    SweetAlertService.prototype.copyPageCourseLoading = function () {
        swal({
            // html: 'Hello 123',
            title: 'Page Loading...',
            type: 'info',
            allowOutsideClick: false,
            showConfirmButton: false,
            confirmButtonClass: 'btn-danger',
            cancelButtonClass: 'btn-secondary',
        });
        // show loading event
        swal.showLoading();
    };
    SweetAlertService.prototype.custom = function (displayHtml, cbFn) {
        swal({
            html: displayHtml,
            title: '',
            type: 'warning',
            allowOutsideClick: false,
            showCloseButton: false,
            showConfirmButton: false,
            confirmButtonClass: 'btn-danger',
            cancelButtonClass: 'btn-secondary',
            // confirmButtonText: globalText.copy,
            customClass: 'custom-share',
            onOpen: function () {
                cbFn();
            }
        });
    };
    // api to close all sweet alert
    SweetAlertService.prototype.close = function () {
        swal.close();
    };
    SweetAlertService.prototype.sessionTimeout = function (cbFn) {
        console.log('sessionTimeout');
        console.log('cbFn', cbFn);
        this.info('Timeout!', 'You have no access right. Please contact us for the request.', function () { return cbFn(); });
    };
    SweetAlertService.prototype.serverError = function () {
        this.error('Error', 'Server error');
    };
    return SweetAlertService;
}());
exports.SweetAlertService = SweetAlertService;
