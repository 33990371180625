declare var swal: any;

export class SweetAlertService {

  constructor() {
  }


  success(msg: string) {

    swal({
      html: msg,
      title: 'Success',
      type: 'success',
      allowOutsideClick: true,
      showConfirmButton: true,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      closeOnConfirm: true,
      confirmButtonText: 'OK'
    });
  }


  async confirm(msg: string, cbFnConfirm?: () => void, cbFnCancel?: () => void) {

    const alert = swal({
      html: msg,
      title: 'Confirmation',
      type: 'warning',
      allowOutsideClick: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    })
    //   .then((result) => {
    //     if (result.value === true) {
    //         if (cbFnConfirm) {
    //             cbFnConfirm();
    //         }
    //     } else {
    //         if (cbFnCancel) {
    //             cbFnCancel();
    //         }
    //     }
    // });
    try {
      await alert;
    } catch (e) {
      console.log('e', e);
    }
    if (cbFnConfirm) {
      console.log('cbFnConfirm', cbFnConfirm)
      cbFnConfirm();
    }
    if (cbFnCancel) {
      console.log('cbFnCancel', cbFnCancel)
      cbFnCancel();
    }
  }

  confirmAction(title: string, text: string, cbFnConfirm?: () => void, cbFnCancel?: () => void) {
    swal({
      title: title,
      text: text,
      type: "warning",
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      confirmButtonText: "Confirm",
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        cbFnConfirm();
      }
    })
  }

  confirmActionWithInfo(title: string, text: string, cbFnConfirm?: () => void, cbFnCancel?: () => void) {
    swal({
      title: title,
      text: text,
      type: "warning",
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      confirmButtonText: "Confirm",
      cancelButtonText: 'Cancel',
      closeOnConfirm: false,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        cbFnConfirm();
      }
    })
  }

  async warningWithConfirm(title: string, text: string, confirmButton: string, cancelButton: string,
                          cbFnConfirm?: () => void) {
    swal({
      title: title,
      text: text,
      type: "warning",
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      confirmButtonText: confirmButton,
      cancelButtonText: cancelButton,
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        cbFnConfirm();
      }
    })
  }


  async restrictedAccess(title: string, text: string, cbFnConfirm?: () => void) {

    const alert = swal({
      title: title,
      text: text,
      type: 'warning',
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      closeOnConfirm: true,
      confirmButtonText: 'OK'
    }, function (isConfirm) {
      if (isConfirm) {
        cbFnConfirm();
      }
    })

    try {
      await alert;
    } catch (e) {
      console.log(e);
    }

  }


  async info(title: string, text: string, cbFn?: () => void) {

    const alert = swal({
      title: title,
      text: text,
      type: 'info',
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      closeOnConfirm: true,
      confirmButtonText: 'OK'
    })

    try {
      await alert;
    } catch (e) {
      console.log(e);
    }
    if (cbFn) {
      cbFn();
    }
  }

  successManual(title: string, text: string) {
    swal({
      title: title,
      text: text,
      type: "success"
    })
  }

  async successConnection(title: string, text: string, confirmButton: string, cancelButton: string,
                          cbFnConfirm?: () => void) {
    swal({
      title: title,
      text: text,
      type: "success",
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      confirmButtonText: confirmButton,
      cancelButtonText: cancelButton,
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        cbFnConfirm();
      }
    })
  }

  async successConnectionNoDownload(title: string, text: string) {
    swal({
      title: title,
      text: text,
      type: "success",
      allowOutsideClick: false,
      confirmButtonText: 'OK',
    })
  }

  errorManual(title: string, text: string) {
    swal({
      title: title,
      text: text,
      type: "error",
      allowOutsideClick: false,
      confirmButtonText: 'OK'
    })
  }


  error(title: string, msg: string) {
    swal({
      html: msg,
      title: title,
      type: 'error',
      allowOutsideClick: false,
      confirmButtonText: 'OK'
    });
  }


  warning(title: string, msg: string) {
    swal({
      // html: msg,
      title: title,
      text: msg,
      type: 'warning',
      allowOutsideClick: false,
      confirmButtonText: 'OK'
    });
  }

    autoCloseInfo(title: string, text: string) {
      swal({
        title: title,
        text: text,
        timer: 1000,
        confirmButtonClass: 'btn-danger',
        cancelButtonClass: 'btn-secondary',
      })
    }


  warningNotAllowAccess(title: string, text: string, cbFn?: () => void) {

    swal({
      title: title,
      type: 'warning',
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: false,
      closeOnConfirm: true,
      confirmButtonText: 'OK'
    }).then(() => {
      if (cbFn) {
        cbFn();
      }
    });
  }

  deleteNotice(title: string, text: string, keyword: string, mode: string,
               cbFnConfirm?: () => void) {
    swal({
      title: title,
      text: text,
      type: "input",
      showCancelButton: true,
      closeOnConfirm: false,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      animation: "slide-from-top",
      inputPlaceholder: "Please input '" + keyword + "'",
    }, function (inputValue) {
      if (inputValue === false) return false;

      if (inputValue === "" || inputValue !== keyword) {
        swal.showInputError("Please input '" + keyword + "' !");
        return false;
      }

      swal("Nice!", "This " + mode + " has been deleted!", "success");
      cbFnConfirm()
    });
  }

  // for video upload
  // block ui and show loading event
  videoUpload() {
    swal({
      // html: 'Hello 123',
      title: 'Video Uploading...',
      type: 'info',
      allowOutsideClick: false,
      showConfirmButton: false,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      // confirmButtonText: 'OK'
    });
    // show loading event
    swal.showLoading();
  }


  // for copy page course loading
  copyPageCourseLoading() {
    swal({
      // html: 'Hello 123',
      title: 'Page Loading...',
      type: 'info',
      allowOutsideClick: false,
      showConfirmButton: false,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      // confirmButtonText: 'OK'
    });
    // show loading event
    swal.showLoading();
  }


  custom(displayHtml: string, cbFn?: () => void) {

    swal({
      html: displayHtml,
      title: '',
      type: 'warning',
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: 'btn-secondary',
      // confirmButtonText: globalText.copy,
      customClass: 'custom-share',
      onOpen: () => {
        cbFn();
      }
    });
  }


  // api to close all sweet alert
  close() {
    swal.close();
  }


  sessionTimeout(cbFn?: () => void) {
    console.log('sessionTimeout')
    console.log('cbFn', cbFn)
    this.info('Timeout!', 'You have no access right. Please contact us for the request.', () => cbFn());
  }

  serverError() {
    this.error('Error', 'Server error');
  }
}

