export enum EnumNotificationType {
  // notification type
  Alert = "ALERT",
  Event = "EVENT",
  Log = "LOG"
}

export enum EnumNotificationRealm {
  // notification realm
  DataWarehouse = "DATA_WAREHOUSE",
  DataPipeline = "DATA_PIPELINE",
  SmartQuery = "SMART_QUERY",
  UserGroup = "USER_GROUP",
  AccessControl = "ACCESS_CONTROL"
}
