"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var FormControlHelper = /** @class */ (function () {
    function FormControlHelper() {
    }
    FormControlHelper.prototype.isControlValid = function (formGroup, controlName) {
        var control = formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    };
    FormControlHelper.prototype.isControlInvalid = function (formGroup, controlName, isSubmitted) {
        var control = formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched || isSubmitted);
    };
    FormControlHelper.prototype.controlHasError = function (formGroup, validation, controlName, isSubmitted) {
        var control = formGroup.controls[controlName];
        return control.hasError(validation) && (control.dirty || control.touched || isSubmitted);
    };
    FormControlHelper.prototype.isControlInvalidWithoutSubmit = function (formGroup, controlName) {
        var control = formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    };
    FormControlHelper.prototype.controlHasErrorWithoutSubmit = function (formGroup, validation, controlName) {
        var control = formGroup.controls[controlName];
        return control.hasError(validation) && (control.dirty || control.touched);
    };
    FormControlHelper.ngInjectableDef = i0.defineInjectable({ factory: function FormControlHelper_Factory() { return new FormControlHelper(); }, token: FormControlHelper, providedIn: "root" });
    return FormControlHelper;
}());
exports.FormControlHelper = FormControlHelper;
