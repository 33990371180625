import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'textCase'})
export class TextcaseConvertPipe implements PipeTransform {


  transform(value: string): any {
    return this.titleCase(value);
  }

  titleCase(str) {
    str = str.replaceAll(' ', '_').replaceAll('-', '_');
    let splitStr = str.toLowerCase().split('_');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }
}
