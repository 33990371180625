export enum EnumUserRole {
  SystemAdmin = 'SYSTEM_ADMIN',
  OrganizationAdmin = "ORGANIZATION_ADMIN",
  Manager = "MANAGER",
  User = "USER"
}

export enum EnumEntityRole {
  EntityOwner = "ENTITY_OWNER",
  EntityEditor = "ENTITY_EDITOR",
  EntityViewer = "ENTITY_VIEWER"
}

export enum EnumPipelineRole {
  PipelineOwner = "PIPELINE_OWNER",
  PipelineEditor = "PIPELINE_EDITOR",
  PipelineViewer = "PIPELINE_VIEWER"
}

export enum EnumQueryRole {
  QueryOwner = "QUERY_OWNER",
  QueryEditor = "QUERY_EDITOR",
  QueryViewer = "QUERY_VIEWER"
}

export enum EnumSearchRole {
  SearchOwner = "SEARCH_OWNER",
  SearchEditor = "SEARCH_EDITOR",
  SearchViewer = "SEARCH_VIEWER"
}
