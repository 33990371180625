import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

export const routes: Routes = [
  {path: "auth", loadChildren: "./theme/pages/auth/auth.module#AuthModule"},
  {path: "documentation/:type", loadChildren: "./theme/pages/documentation/documentation.module#DocumentationModule"},
  { path: 'error', loadChildren: "./theme/pages/errors/errors.module#ErrorsModule" },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
