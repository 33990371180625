"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var param_model_1 = require("../../../../shared/model-states/param.model");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var RoleAccessService = /** @class */ (function () {
    function RoleAccessService(http) {
        this.http = http;
        this.BASE_URL = '/data-canva-api/protected/roleAccess';
    }
    RoleAccessService.prototype.updateParams = function (paginator, sorting) {
        var params = new param_model_1.ParamState();
        params.draw = 1;
        params.start = (paginator.page - 1) * paginator.pageSize;
        params.length = paginator.pageSize;
        params.searchValue = "";
        params.orderColumn = sorting.column;
        params.orderDirection = sorting.direction;
        return params;
    };
    RoleAccessService.prototype.getRoleAccessStatic = function () {
        var url = this.BASE_URL + "/getRoleAccessStatic";
        return this.http.get(url);
    };
    RoleAccessService.prototype.getRoleAccess = function (targetRole) {
        var params = { "targetRole": targetRole };
        var url = this.BASE_URL + "/getRoleAccess";
        return this.http.get(url, { params: params });
    };
    RoleAccessService.prototype.getRoleAccessStatusList = function () {
        var url = this.BASE_URL + "/getRoleAccessStatusList";
        return this.http.get(url);
    };
    RoleAccessService.prototype.saveRoleAccess = function (element) {
        var url = this.BASE_URL + "/saveRoleAccess";
        return this.http.post(url, element);
    };
    RoleAccessService.ngInjectableDef = i0.defineInjectable({ factory: function RoleAccessService_Factory() { return new RoleAccessService(i0.inject(i1.HttpClient)); }, token: RoleAccessService, providedIn: "root" });
    return RoleAccessService;
}());
exports.RoleAccessService = RoleAccessService;
