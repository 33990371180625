import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {
  EnumCustomFieldBooleanOperator, EnumCustomFieldComparisonOperator,
  EnumCustomFieldConditionOperator,
  EnumCustomFieldConstantOperator,
  EnumCustomFieldDateOperator,
  EnumCustomFieldExpressType,
  EnumCustomFieldNumericalOperator,
  EnumCustomFieldStrOperator, EnumCustomFieldTypeOperator,
  EnumDataType
} from "../../../enums/data-type.enum";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  EnumArgumentType,
  EnumSmartQueryCustomFieldAction,
  EnumSmartQueryDropZone
} from "../../../enums/smart-query.enum";
import {FormControlHelper} from "../../helpers/form-control.helper";
import {CustomFieldState} from "../../model-states/custom-field.model";
import {CommonValidator} from "../../helpers/common-validator";
import {SweetAlertService} from "../../../global-services/sweet-alert.service";
import {CustomFieldMappingService} from "./custom-field-mapping.service";


@Component({
  selector: 'set-custom-field-modal',
  templateUrl: './set-custom-field-modal.component.html'
})
export class SetCustomFieldModalComponent implements OnInit {

  @Input() fieldList;
  @Input() mode;
  @Input() dropZone;
  @Input() savedCustomField;

  // very important !!!
  tempFieldList: any[] = [];
  filteredFieldList: any[] = [];

  nameList: string[] = [];
  customFieldName: string;
  expressionType: string = "";
  expressionList: string[] = Object.values(EnumCustomFieldExpressType);
  operator: string = "";
  operatorList: string[] = [];
  argumentList: any[] = [];
  argumentTypeList: any[] = [];
  argumentDataTypeList: any[] = [];
  argumentDelList: boolean[] = [];
  outputDataTypeList: string[] = [];
  placeholderList: string[] = [];
  selectedList: string[] = [];
  readyCustomFieldList: any[] = [];
  numericalTypeList = [EnumDataType.Int, EnumDataType.Double, EnumDataType.Long, EnumDataType.Float];

  isSubmitted: boolean = false;

  argObj: CustomFieldState = new CustomFieldState();
  formGroup: FormGroup;
  output: any = "";

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder,
              private sweetAlertService: SweetAlertService,
              public formControlHelper: FormControlHelper,
              private customFieldMappingService: CustomFieldMappingService,
              private cv: CommonValidator) {
  }

  get enumSmartQueryDropZone(): typeof EnumSmartQueryDropZone {
    return EnumSmartQueryDropZone;
  }

  get enumSmartQueryCustomFieldAction(): typeof EnumSmartQueryCustomFieldAction {
    return EnumSmartQueryCustomFieldAction;
  }

  get enumDataType(): typeof EnumDataType {
    return EnumDataType;
  }

  get enumArgumentType(): typeof EnumArgumentType {
    return EnumArgumentType;
  }

  ngOnInit() {
    this.selectedList = [];
    this.operatorList = [];
    console.log(this.fieldList, this.mode, this.savedCustomField);
    this.fieldList.forEach(field => this.nameList.push(field.fieldName));
    this.loadCustomFieldForm();
  }

  private initCustomFieldForm() {
    this.formGroup = this.fb.group({
      customFieldName: ["", Validators.compose([Validators.required,
        this.cv.validateDuplicateFieldName(this.nameList)])],
      expressionType: [""],
      operator: [""],
      outputDataType: [""]
    })

    this.tempFieldList = Object.assign([], this.fieldList);
  }

  private loadExistCustomFieldForm() {
    this.formGroup = this.fb.group({
      customFieldName: [this.savedCustomField.fieldName, Validators.compose([Validators.required,
        this.cv.validateDuplicateFieldName(this.nameList)])],
      expressionType: [""],
      operator: [""],
      outputDataType: [""]
    })

    this.dropZone = this.savedCustomField.dropZone;
    this.readyCustomFieldList.push(...this.savedCustomField.readyCustomFieldList);
    this.fieldList.forEach(field => {
      if (field.fieldName !== this.savedCustomField.fieldName) {
        this.tempFieldList.push(field)
      }
    });
    this.tempFieldList.push(...this.savedCustomField.readyCustomFieldList);
  }

  loadCustomFieldForm() {
    if (this.mode === EnumSmartQueryCustomFieldAction.ActionEdit) {
      this.loadExistCustomFieldForm();
    } else if (this.mode === EnumSmartQueryCustomFieldAction.ActionAdd){
      this.initCustomFieldForm();
    }
  }

  openPopupDocumentation() {
    let currentURL = window.location.href;
    let host = currentURL.split("/");
    let url = `${host[0]}//${host[2]}/documentation/custom-field`;
    window.open(url,"_blank", "toolbar,scrollbars,resizable");
  }

  onSelectExpressionType(expressionType: string) {
    this.formGroup.value.operator = "";
    this.operatorList = [""];
    this.argumentTypeList = [];
    this.argumentDelList = [];
    this.argumentDataTypeList = [];

    switch (expressionType) {
      case EnumCustomFieldExpressType.Arithmetic:
        this.operatorList.push(...Object.values(EnumCustomFieldNumericalOperator));
        let numericTypeList = [EnumDataType.Int, EnumDataType.Double, EnumDataType.Long, EnumDataType.Float, EnumCustomFieldExpressType.Arithmetic];
        this.filteredFieldList = this.tempFieldList.filter(field => numericTypeList.includes(field.dataType));
        break;
      case EnumCustomFieldExpressType.Boolean:
        this.operatorList.push(...Object.values(EnumCustomFieldBooleanOperator));
        this.filteredFieldList = this.tempFieldList.filter(field => field.dataType == expressionType);
        break;
      case EnumCustomFieldExpressType.Comparison:
        this.operatorList.push(...Object.values(EnumCustomFieldComparisonOperator));
        this.filteredFieldList = this.tempFieldList;
        break;
      case EnumCustomFieldExpressType.Condition:
        this.operatorList.push(...Object.values(EnumCustomFieldConditionOperator));
        this.filteredFieldList = this.tempFieldList;
        break;
      case EnumCustomFieldExpressType.Constant:
        this.operatorList.push(...Object.values(EnumCustomFieldConstantOperator));
        this.filteredFieldList = this.tempFieldList;
        break;
      case EnumCustomFieldExpressType.Date:
        this.operatorList.push(...Object.values(EnumCustomFieldDateOperator));
        let dateTypeList = [EnumDataType.DateDate, EnumDataType.DateDatetime, EnumDataType.DateTimestamp,
          EnumCustomFieldExpressType.Date];
        this.filteredFieldList = this.tempFieldList.filter(field => dateTypeList.includes(field.dataType));
        break;
      case EnumCustomFieldExpressType.String:
        this.operatorList.push(...Object.values(EnumCustomFieldStrOperator));
        let stringTypeList = [EnumDataType.String, EnumDataType.Categorical];
        this.filteredFieldList = this.tempFieldList.filter(field => stringTypeList.includes(field.dataType));
        break;
      case EnumCustomFieldExpressType.Type:
        this.operatorList.push(...Object.values(EnumCustomFieldTypeOperator));
        this.filteredFieldList = this.tempFieldList;
        break;

    }
    console.log(this.filteredFieldList);
  }

  onOperatorChange() {
    this.argumentTypeList = [];
    this.argumentDelList = [];
    this.argumentDataTypeList = [];
    this.outputDataTypeList = [""];
    this.placeholderList = [];
    this.argObj = this.customFieldMappingService.operatorMappingArgNum(this.formGroup.value.operator);
    console.log(this.argObj);
    if (!this.argObj.unlimitedArgNum) {
      for (let i = 0; i < this.argObj.argType.length; i++) {
        this.argumentList.push("");
        this.argumentTypeList.push(this.argObj.argType[i]);
        this.argumentDelList.push(true);
        this.argumentDataTypeList.push("");
      }
    }

    this.outputDataTypeList.push(...this.argObj.outputDataTypeChoice);
    this.placeholderList.push(...this.argObj.placeholder);
    console.log(this.placeholderList);
    this.output = `${this.formGroup.value.operator}()`;
  }

  selectChangeArgDataType(index) {
    let field = this.filteredFieldList.find((field) =>
      (this.dropZone === EnumSmartQueryDropZone.DropZoneCustom)? field.newName : field.fieldName === this.argumentList[index]);
    this.argumentDataTypeList[index] = field.dataType;
  }

  inputChangeArgDataType(index) {
    switch (this.placeholderList[index]) {
      case "<number>":
      case "<base>":
      case "<exponent>":
      case "<place>":
      case "<milliseconds>":
      case "<amount>":
      case "<startIndex>":
      case "<endIndex>":
      case "<finalPaddedLength>":
      case "<padding>":
      case "<codePointIndex>":
        this.argumentDataTypeList[index] = EnumDataType.Long;
        break;
      default:
        this.argumentDataTypeList[index] = EnumDataType.String;
        break;
    }
    console.log(this.argumentDataTypeList);
  }

  addArgument(mode: string) {
    this.argumentTypeList.push(mode);
    if (mode === EnumArgumentType.Select) {
      this.argumentList.push("");
    } else {
      for (let s = 0; s < this.argumentTypeList.length; s++) {
        this.argumentList[s] = this.argumentList[s] || ""
      }
    }
    this.argumentDelList.push(false);
    this.argumentDataTypeList.push("");
  }

  deleteArgument(index: number) {
    this.argumentList.splice(index, 1);
    this.argumentTypeList.splice(index, 1);
    this.argumentDelList.splice(index, 1);
    this.argumentDataTypeList.splice(index, 1);
  }

  checkDisableAddArg(): boolean {
    if (this.formGroup.value.operator === "" || this.argumentTypeList.length >= this.argObj.maxArgNum) {
      return true;
    } else {
      return this.argumentList.length == this.argObj.maxArgNum;
    }
  }

  deleteCustomLogic(index: number) {
    let deletedCustomLogicArray = this.readyCustomFieldList.slice(index);
    let deleteCustomFieldName: Array<string> = [];
    deletedCustomLogicArray.forEach(field => deleteCustomFieldName.push(field.fieldName));
    this.tempFieldList = this.tempFieldList.filter(field => !deleteCustomFieldName.includes(field.fieldName))
    this.onSelectExpressionType(this.formGroup.value.expressionType);
    this.readyCustomFieldList.splice(index);
  }

  // todo: some problem here
  add() {
    this.isSubmitted = false;
    let dropZone: string;
    switch (this.dropZone) {
      case EnumSmartQueryDropZone.DropZoneField:
        dropZone = EnumSmartQueryDropZone.DropZoneField;
        break;
      case EnumSmartQueryDropZone.DropZoneDimension:
      case EnumSmartQueryDropZone.DropZoneMeasure:
        dropZone = ![EnumDataType.Int, EnumDataType.Double, EnumDataType.Long, EnumDataType.Float].includes(this.formGroup.value.outputDataType) ? "dimension" : "measure";
        // dropZone = ((this.formGroup.value.outputDataType !== EnumDataType.Int) && (this.formGroup.value.outputDataType !== EnumDataType.Double)) ? "dimension" : "measure";
        break;
      case EnumSmartQueryDropZone.DropZoneCustom:
        dropZone = EnumSmartQueryDropZone.DropZoneCustom;
        break;
    }

    let lastCustomFieldList = this.readyCustomFieldList[this.readyCustomFieldList.length - 1];

    let addCustomFieldForm = {
      fieldName: this.formGroup.value.customFieldName,
      dataType: lastCustomFieldList.dataType,
      readyCustomFieldList: this.readyCustomFieldList,
      isCustom: true,
      dropZone: dropZone,
      isMeasure: false
    }
    console.log(addCustomFieldForm);
    this.modal.close(addCustomFieldForm);
  }

  checkDisableCustomButton(): boolean {
    return this.argumentTypeList.length < this.argObj.minArgNum;
  }

  custom() {
    console.log(this.fieldList);
    console.log(this.argumentList);
    let tempArg = "";
    let argElement = [];
    for (let i = 0; i < this.argumentList.length; i++) {
      let element = {
        arg: this.argumentList[i],
        type: this.argumentTypeList[i],
        dataType: this.argumentDataTypeList[i]
      }
      argElement.push(element);
      if (i == 0) {
        tempArg += this.argumentList[0];
      } else {
        tempArg += `, ${this.argumentList[i]}`;
      }
    }

    this.output = this.output.replace("()", `(${tempArg})`);

    let customFieldList = {
      fieldName: this.output,
      operator: this.formGroup.value.operator,
      argElement: argElement,
      dataType: this.formGroup.value.outputDataType,
      dropZone: EnumSmartQueryDropZone.DropZoneCustom
    }
    this.readyCustomFieldList.push(customFieldList);
    this.tempFieldList.push(customFieldList);
    this.resetValue();
    console.log(this.readyCustomFieldList);
  }

  resetValue() {
    this.formGroup.patchValue({expressionType: '', operator: '', outputDataType: ''})
    this.argumentList = [];
    this.argumentDelList = [];
    this.argumentTypeList = [];
    this.output = "";
    this.onSelectExpressionType(this.formGroup.value.expressionType);
  }
}
