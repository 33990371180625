"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HrefPreventDefaultDirective = /** @class */ (function () {
    function HrefPreventDefaultDirective() {
    }
    HrefPreventDefaultDirective.prototype.preventDefault = function (event) {
        if (this.href.length === 0 || this.href === '#') {
            event.preventDefault();
        }
    };
    return HrefPreventDefaultDirective;
}());
exports.HrefPreventDefaultDirective = HrefPreventDefaultDirective;
