<div *ngIf="isShowObs | async">
  <div id="splash-routing">
<!--    <mat-spinner></mat-spinner>-->

    <svg class="splash-spinner" viewBox="0 0 100 100">
      <circle id="spinner-circle" cx="50" cy="50" r="40"></circle>
    </svg>

  </div>
</div>
