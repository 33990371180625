"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultLayoutConfig = {
    demo: 'data-canva',
    // Javascript Settings
    js: {
        breakpoints: {
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1400,
        },
        colors: {
            theme: {
                base: {
                    white: '#ffffff',
                    primary: '#3699FF',
                    secondary: '#E5EAEE',
                    success: '#1BC5BD',
                    green: 'green',
                    info: '#8950FC',
                    warning: '#FFA800',
                    danger: '#F64E60',
                    light: '#E4E6EF',
                    dark: '#181C32',
                },
                light: {
                    white: '#ffffff',
                    primary: '#E1F0FF',
                    secondary: '#EBEDF3',
                    success: '#C9F7F5',
                    green: '#cdf7e1',
                    info: '#EEE5FF',
                    warning: '#FFF4DE',
                    danger: '#FFE2E5',
                    light: '#F3F6F9',
                    dark: '#D6D6E0',
                },
                inverse: {
                    white: '#ffffff',
                    primary: '#ffffff',
                    secondary: '#3F4254',
                    success: '#ffffff',
                    green: '#ffffff',
                    info: '#ffffff',
                    warning: '#ffffff',
                    danger: '#ffffff',
                    light: '#464E5F',
                    dark: '#ffffff',
                },
            },
            gray: {
                'gray-100': '#F3F6F9',
                'gray-200': '#EBEDF3',
                'gray-300': '#E4E6EF',
                'gray-400': '#D1D3E0',
                'gray-500': '#B5B5C3',
                'gray-600': '#7E8299',
                'gray-700': '#5E6278',
                'gray-800': '#3F4254',
                'gray-900': '#181C32',
            },
        },
        fontFamily: 'Poppins',
    },
    // Base Settins
    self: {
        layout: 'default',
    },
    // Page loader
    pageLoader: {
        type: '',
    },
    // Header
    header: {
        self: {
            display: true,
            width: 'fixed',
            theme: 'light',
            fixed: {
                desktop: false,
                mobile: false,
            },
        },
        menu: {
            self: {
                display: false,
                static: true,
                layout: 'tab',
                rootArrow: false,
                iconStyle: 'duotone',
            },
            desktop: {
                arrow: true,
                toggle: 'click',
                submenu: {
                    theme: 'light',
                    arrow: true,
                },
            },
            mobile: {
                submenu: {
                    theme: 'light',
                    accordion: true,
                },
            },
        },
    },
    // Subheader
    subheader: {
        display: true,
        displayDesc: false,
        displayDaterangepicker: false,
        layoutVersion: 'v1',
        fixed: false,
        width: 'fixed',
        clear: true,
        style: 'solid',
    },
    // Content
    content: {
        width: 'fixed',
    },
    // Brand
    brand: {
        self: {
            theme: 'light',
        },
    },
    // Aside
    aside: {
        self: {
            theme: 'light',
            display: true,
            fixed: true,
            minimize: {
                toggle: true,
                default: true,
                hoverable: false,
            },
        },
        footer: {
            self: {
                display: false,
            },
        },
        menu: {
            static: true,
            dropdown: false,
            scroll: true,
            iconStyle: 'duotone',
            submenu: {
                accordion: true,
                dropdown: {
                    arrow: true,
                    hoverTimeout: 500,
                },
            },
        },
    },
    // Footer
    footer: {
        display: false,
        width: 'fluid',
        fixed: true,
    },
    // Extras
    extras: {
        // Search
        search: {
            display: true,
            layout: 'dropdown',
            offcanvas: {
                direction: 'right',
            },
        },
        // Notifications
        notifications: {
            display: true,
            layout: 'dropdown',
            dropdown: {
                style: 'light',
            },
            offcanvas: {
                direction: 'right',
            },
        },
        // Quick Actionss
        quickActions: {
            display: true,
            layout: 'dropdown',
            dropdown: {
                style: 'light',
            },
            offcanvas: {
                direction: 'right',
            },
        },
        // User
        user: {
            display: true,
            layout: 'offcanvas',
            dropdown: {
                style: 'light',
            },
            offcanvas: {
                direction: 'right',
            },
        },
        // Languages
        languages: {
            display: true,
        },
        // Cart
        cart: {
            display: false,
            layout: 'dropdown',
            offcanvas: {
                direction: 'right',
            },
            dropdown: {
                style: 'light',
            },
        },
        // Chat
        chat: {
            display: false,
        },
        // Quick Panel
        quickPanel: {
            display: false,
            offcanvas: {
                direction: 'right',
            },
        },
        // Page Toolbar
        toolbar: {
            display: false,
        },
        // Scrolltop
        scrolltop: {
            display: false,
        },
    },
};
