import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { KTUtil } from '../../../../../assets/js/components/util';
import KTLayoutAsideToggle from '../../../../../assets/js/layout/base/aside-toggle';
import KTLayoutStickyCard from '../../../../../assets/js/layout/base/sticky-card';
import KTLayoutStretchedCard from '../../../../../assets/js/layout/base/stretched-card';
import { LayoutService } from '../../../../_metronic/core';
import KTLayoutBrand from '../../../../../assets/js/layout/base/brand';
import KTLayoutAside from '../../../../../assets/js/layout/base/aside';
import KTLayoutAsideMenu from '../../../../../assets/js/layout/base/aside-menu';
import {Subscription} from "rxjs";
import { BreadCrumbService } from '../../../../global-services/bread-crumb.service';
import { IBreadCrumb } from '../../../../interfaces/custom/bread-crumb.interface';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { RouteNameService } from '../../../../global-services/route-name.service';
import { EnumUserRole } from "../../../../enums/user-role.enum";
import { ContextDataService } from '../../../../global-services/context-data.service';
import { EnumRouteName } from "../../../../enums/route-name.enum";

@Component({
  selector: 'app-scripts-init',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './scripts-init.component.html',
})
export class ScriptsInitComponent implements OnInit, AfterViewInit {
  asideSelfMinimizeToggle = false;

  breadcrumbs$: Subscription;
  EnumUserRole: typeof EnumUserRole = EnumUserRole;
  EnumRouteName: typeof EnumRouteName = EnumRouteName;

  customBreadCrumbs: IBreadCrumb[];
  title: string;
  userRole: EnumUserRole;
  currentRouteName;
  constructor(private layout: LayoutService,
              private activatedRoute: ActivatedRoute,
              private router: Router
  ) {
    // console.log('activatedRoute init',activatedRoute,activatedRoute.snapshot)
    // activatedRoute.data.subscribe(data => console.log('sub activatedRoute',data.title));
    ContextDataService.init(activatedRoute.snapshot.data.rsContextData);

    this.userRole = ContextDataService.userRole;

    // load breadcrumbs and construct custom breadcrumbs
    this.breadcrumbs$ = BreadCrumbService.getBreadCrumbsObservable().subscribe(breadcrumbs => {

      // init custom breadcrumbs and read title
      this.customBreadCrumbs = breadcrumbs[breadcrumbs.length - 1].customBreadCrumbs(activatedRoute);
      if (breadcrumbs.length > 0) {
        this.title = this.customBreadCrumbs[this.customBreadCrumbs.length - 1].title;
      }

      // get current route name
      this.currentRouteName = RouteNameService.getCurrentRouteName(this.activatedRoute);
    });
  }

  ngOnInit(): void {
    // console.log('activatedRoute init2',this.activatedRoute,this.activatedRoute.snapshot)
    ContextDataService.init(this.activatedRoute.snapshot.data.rsContextData);
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
  }

  ngAfterViewInit() {
    KTUtil.ready(() => {
      // Init Brand Panel For Logo
      KTLayoutBrand.init('kt_brand');
      // Init Aside
      KTLayoutAside.init('kt_aside');
      // Init Aside Menu
      KTLayoutAsideMenu.init('kt_aside_menu');

      if (this.asideSelfMinimizeToggle) {
        // Init Aside Menu Toggle
        KTLayoutAsideToggle.init('kt_aside_toggle');
      }

      // Init Sticky Card
      KTLayoutStickyCard.init('kt_page_sticky_card');
      // Init Stretched Card
      KTLayoutStretchedCard.init('kt_page_stretched_card');
    });
  }
}
