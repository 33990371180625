"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["#splash-screen[_ngcontent-%COMP%]{position:absolute;z-index:10001;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;background-color:#f2f3f8}#splash-screen[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{margin-left:calc(100vw - 100%);margin-bottom:30px}#splash-screen.hidden[_ngcontent-%COMP%]{opacity:0;visibility:hidden}.splash-spinner[_ngcontent-%COMP%]{-webkit-animation:2s linear infinite rotate;animation:2s linear infinite rotate;margin-left:calc(100vw - 100%);width:50px;height:50px}.splash-spinner[_ngcontent-%COMP%]   #spinner-circle[_ngcontent-%COMP%]{fill:none;stroke-width:5;stroke:#e74340;stroke-linecap:round;stroke-dasharray:90,100}@-webkit-keyframes rotate{100%{transform:rotate(360deg)}}@keyframes rotate{100%{transform:rotate(360deg)}}"];
exports.styles = styles;
