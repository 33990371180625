"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TimeDurationPipe = /** @class */ (function () {
    function TimeDurationPipe() {
    }
    TimeDurationPipe.prototype.transform1 = function (value) {
        var time = Number(value) / 1000;
        var minute = Math.floor(time / 60);
        var second = time % 60;
        if (time < 60) {
            return time.toFixed(1) + ' Second(s)';
        }
        if (second > 0) {
            return minute + ' Minute(s) ' + second.toFixed(1) + ' Second(s)';
        }
        else {
            return minute + ' Minute(s)';
        }
    };
    TimeDurationPipe.prototype.transform = function (value) {
        var second = Number(value) / 1000;
        if (second <= 0) {
            return 'Just Now';
        }
        var minute = Math.floor(second / 60);
        if (minute <= 0) {
            return 'Just Now';
        }
        var hour = Math.floor(second / 60 / 60);
        if (hour <= 0) {
            return minute + ' minute(s) ago';
        }
        var day = Math.floor(second / 60 / 60 / 24);
        if (day <= 0) {
            return hour + ' hour(s) ago';
        }
        var month = Math.floor(second / 60 / 60 / 24 / 30);
        if (month <= 0) {
            return day + ' day(s) ago';
        }
        var year = Math.floor(second / 60 / 60 / 24 / 365);
        if (year <= 0) {
            return month + ' month(s) ago';
        }
        return year + ' year(s) ago';
    };
    return TimeDurationPipe;
}());
exports.TimeDurationPipe = TimeDurationPipe;
