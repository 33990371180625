"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TextcaseConvertPipe = /** @class */ (function () {
    function TextcaseConvertPipe() {
    }
    TextcaseConvertPipe.prototype.transform = function (value) {
        return this.titleCase(value);
    };
    TextcaseConvertPipe.prototype.titleCase = function (str) {
        str = str.replaceAll(' ', '_').replaceAll('-', '_');
        var splitStr = str.toLowerCase().split('_');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    };
    return TextcaseConvertPipe;
}());
exports.TextcaseConvertPipe = TextcaseConvertPipe;
