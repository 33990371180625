"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("../../../../_metronic/core");
var HeaderMobileComponent = /** @class */ (function () {
    function HeaderMobileComponent(layout) {
        this.layout = layout;
        this.headerLogo = '';
        this.asideSelfDisplay = true;
        this.headerMenuSelfDisplay = true;
        this.headerMobileClasses = '';
        this.headerMobileAttributes = {};
    }
    HeaderMobileComponent.prototype.ngOnInit = function () {
        // build view by layout config settings
        this.headerMobileClasses = this.layout.getStringCSSClasses('header_mobile');
        this.headerMobileAttributes = this.layout.getHTMLAttributes('header_mobile');
        this.headerLogo = this.getLogoUrl();
        this.asideSelfDisplay = this.layout.getProp('aside.self.display');
        this.headerMenuSelfDisplay = this.layout.getProp('header.menu.self.display');
    };
    HeaderMobileComponent.prototype.ngAfterViewInit = function () {
        // Init Header Topbar For Mobile Mode
        // KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');
    };
    HeaderMobileComponent.prototype.getLogoUrl = function () {
        var headerSelfTheme = this.layout.getProp('header.self.theme') || '';
        var brandSelfTheme = this.layout.getProp('brand.self.theme') || '';
        var result = 'Datacanva_Logo_White.png';
        if (!this.asideSelfDisplay) {
            if (headerSelfTheme === 'light') {
                result = 'Datacanva_Logo.webp';
            }
        }
        else {
            if (brandSelfTheme === 'light') {
                result = 'Datacanva_Logo.webp';
            }
        }
        return "./assets/media/logos/" + result;
    };
    return HeaderMobileComponent;
}());
exports.HeaderMobileComponent = HeaderMobileComponent;
