"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_role_enum_1 = require("../enums/user-role.enum");
var context_data_service_1 = require("../global-services/context-data.service");
var route_name_enum_1 = require("../enums/route-name.enum");
var core_2 = require("../_metronic/core");
var content_1 = require("../../assets/js/layout/base/content");
var role_access_service_1 = require("./pages/userGroup/_services/role-access.service");
var ThemeComponent = /** @class */ (function () {
    function ThemeComponent(router, activatedRoute, initService, roleAccessService, layout) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.initService = initService;
        this.roleAccessService = roleAccessService;
        this.layout = layout;
        // Public variables
        this.selfLayout = 'default';
        this.contentClasses = '';
        this.contentContainerClasses = '';
        this.subheaderDisplay = true;
        this.asideHTMLAttributes = {};
        this.headerMobileClasses = '';
        this.headerMobileAttributes = {};
        this.headerHTMLAttributes = {};
        // offcanvases
        this.extrasSearchOffcanvasDisplay = false;
        this.extrasNotificationsOffcanvasDisplay = false;
        this.extrasQuickActionsOffcanvasDisplay = false;
        this.extrasCartOffcanvasDisplay = false;
        this.extrasUserOffcanvasDisplay = false;
        this.extrasQuickPanelDisplay = false;
        this.extrasScrollTopDisplay = false;
        this.EnumUserRole = user_role_enum_1.EnumUserRole;
        this.EnumRouteName = route_name_enum_1.EnumRouteName;
        // TODO: need to solve it in the future
        if (this.router.url === '/') {
            window.location.href = '/data-canva/dashboard';
        }
        this.initService.init();
        // console.log('theme',activatedRoute,activatedRoute.snapshot.data)
        // load context data and init the ContextDataService
        // ContextDataService.init(activatedRoute.snapshot.data.rsContextData);
        this.userRole = context_data_service_1.ContextDataService.userRole;
        // load breadcrumbs and construct custom breadcrumbs
        // this.breadcrumbs$ = BreadCrumbService.getBreadCrumbsObservable().subscribe(breadcrumbs => {
        //
        //     // init custom breadcrumbs and read title
        //     this.customBreadCrumbs = breadcrumbs[breadcrumbs.length - 1].customBreadCrumbs(activatedRoute);
        //     if (breadcrumbs.length > 0) {
        //         this.title = this.customBreadCrumbs[this.customBreadCrumbs.length - 1].title;
        //     }
        //
        //     // get current route name
        //     this.currentRouteName = RouteNameService.getCurrentRouteName(this.activatedRoute);
        // });
    }
    ThemeComponent.prototype.ngOnInit = function () {
        // build view by layout config settings
        this.selfLayout = this.layout.getProp('self.layout');
        this.asideSelfDisplay = this.layout.getProp('aside.self.display');
        this.asideMenuStatic = this.layout.getProp('aside.menu.static');
        this.subheaderDisplay = this.layout.getProp('subheader.display');
        this.contentClasses = this.layout.getStringCSSClasses('content');
        this.contentContainerClasses = this.layout.getStringCSSClasses('content_container');
        this.contentExtended = this.layout.getProp('content.extended');
        this.asideHTMLAttributes = this.layout.getHTMLAttributes('aside');
        this.asideCSSClasses = this.layout.getStringCSSClasses('aside');
        this.headerMobileClasses = this.layout.getStringCSSClasses('header_mobile');
        this.headerMobileAttributes = this.layout.getHTMLAttributes('header_mobile');
        this.footerDisplay = this.layout.getProp('footer.display');
        this.footerCSSClasses = this.layout.getStringCSSClasses('footer');
        this.headerCSSClasses = this.layout.getStringCSSClasses('header');
        this.headerHTMLAttributes = this.layout.getHTMLAttributes('header');
        // offcanvases
        if (this.layout.getProp('extras.search.display')) {
            this.extrasSearchOffcanvasDisplay =
                this.layout.getProp('extras.search.layout') === 'offcanvas';
        }
        if (this.layout.getProp('extras.notifications.display')) {
            this.extrasNotificationsOffcanvasDisplay =
                this.layout.getProp('extras.notifications.layout') === 'offcanvas';
        }
        if (this.layout.getProp('extras.quickActions.display')) {
            this.extrasQuickActionsOffcanvasDisplay =
                this.layout.getProp('extras.quickActions.layout') === 'offcanvas';
        }
        if (this.layout.getProp('extras.cart.display')) {
            this.extrasCartOffcanvasDisplay =
                this.layout.getProp('extras.cart.layout') === 'offcanvas';
        }
        if (this.layout.getProp('extras.user.display')) {
            this.extrasUserOffcanvasDisplay =
                this.layout.getProp('extras.user.layout') === 'offcanvas';
        }
        this.extrasQuickPanelDisplay = this.layout.getProp('extras.quickPanel.display');
        this.extrasScrollTopDisplay = this.layout.getProp('extras.scrolltop.display');
    };
    ThemeComponent.prototype.ngAfterViewInit = function () {
        if (this.ktAside) {
            for (var key in this.asideHTMLAttributes) {
                if (this.asideHTMLAttributes.hasOwnProperty(key)) {
                    this.ktAside.nativeElement.attributes[key] = this.asideHTMLAttributes[key];
                }
            }
        }
        if (this.ktHeaderMobile) {
            for (var key in this.headerMobileAttributes) {
                if (this.headerMobileAttributes.hasOwnProperty(key)) {
                    this.ktHeaderMobile.nativeElement.attributes[key] = this.headerMobileAttributes[key];
                }
            }
        }
        if (this.ktHeader) {
            for (var key in this.headerHTMLAttributes) {
                if (this.headerHTMLAttributes.hasOwnProperty(key)) {
                    this.ktHeader.nativeElement.attributes[key] = this.headerHTMLAttributes[key];
                }
            }
        }
        // Init Content
        content_1.default.init('kt_content');
    };
    ThemeComponent.prototype.route = function (bc) {
        var href = bc.href.substring(1);
        var params = href.split("/");
        if (undefined !== bc.queryParams) {
            this.router.navigate(params, { queryParams: bc.queryParams });
        }
        else {
            this.router.navigate(params);
        }
    };
    ThemeComponent.prototype.ngOnDestroy = function () {
        //     this.breadcrumbs$.unsubscribe();
    };
    return ThemeComponent;
}());
exports.ThemeComponent = ThemeComponent;
