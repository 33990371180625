"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ContextDataService = /** @class */ (function () {
    function ContextDataService() {
    }
    ContextDataService.init = function (data) {
        if (!data) {
            return;
        }
        this._userID = data.userID;
        this._orgID = data.orgID;
        this._userName = data.userName;
        this._userRole = data.userRole;
        this._userDisplayName = data.userDisplayName;
        this._timezone = data.timezone;
        this._roleAccess = data['functionListBeanList'];
    };
    Object.defineProperty(ContextDataService, "userID", {
        get: function () {
            return this._userID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextDataService, "orgID", {
        get: function () {
            return this._orgID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextDataService, "userName", {
        get: function () {
            return this._userName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextDataService, "userRole", {
        get: function () {
            return this._userRole;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextDataService, "userDisplayName", {
        get: function () {
            return this._userDisplayName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextDataService, "email", {
        get: function () {
            return this._email;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextDataService, "timezone", {
        get: function () {
            return this._timezone;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextDataService, "roleAccess", {
        get: function () {
            return this._roleAccess;
        },
        enumerable: true,
        configurable: true
    });
    return ContextDataService;
}());
exports.ContextDataService = ContextDataService;
