"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var forms_1 = require("@angular/forms");
var form_control_helper_1 = require("../../helpers/form-control.helper");
var dateFormat_enum_1 = require("../../../enums/dateFormat.enum");
var data_type_enum_1 = require("../../../enums/data-type.enum");
var SetDateFormatModalComponent = /** @class */ (function () {
    function SetDateFormatModalComponent(modal, fb, formControlHelper) {
        this.modal = modal;
        this.fb = fb;
        this.formControlHelper = formControlHelper;
    }
    Object.defineProperty(SetDateFormatModalComponent.prototype, "enumDataType", {
        get: function () {
            return data_type_enum_1.EnumDataType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetDateFormatModalComponent.prototype, "enumDateSymbol", {
        get: function () {
            return dateFormat_enum_1.EnumDateSymbol;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetDateFormatModalComponent.prototype, "enumDateFormat", {
        get: function () {
            return dateFormat_enum_1.EnumDateFormat;
        },
        enumerable: true,
        configurable: true
    });
    SetDateFormatModalComponent.prototype.ngOnInit = function () {
        this.formGroup = this.fb.group({
            dateFormat: [this.dateFormat, forms_1.Validators.compose([forms_1.Validators.required])],
            dateFormatSelectList: [""]
        });
    };
    SetDateFormatModalComponent.prototype.loadDateFormatPopover = function (dateFormat) {
        this.dateFormatPopTitle = dateFormat;
        switch (dateFormat) {
            case dateFormat_enum_1.EnumDateFormat.MM:
                this.dateFormatPopContent = "Month of the year in a two-digit format";
                break;
            case dateFormat_enum_1.EnumDateFormat.MMM:
                this.dateFormatPopContent = "Abbreviated month of the year";
                break;
            case dateFormat_enum_1.EnumDateFormat.MMMM:
                this.dateFormatPopContent = "Month of the year";
                break;
            case dateFormat_enum_1.EnumDateFormat.dd:
                this.dateFormatPopContent = "Day of the month";
                break;
            case dateFormat_enum_1.EnumDateFormat.yy:
                this.dateFormatPopContent = "Year in two-digit format";
                break;
            case dateFormat_enum_1.EnumDateFormat.yyyy:
                this.dateFormatPopContent = "Year in four-digit format";
                break;
            case dateFormat_enum_1.EnumDateFormat.HH:
                this.dateFormatPopContent = "Hour of the day (0-23)";
                break;
            case dateFormat_enum_1.EnumDateFormat.mm:
                this.dateFormatPopContent = "Minute";
                break;
            case dateFormat_enum_1.EnumDateFormat.ss:
                this.dateFormatPopContent = "Second";
                break;
            case dateFormat_enum_1.EnumDateFormat.SSS:
                this.dateFormatPopContent = "Fraction of a second";
                break;
            case dateFormat_enum_1.EnumDateFormat.z:
                this.dateFormatPopContent = "UTC time offset";
                break;
        }
    };
    SetDateFormatModalComponent.prototype.addSymbol = function (symbol) {
        this.dateFormat = (typeof this.dateFormat == "undefined") ? "" : this.dateFormat;
        var dfBean = { "type": "", "value": "" };
        switch (symbol) {
            case dateFormat_enum_1.EnumDateSymbol.colon:
                dfBean.type = "COLON";
                break;
            case dateFormat_enum_1.EnumDateSymbol.dot:
                dfBean.type = "DOT";
                break;
            case dateFormat_enum_1.EnumDateSymbol.minus:
                dfBean.type = "MINUS";
                break;
            case dateFormat_enum_1.EnumDateSymbol.slash:
                dfBean.type = "SLASH";
                break;
            case dateFormat_enum_1.EnumDateSymbol.space:
                dfBean.type = "SPACE";
                break;
        }
        dfBean.value = symbol;
        this.dateFormat += symbol;
        this.dateFormatSelectList.push(dfBean);
        this.formGroup.patchValue({ dateFormat: this.dateFormat, dateFormatSelectList: this.dateFormatSelectList });
    };
    SetDateFormatModalComponent.prototype.addDateFormat = function (dateFormat) {
        this.dateFormat = (typeof this.dateFormat == "undefined") ? "" : this.dateFormat;
        var dfBean;
        switch (dateFormat) {
            case dateFormat_enum_1.EnumDateFormat.yyyy:
            case dateFormat_enum_1.EnumDateFormat.yy:
                dfBean = { "type": "YEAR", "value": dateFormat };
                break;
            case dateFormat_enum_1.EnumDateFormat.MM:
            case dateFormat_enum_1.EnumDateFormat.MMM:
            case dateFormat_enum_1.EnumDateFormat.MMMM:
                dfBean = { "type": "MONTH", "value": dateFormat };
                break;
            case dateFormat_enum_1.EnumDateFormat.timestamp13:
            case dateFormat_enum_1.EnumDateFormat.timestamp10:
                dfBean = { "type": "TIMESTAMP", "value": dateFormat };
                break;
            default:
                dfBean = { "type": dateFormat, "value": dateFormat };
                break;
        }
        this.dateFormat += dateFormat;
        this.dateFormatSelectList.push(dfBean);
        this.formGroup.patchValue({ dateFormat: this.dateFormat, dateFormatSelectList: this.dateFormatSelectList });
    };
    SetDateFormatModalComponent.prototype.checkDisableDateFormat = function (dateFormatType) {
        var tempList = this.dateFormatSelectList.filter(function (format) { return format.type == dateFormatType; });
        return tempList.length > 0;
    };
    SetDateFormatModalComponent.prototype.deleteArg = function (index) {
        this.dateFormatSelectList.splice(index, 1);
        var dateFormatStrList = [];
        this.dateFormatSelectList.forEach(function (dateFormatStr) { return dateFormatStrList.push(dateFormatStr.value); });
        this.dateFormat = dateFormatStrList.join('');
        this.formGroup.patchValue({ dateFormat: this.dateFormat, dateFormatSelectList: this.dateFormatSelectList });
    };
    SetDateFormatModalComponent.prototype.clearAllArg = function () {
        this.dateFormat = "";
        this.dateFormatSelectList = [];
        this.formGroup.patchValue({ dateFormat: this.dateFormat, dateFormatSelectList: this.dateFormatSelectList });
    };
    SetDateFormatModalComponent.prototype.save = function () {
        var response = {
            dateFormatSelectList: this.formGroup.value.dateFormatSelectList,
            dateFormat: this.formGroup.value.dateFormat
        };
        this.modal.close(response);
    };
    return SetDateFormatModalComponent;
}());
exports.SetDateFormatModalComponent = SetDateFormatModalComponent;
