"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var core_2 = require("../../../../_metronic/core");
var router_1 = require("@angular/router");
var context_data_service_1 = require("../../../../global-services/context-data.service");
var function_list_enum_1 = require("../../../../enums/function-list.enum");
var role_access_helper_1 = require("../../../../shared/helpers/role-access.helper");
// @ts-ignore
var package_json_1 = require("../../../../../../package.json");
var user_role_enum_1 = require("../../../../enums/user-role.enum");
var router_enum_1 = require("../../../../enums/router.enum");
var AsideComponent = /** @class */ (function () {
    function AsideComponent(layout, loc, router, cdr, roleAccessHelper) {
        this.layout = layout;
        this.loc = loc;
        this.router = router;
        this.cdr = cdr;
        this.roleAccessHelper = roleAccessHelper;
        this.asideMenuHTMLAttributes = {};
        this.asideMenuScroll = 1;
        this.asideSelfMinimizeToggle = false;
        this.userRole = context_data_service_1.ContextDataService.userRole;
        this.version = package_json_1.version;
        this.currentYear = new Date().getFullYear();
        // For role Access
        this.isDataWareHouse = null;
        this.isDataPipeline = null;
        this.isUserGroup = null;
        this.isAccessControl = null;
        this.isSmartQuery = null;
        this.isSmartSearch = null;
        this.isProfile = null;
        this.isAdminSetting = null;
    }
    Object.defineProperty(AsideComponent.prototype, "enumUserRole", {
        get: function () {
            return user_role_enum_1.EnumUserRole;
        },
        enumerable: true,
        configurable: true
    });
    AsideComponent.prototype.ngOnInit = function () {
        this.loadAllRoleAccess();
        this.userId = String(context_data_service_1.ContextDataService.userID);
        // load view settings
        this.disableAsideSelfDisplay =
            this.layout.getProp('aside.self.display') === false;
        this.brandSkin = this.layout.getProp('brand.self.theme');
        this.headerLogo = this.getLogo();
        this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
        this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
        this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
        this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown') ? '1' : '0';
        this.brandClasses = this.layout.getProp('brand');
        this.asideSelfMinimizeToggle = this.layout.getProp('aside.self.minimize.toggle');
        this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
        // this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
        // Routing
        this.location = this.loc;
    };
    AsideComponent.prototype.loadAllRoleAccess = function () {
        try {
            this.isDataWareHouse = this.roleAccessHelper.isEnabledAccess(function_list_enum_1.EnumFunctionList.DataWarehousePage);
            this.isDataPipeline = this.roleAccessHelper.isEnabledAccess(function_list_enum_1.EnumFunctionList.DataPipelinePage);
            this.isSmartQuery = this.roleAccessHelper.isEnabledAccess(function_list_enum_1.EnumFunctionList.SmartQueryPage);
            this.isSmartSearch = this.roleAccessHelper.isEnabledAccess(function_list_enum_1.EnumFunctionList.SmartSearchPage);
            this.isUserGroup = this.roleAccessHelper.isEnabledAccess(function_list_enum_1.EnumFunctionList.UserGroup);
            this.isAccessControl = this.roleAccessHelper.isEnabledAccess(function_list_enum_1.EnumFunctionList.AccessControl);
            this.isProfile = this.roleAccessHelper.isEnabledAccess(function_list_enum_1.EnumFunctionList.MyProfilePage);
            this.isAdminSetting = this.roleAccessHelper.isEnabledAccess(function_list_enum_1.EnumFunctionList.AdminSettingPage);
        }
        catch (e) {
            this.roleAccessHelper.roleAccessNotSetError(context_data_service_1.ContextDataService.userRole);
        }
    };
    AsideComponent.prototype.navigateToAdminSetting = function () {
        var isViewSmartSearchSetting = this.roleAccessHelper.isEnabledAccess(function_list_enum_1.EnumFunctionList.ViewSmartSearchSetting);
        if (isViewSmartSearchSetting) {
            this.router.navigate(["/" + router_enum_1.EnumMainPageRoute.AdminSetting, "smart-search"]).then();
            return;
        }
        else {
            this.router.navigate(["/" + router_enum_1.EnumMainPageRoute.AdminSetting, "notification"]).then();
        }
    };
    AsideComponent.prototype.getLogo = function () {
        if (this.brandSkin === 'light') {
            return './assets/media/logos/Datacanva_Logo.webp';
        }
        else {
            return './assets/media/logos/Datacanva_Logo.webp';
        }
    };
    AsideComponent.prototype.logout = function () {
        console.log('this.cdr', this.cdr);
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }
        localStorage.removeItem('token');
        this.router.navigate(["/" + router_enum_1.EnumMainPageRoute.Auth + "/" + router_enum_1.EnumAuthPageRoute.Login]).then();
    };
    return AsideComponent;
}());
exports.AsideComponent = AsideComponent;
