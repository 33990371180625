"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./splash-routing.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./splash-routing.component");
var i4 = require("./splash-routing.service");
var styles_SplashRoutingComponent = [i0.styles];
var RenderType_SplashRoutingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplashRoutingComponent, data: {} });
exports.RenderType_SplashRoutingComponent = RenderType_SplashRoutingComponent;
function View_SplashRoutingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["id", "splash-routing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "splash-spinner"], ["viewBox", "0 0 100 100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:circle", [["cx", "50"], ["cy", "50"], ["id", "spinner-circle"], ["r", "40"]], null, null, null, null, null))], null, null); }
function View_SplashRoutingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_SplashRoutingComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.isShowObs)); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_SplashRoutingComponent_0 = View_SplashRoutingComponent_0;
function View_SplashRoutingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-splash-routing", [], null, null, null, View_SplashRoutingComponent_0, RenderType_SplashRoutingComponent)), i1.ɵdid(1, 114688, null, 0, i3.SplashRoutingComponent, [i4.SplashRoutingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SplashRoutingComponent_Host_0 = View_SplashRoutingComponent_Host_0;
var SplashRoutingComponentNgFactory = i1.ɵccf("app-splash-routing", i3.SplashRoutingComponent, View_SplashRoutingComponent_Host_0, {}, {}, []);
exports.SplashRoutingComponentNgFactory = SplashRoutingComponentNgFactory;
