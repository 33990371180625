<div class="modal-header">
  <div class="modal-title h4">
    Add Custom Field
  </div>
  <div class="float-right">
    <button
      title="Open Custom Field Document"
      class="btn btn-light btn-sm"
      (click)="openPopupDocumentation()"
    >
      <i class="fas fa-exclamation-circle"></i>
      Learn More
    </button>
  </div>
</div>
<div class="modal-body">
  <form id="custom-field-form" action="#" class="form form-label-right" [formGroup]="formGroup" (ngSubmit)="add()">
    <div class="row">
      <div class="col-lg-12 margin-bottom-10">
        <label>Custom Field Name:</label>
        <input
          title
          type="text"
          class="form-control form-control-lg form-control-solid"
          autocomplete="on"
          placeholder="custom field name"
          formControlName="customFieldName"
          [disabled]="mode === enumSmartQueryCustomFieldAction.ActionEdit"
          name="customFieldName"
          [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'customFieldName')"
          [class.is-valid]="formControlHelper.isControlValid(formGroup, 'customFieldName')"
        />
        <div class="valid-feedback" *ngIf="formControlHelper.isControlValid(formGroup, 'customFieldName')">
          Custom Field Name was entered correct
        </div>
        <div
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'customFieldName')"
        >
          Custom Field Name is required
        </div>
        <div
          class="invalid-feedback"
          *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'duplicateNameInvalid', 'customFieldName')"
        >
          This name is used in other field
        </div>
      </div>
      <div class="margin-top-5 col-lg-6 border-right">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Expression Type:</label>
            <select
              title
              class="form-control form-group-lg form-control-solid"
              name="expressionType"
              formControlName="expressionType"
              (change)="onSelectExpressionType(formGroup.value.expressionType)"
            >
              <option value="" disabled>--- Please Select ---</option>
              <option *ngFor="let expression of expressionList" [value]="expression">
                {{expression | textCase}}
              </option>
            </select>
          </div>
          <div class="col-lg-6">
            <label>Operator:</label>
            <select
              title
              class="form-control form-group-lg form-control-solid"
              name="operator"
              formControlName="operator"
              (change)="onOperatorChange()"
            >
              <option *ngFor="let col of operatorList">
                {{col}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Output Data Type:</label>
            <select
              title
              class="form-control form-group-lg form-control-solid"
              name="outputDataType"
              formControlName="outputDataType"
            >
              <option *ngFor="let dataType of outputDataTypeList">
                {{dataType}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row margin-top-5">
          <div class="col-lg-8">
            <button type="button"
                    (click)="addArgument(enumArgumentType.Select)"
                    [disabled]="checkDisableAddArg()"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Add Select
            </button>
            <button type="button"
                    (click)="addArgument(enumArgumentType.Input)"
                    [disabled]="checkDisableAddArg()"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Add Input
            </button>
          </div>
        </div>
        <div class="form-group row margin-top-5">
          <div class="col-lg-12" *ngFor="let arg of argumentTypeList; let i = index;">
            <label>Argument {{i + 1}}:</label>
            <div class="form-group d-flex flex-row">
              <div *ngIf="arg === enumArgumentType.Select">
                <select
                  title
                  class="form-control form-group-lg form-control-solid"
                  [(ngModel)]="argumentList[i]"
                  [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="selectChangeArgDataType(i)">
                  <option value="" hidden disabled selected>Select {{placeholderList[i]}} column</option>
                  <option *ngFor="let field of filteredFieldList">
                    {{(dropZone === enumSmartQueryDropZone.DropZoneCustom)? field.newName: field.fieldName}}
                  </option>
                </select>
              </div>
              <div *ngIf="arg === enumArgumentType.Input">
                <input
                  title
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  [placeholder]="placeholderList[i]"
                  name="argument_2"
                  [(ngModel)]="argumentList[i]"
                  [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="inputChangeArgDataType(i)"
                />
              </div>

              <div>
                <a title="Delete Custom Field"
                   class="btn btn-icon btn-light xx btn-hover-secondary btn-sm mx-3"
                   [class.disabled]="argumentDelList[i]"
                   (click)="deleteArgument(i)"
                >
              <span
                [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-md secondary"
              >
              </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn xx btn-danger xx btn-elevate mr-1"
                [disabled]="checkDisableCustomButton()"
                (click)="custom()">
          Add Next Logic
        </button>
      </div>
      <div class="margin-top-5 col-lg-6">
        <div class="form-group d-flex flex-row">
          <div *ngIf="readyCustomFieldList.length != 0">
            <h5>Customized Logic</h5>
            <ul>
              <li *ngFor="let arg of readyCustomFieldList; let i = index;">
                <ng-template #popTitle><b>{{arg.operator}}-{{i}}</b></ng-template>
                <ng-template #popContent>
                  <p><b>Data Type: </b> {{arg.dataType}}</p>
                  <p><b>Operator: </b> {{arg.operator}}</p>
                  <ul>
                    <li *ngFor="let ae of arg.argElement; let j = index;">
                      <b>Argument {{j + 1}}: </b>{{ae.arg}}
                    </li>
                  </ul>
                </ng-template>
                <button type="button" class="btn xx btn-hover-secondary mr-2"
                        placement="top"
                        style="background-color: #F3F6F9 ; "
                        [ngbPopover]="popContent" [popoverTitle]="popTitle"
                >
                  {{arg.fieldName}}
                </button>
                <a title="Delete Custom Field"
                   class="btn btn-icon btn-light xx btn-hover-secondary btn-sm mx-3"
                   (click)="deleteCustomLogic(i)"
                >
              <span
                [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-md secondary"
              >
              </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">
    Cancel
  </button>&nbsp;
  <button type="submit" class="btn xx btn-danger xx btn-elevate" form="custom-field-form">
    Add
  </button>
</div>
