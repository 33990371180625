import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ParamFilterState} from "../../../shared/model-states/param.model";
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private BASE_URL = `/data-canva-api/protected/notification`;

  constructor(private http: HttpClient) {
  }

  updateFilterParams(paginator, sorting, searchTerm, filterKey, filterTerm) {
    let params = new ParamFilterState();
    params.draw = 1;
    params.start = (paginator.page - 1) * paginator.pageSize;
    params.length = paginator.pageSize;
    params.searchValue = searchTerm;
    params.filterKey = filterKey
    params.filterValue = filterTerm;
    params.orderColumn = sorting.column;
    params.orderDirection = sorting.direction;
    return params;
  }

  getNotificationDashboardList(type: string, params) {
    const url = `${this.BASE_URL}/getNotificationDashboardList`;
    params.type = type;
    // params.isDeleted = false;
    return this.http.get(url, {params});
  }

  getLatestNotificationDashboardList() {
    const url = `${this.BASE_URL}/getLatestNotificationDashboardList`;
    return this.http.get(url);
  }

  getLatestNotification() {
    const url = `${this.BASE_URL}/getLatestNotification`;
    return this.http.get(url);
  }

  countNotification() {
    const url = `${this.BASE_URL}/getNotificationCount`;
    return this.http.get(url);
  }

  getNotificationList(type: string, unreadMode: boolean, callTime: number) {
    const url = `${this.BASE_URL}/getNotificationList?type=` + type + `&unreadMode=` + unreadMode + `&callTime=` + callTime;
    return this.http.get(url);
  }

  saveNotificationSetting(notificationSettingList) {
    const url = `${this.BASE_URL}/saveNotificationSetting`;
    let formData = new FormData();
    formData.append("notificationSettingList", notificationSettingList);
    return this.http.post(url, formData);
  }

  saveNotificationSettingFromAdmin(userID, notificationSettingList) {
    const url = `${this.BASE_URL}/saveNotificationSettingFromAdmin`;
    let formData = new FormData();
    formData.append("userID", userID);
    formData.append("notificationSettingList", notificationSettingList);
    return this.http.post(url, formData);
  }

  getAppendNotificationList(type: string, callTime: number) {
    const url = `${this.BASE_URL}/getAppendNotificationList?type=` + type + `&callTime=` + callTime;
    return this.http.get(url);
  }

  getAllUnreadList(type: string) {
    const url = `${this.BASE_URL}/getAllUnreadList?type=` + type;
    return this.http.get(url);
  }

  getNotificationSetting() {
    const url = `${this.BASE_URL}/getNotificationSetting`;
    return this.http.get(url);
  }

  getNotificationSettingByUser(selectedUser: number) {
    const url = `${this.BASE_URL}/getNotificationSettingByUser?userID=${selectedUser}`;
    return this.http.get(url);
  }

  markReadNotice(mnuID: number) {
    let formData = new FormData();
    formData.append("mnuID", mnuID.toString());
    const url = `${this.BASE_URL}/markReadNotice`;
    return this.http.post(url, formData);
  }

  markReadNotices(idList) {
    const url = `${this.BASE_URL}/markReadNotices`;
    let formData = new FormData();
    formData.append("mnuIDList", idList);
    return this.http.post(url, formData);
  }

  deleteNotification(mnuID) {
    const url = `${this.BASE_URL}/deleteNotification?mnuID=` + mnuID;
    return this.http.delete(url);
  }

  deleteNotifications(idList) {
    const url = `${this.BASE_URL}/deleteNotifications`;
    let params = {"mnuIDList": idList};
    return this.http.delete(url, {params});
  }
}
