"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Convert string to Date
 *
 * @param dateInStr: string (format => 'MM/dd/yyyy')
 */
function getDateFromString(dateInStr) {
    if (dateInStr === void 0) { dateInStr = ''; }
    if (dateInStr && dateInStr.length > 0) {
        var dateParts = dateInStr.trim().split('/');
        var year = toInteger(dateParts[2]);
        var month = toInteger(dateParts[0]);
        var day = toInteger(dateParts[1]);
        // tslint:disable-next-line:prefer-const
        var result = new Date();
        result.setDate(day);
        result.setMonth(month - 1);
        result.setFullYear(year);
        return result;
    }
    return new Date();
}
exports.getDateFromString = getDateFromString;
/**
 * Covert value to number
 *
 * @param value: any
 */
function toInteger(value) {
    return parseInt("" + value, 10);
}
exports.toInteger = toInteger;
