<div class="modal-header">
  <div class="modal-title h4">Set Filemask</div>
</div>
<div class="modal-body">
  <form id="filemask-form" class="form" [formGroup]="formGroup" (ngSubmit)="save()">
    <div class="row">
      <div class="col-lg-12 margin-bottom-10">
        <label>Filemask</label>
        <input
          type="text"
          class="form-control form-control-lg form-control-solid"
          autocomplete="on"
          name="Filemask"
          [(ngModel)]="filemask"
          [ngModelOptions]="{standalone: true}"
          disabled
        />
      </div>
      <div class="margin-top-5 col-lg-6 border-right">
        <div class="form-group row margin-top-5">
          <div class="col-lg-6">
            <label>Expression Type:</label>
          </div>
          <div class="col-lg-12">
            <button type="button"
                    (click)="addExpression(enumFileMask.prefix)"
                    [disabled]="isPrefix || isSuffix"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Prefix
            </button>
            <button type="button"
                    (click)="addExpression(enumFileMask.character)"
                    [disabled]="isSuffix"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Character
            </button>
            <button type="button"
                    [disabled]="isSerialNo || isSuffix"
                    (click)="addExpression(enumFileMask.serialNo)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Serial No.
            </button>
            <button type="button"
                    (click)="addExpression(enumFileMask.suffix)"
                    [disabled]="isSuffix"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Suffix
            </button>
          </div>
        </div>
        <div  class="form-group row margin-top-5" [ngSwitch]=expression>
          <div class="col-lg-12 margin-top-5" *ngSwitchCase="enumFileMask.prefix">
            <label>Prefix:</label>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              placeholder="Prefix Input"
              name="prefixInput"
              formControlName="prefixInput"
              [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'prefixInput')"
              [class.is-valid]="formControlHelper.isControlValid(formGroup, 'prefixInput')"
            />
            <div class="valid-feedback" *ngIf="formControlHelper.isControlValid(formGroup, 'prefixInput')">
              Prefix Input was entered correct
            </div>
            <div
              class="invalid-feedback"
              *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'prefixInput')"
            >
              Prefix Input is required
            </div>
            <div
              class="invalid-feedback"
              *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'prefixInvalid', 'prefixInput')"
            >
              Prefix Input should not include <>:"/\|?* characters
            </div>
          </div>
          <div class="col-lg-12 margin-top-5" *ngSwitchCase="enumFileMask.character">
            <label>Character:</label>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              placeholder="Character"
              name="character"
              formControlName="character"
              [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'character')"
              [class.is-valid]="formControlHelper.isControlValid(formGroup, 'character')"
            />
            <div class="valid-feedback" *ngIf="formControlHelper.isControlValid(formGroup, 'character')">
              Character was entered correct
            </div>
            <div
              class="invalid-feedback"
              *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'character')"
            >
              Character is required
            </div>
            <div
              class="invalid-feedback"
              *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'separatorInvalid', 'character')"
            >
              Character should not include <>:"/\|?* characters
            </div>
          </div>
          <div class="col-lg-12 margin-top-5" *ngSwitchCase="enumFileMask.serialNo">
            <label>Serial Number Length:</label>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              placeholder="Serial No."
              name="serialNumLength"
              formControlName="serialNumLength"
              [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'serialNumLength')"
              [class.is-valid]="formControlHelper.isControlValid(formGroup, 'serialNumLength')"
            />
            <div class="valid-feedback" *ngIf="formControlHelper.isControlValid(formGroup, 'serialNumLength')">
              Serial Number Length was entered correct
            </div>
            <div
              class="invalid-feedback"
              *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'serialNumLength')"
            >
              Serial Number Length is required
            </div>
            <div
              class="invalid-feedback"
              *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'serialLengthInvalid', 'serialNumLength')"
            >
              Serial Number Length should not exceed 20 characters
            </div>
          </div>
          <div class="col-lg-12 margin-top-5" *ngSwitchCase="enumFileMask.suffix">
            <label>Suffix:</label>
            <select class="form-control form-group-lg form-control-solid"
                    formControlName="suffix"
                    [class.is-invalid]="formControlHelper.isControlInvalidWithoutSubmit(formGroup, 'suffix')"
                    [class.is-valid]="formControlHelper.isControlValid(formGroup, 'suffix')">
              <option>.csv</option>
              <option>.tsv</option>
              <option>.txt</option>
            </select>
            <div
              class="invalid-feedback"
              *ngIf="formControlHelper.controlHasErrorWithoutSubmit(formGroup,'required', 'suffix')"
            >
              Suffix is required
            </div>
          </div>
        </div>
        <div class="form-group row margin-top-5">
          <div class="col-lg-12 margin-top-5">
            <button type="button" class="btn xx btn-danger xx btn-elevate mr-1"
                    (click)="add()">
              Add
            </button>
          </div>
        </div>
        <div class="form-group row margin-top-5">
          <div class="col-lg-6">
            <label>Date Mask:</label>
          </div>
          <div class="col-lg-12">
            <button type="button"
                    (click)="addDate(enumDateMask.YYYY)"
                    [disabled]="isSuffix || checkDisableDate(enumDateMask.YYYY)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>YYYY
            </button>
            <button type="button"
                    (click)="addDate(enumDateMask.YY)"
                    [disabled]="isSuffix || checkDisableDate(enumDateMask.YY)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>YY
            </button>
            <button type="button"
                    (click)="addDate(enumDateMask.MM)"
                    [disabled]="isSuffix || checkDisableDate(enumDateMask.MM)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>MM
            </button>
            <button type="button"
                    (click)="addDate(enumDateMask.DD)"
                    [disabled]="isSuffix || checkDisableDate(enumDateMask.DD)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>DD
            </button>
            <button type="button"
                    (click)="addDate(enumDateMask.HH)"
                    [disabled]="isSuffix || checkDisableDate(enumDateMask.HH)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>HH
            </button>
            <button type="button"
                    (click)="addDate(enumDateMask.mm)"
                    [disabled]="isSuffix || checkDisableDate(enumDateMask.mm)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>mm
            </button>
            <button type="button"
                    (click)="addDate(enumDateMask.ss)"
                    [disabled]="isSuffix || checkDisableDate(enumDateMask.ss)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>ss
            </button>
            <button type="button"
                    (click)="addDate(enumDateMask.timestamp10)"
                    [disabled]="isSuffix || checkDisableDate(enumDateMask.timestamp10)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Time Stamp (10 digits)
            </button>
            <button type="button"
                    (click)="addDate(enumDateMask.timestamp13)"
                    [disabled]="isSuffix || checkDisableDate(enumDateMask.timestamp13)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
            >
              <i class="fa btn-list-add"></i>Time Stamp (13 digits)
            </button>
          </div>
        </div>
      </div>
      <div class="margin-top-5 col-lg-6">
        <div class="form-group row margin-top-5">
          <div class="col-lg-6" *ngIf="fileMaskSelectList && fileMaskSelectList.length != 0">
            <label>File Mask Select List:</label>
            <ul>
              <li *ngFor="let fmBean of fileMaskSelectList; let i = index;">
                {{fmBean.value}}
                <a title="Delete custom field"
                   class="btn btn-icon btn-light xx btn-hover-secondary btn-sm mx-3"
                   (click)="deleteArg(i)"
                >
                  <span
                    [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                    cacheSVG="true"
                    class="svg-icon svg-icon-md secondary"
                  >
                  </span>
                </a>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">
    Cancel
  </button>&nbsp;
  <button type="submit" class="btn xx btn-danger xx btn-elevate" form="filemask-form">
    Save
  </button>
</div>
