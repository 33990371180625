"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumDataType;
(function (EnumDataType) {
    EnumDataType["Int"] = "INT";
    EnumDataType["Long"] = "LONG";
    EnumDataType["Float"] = "FLOAT";
    EnumDataType["Double"] = "DOUBLE";
    EnumDataType["String"] = "STRING";
    EnumDataType["Boolean"] = "BOOLEAN";
    EnumDataType["DateDate"] = "DATE.DATE";
    EnumDataType["DateDatetime"] = "DATE.DATETIME";
    EnumDataType["DateTimestamp"] = "DATE.TIMESTAMP";
    EnumDataType["Categorical"] = "CATEGORICAL";
})(EnumDataType = exports.EnumDataType || (exports.EnumDataType = {}));
var EnumBooleanValue;
(function (EnumBooleanValue) {
    EnumBooleanValue["False"] = "false";
    EnumBooleanValue["True"] = "true";
})(EnumBooleanValue = exports.EnumBooleanValue || (exports.EnumBooleanValue = {}));
var EnumBooleanOperator;
(function (EnumBooleanOperator) {
    EnumBooleanOperator["Is"] = "$eq";
})(EnumBooleanOperator = exports.EnumBooleanOperator || (exports.EnumBooleanOperator = {}));
var EnumBooleanViewOperator;
(function (EnumBooleanViewOperator) {
    EnumBooleanViewOperator["Is"] = "is (for boolean)";
})(EnumBooleanViewOperator = exports.EnumBooleanViewOperator || (exports.EnumBooleanViewOperator = {}));
var EnumNumOperator;
(function (EnumNumOperator) {
    EnumNumOperator["Gte"] = "$gte";
    EnumNumOperator["Gt"] = "$gt";
    EnumNumOperator["Lte"] = "$lte";
    EnumNumOperator["Lt"] = "$lt";
    EnumNumOperator["Eq"] = "$eq";
    EnumNumOperator["Ne"] = "$ne";
})(EnumNumOperator = exports.EnumNumOperator || (exports.EnumNumOperator = {}));
var EnumNumViewOperator;
(function (EnumNumViewOperator) {
    EnumNumViewOperator["Gte"] = ">=";
    EnumNumViewOperator["Gt"] = ">";
    EnumNumViewOperator["Lte"] = "<=";
    EnumNumViewOperator["Lt"] = "<";
    EnumNumViewOperator["Eq"] = "==";
    EnumNumViewOperator["Ne"] = "!=";
})(EnumNumViewOperator = exports.EnumNumViewOperator || (exports.EnumNumViewOperator = {}));
var EnumCategoricalOperator;
(function (EnumCategoricalOperator) {
    EnumCategoricalOperator["Eq"] = "$eq";
    EnumCategoricalOperator["Ne"] = "$ne";
})(EnumCategoricalOperator = exports.EnumCategoricalOperator || (exports.EnumCategoricalOperator = {}));
var EnumCategoricalViewOperator;
(function (EnumCategoricalViewOperator) {
    EnumCategoricalViewOperator["Eq"] = "equal";
    EnumCategoricalViewOperator["Ne"] = "not equal";
})(EnumCategoricalViewOperator = exports.EnumCategoricalViewOperator || (exports.EnumCategoricalViewOperator = {}));
var EnumStrOperator;
(function (EnumStrOperator) {
    EnumStrOperator["Eq"] = "$eq";
    EnumStrOperator["Ne"] = "$ne";
    EnumStrOperator["StartWith"] = "$startWith";
    EnumStrOperator["EndWith"] = "$endWith";
    EnumStrOperator["Contains"] = "$contains";
    EnumStrOperator["NotContains"] = "$notContains";
    EnumStrOperator["PatternMatch"] = "$patternMatch";
    EnumStrOperator["In"] = "$in";
    EnumStrOperator["NotIn"] = "$nin";
})(EnumStrOperator = exports.EnumStrOperator || (exports.EnumStrOperator = {}));
var EnumStrViewOperator;
(function (EnumStrViewOperator) {
    EnumStrViewOperator["Eq"] = "equal";
    EnumStrViewOperator["Ne"] = "not equal";
    EnumStrViewOperator["StartWith"] = "start with";
    EnumStrViewOperator["EndWith"] = "end with";
    EnumStrViewOperator["Contains"] = "contains";
    EnumStrViewOperator["NotContains"] = "not contains";
    EnumStrViewOperator["PatternMatch"] = "pattern match";
    EnumStrViewOperator["In"] = "in";
    EnumStrViewOperator["NotIn"] = "not in";
})(EnumStrViewOperator = exports.EnumStrViewOperator || (exports.EnumStrViewOperator = {}));
var EnumDateOperator;
(function (EnumDateOperator) {
    EnumDateOperator["LastDate"] = "LAST_DATE";
})(EnumDateOperator = exports.EnumDateOperator || (exports.EnumDateOperator = {}));
var EnumDateViewOperator;
(function (EnumDateViewOperator) {
    EnumDateViewOperator["LastDate"] = "last date";
})(EnumDateViewOperator = exports.EnumDateViewOperator || (exports.EnumDateViewOperator = {}));
var EnumAggregateOperator;
(function (EnumAggregateOperator) {
    EnumAggregateOperator["Min"] = "$min";
    EnumAggregateOperator["Max"] = "$max";
    EnumAggregateOperator["Avg"] = "$avg";
    EnumAggregateOperator["Sum"] = "$sum";
    EnumAggregateOperator["Count"] = "$count";
    EnumAggregateOperator["CountDistinct"] = "$countDistinct";
    EnumAggregateOperator["MinIf"] = "$minIf";
    EnumAggregateOperator["MaxIf"] = "$maxIf";
    EnumAggregateOperator["AvgIf"] = "$avgIf";
    EnumAggregateOperator["SumIf"] = "$sumIf";
    EnumAggregateOperator["CountIf"] = "$countIf";
    EnumAggregateOperator["CountDistinctIf"] = "$countDistinctIf";
})(EnumAggregateOperator = exports.EnumAggregateOperator || (exports.EnumAggregateOperator = {}));
var EnumNullOperator;
(function (EnumNullOperator) {
    EnumNullOperator["Is"] = "$isNull";
    EnumNullOperator["IsNot"] = "$notNull";
})(EnumNullOperator = exports.EnumNullOperator || (exports.EnumNullOperator = {}));
var EnumNullViewOperator;
(function (EnumNullViewOperator) {
    EnumNullViewOperator["Is"] = "is";
    EnumNullViewOperator["IsNot"] = "is not";
})(EnumNullViewOperator = exports.EnumNullViewOperator || (exports.EnumNullViewOperator = {}));
var EnumDateMode;
(function (EnumDateMode) {
    EnumDateMode["DatePicker"] = "DATEPICKER";
    EnumDateMode["YearOf"] = "YEAR OF";
    EnumDateMode["MonthOf"] = "MONTH OF";
    EnumDateMode["DayOf"] = "DAY OF";
    EnumDateMode["HourOf"] = "HOUR OF";
    EnumDateMode["MinuteOf"] = "MINUTE OF";
    EnumDateMode["SecondOf"] = "SECOND OF";
})(EnumDateMode = exports.EnumDateMode || (exports.EnumDateMode = {}));
var EnumDateUnit;
(function (EnumDateUnit) {
    EnumDateUnit["Year"] = "YEAR";
    EnumDateUnit["Month"] = "MONTH";
    EnumDateUnit["Day"] = "DAY";
    EnumDateUnit["Hour"] = "HOUR";
    EnumDateUnit["Minute"] = "MINUTE";
    EnumDateUnit["Second"] = "SECOND";
})(EnumDateUnit = exports.EnumDateUnit || (exports.EnumDateUnit = {}));
var EnumNull;
(function (EnumNull) {
    EnumNull["Null"] = "null";
})(EnumNull = exports.EnumNull || (exports.EnumNull = {}));
var EnumCustomFieldExpressType;
(function (EnumCustomFieldExpressType) {
    EnumCustomFieldExpressType["Arithmetic"] = "ARITHMETIC";
    EnumCustomFieldExpressType["Boolean"] = "BOOLEAN";
    EnumCustomFieldExpressType["Comparison"] = "COMPARISON";
    EnumCustomFieldExpressType["Constant"] = "CONSTANT";
    EnumCustomFieldExpressType["Condition"] = "CONDITION";
    EnumCustomFieldExpressType["Date"] = "DATE";
    EnumCustomFieldExpressType["String"] = "STRING";
    EnumCustomFieldExpressType["Type"] = "TYPE";
})(EnumCustomFieldExpressType = exports.EnumCustomFieldExpressType || (exports.EnumCustomFieldExpressType = {}));
var EnumCustomFieldNumericalOperator;
(function (EnumCustomFieldNumericalOperator) {
    EnumCustomFieldNumericalOperator["Absolute"] = "ABS";
    EnumCustomFieldNumericalOperator["Add"] = "ADD";
    EnumCustomFieldNumericalOperator["Acos"] = "ACOS";
    EnumCustomFieldNumericalOperator["Asin"] = "ASIN";
    EnumCustomFieldNumericalOperator["Atan"] = "ATAN";
    EnumCustomFieldNumericalOperator["Ceiling"] = "CEILING";
    EnumCustomFieldNumericalOperator["Cosine"] = "COSINE";
    EnumCustomFieldNumericalOperator["Divide"] = "DIVIDE";
    EnumCustomFieldNumericalOperator["Exponential"] = "EXPONENTIAL";
    EnumCustomFieldNumericalOperator["Floor"] = "FLOOR";
    EnumCustomFieldNumericalOperator["Natural_log"] = "NATURAL_LOG";
    EnumCustomFieldNumericalOperator["Log"] = "LOG";
    EnumCustomFieldNumericalOperator["Log10"] = "LOG10";
    EnumCustomFieldNumericalOperator["Mode"] = "MODE";
    EnumCustomFieldNumericalOperator["Multiply"] = "MULTIPLY";
    EnumCustomFieldNumericalOperator["Power"] = "POWER";
    EnumCustomFieldNumericalOperator["Round"] = "ROUND";
    EnumCustomFieldNumericalOperator["Sine"] = "SINE";
    EnumCustomFieldNumericalOperator["Square"] = "SQUARE";
    EnumCustomFieldNumericalOperator["Subtract"] = "SUBTRACT";
    EnumCustomFieldNumericalOperator["Tangent"] = "TANGENT";
    EnumCustomFieldNumericalOperator["Truncate"] = "TRUNCATE";
})(EnumCustomFieldNumericalOperator = exports.EnumCustomFieldNumericalOperator || (exports.EnumCustomFieldNumericalOperator = {}));
var EnumCustomFieldStrOperator;
(function (EnumCustomFieldStrOperator) {
    EnumCustomFieldStrOperator["Concat"] = "CONCAT";
    EnumCustomFieldStrOperator["IndexOfCP"] = "INDEX_OF_CP";
    EnumCustomFieldStrOperator["StrLenCP"] = "STR_LEN_CP";
    EnumCustomFieldStrOperator["ToLower"] = "TO_LOWER";
    EnumCustomFieldStrOperator["ToUpper"] = "TO_UPPER";
    EnumCustomFieldStrOperator["R_PAD"] = "R_PAD";
    EnumCustomFieldStrOperator["L_PAD"] = "L_PAD";
    EnumCustomFieldStrOperator["LTrim"] = "L_TRIM";
    EnumCustomFieldStrOperator["RTrim"] = "R_TRIM";
    EnumCustomFieldStrOperator["Trim"] = "TRIM";
    EnumCustomFieldStrOperator["ReplaceOne"] = "REPLACE_ONE";
    EnumCustomFieldStrOperator["Substrcp"] = "SUB_STR_CP";
    EnumCustomFieldStrOperator["Substr"] = "SUB_STR";
    EnumCustomFieldStrOperator["DateFromString"] = "DATE_FROM_STR";
    EnumCustomFieldStrOperator["ReplaceAll"] = "REPLACE_ALL";
    //DataPipeline Only (Smart Query not provide)
    // DateToString = 'DATE_TO_STRING',
})(EnumCustomFieldStrOperator = exports.EnumCustomFieldStrOperator || (exports.EnumCustomFieldStrOperator = {}));
var EnumCustomFieldDateOperator;
(function (EnumCustomFieldDateOperator) {
    EnumCustomFieldDateOperator["DayOfYear"] = "DAY_OF_YEAR";
    EnumCustomFieldDateOperator["DayOfMonth"] = "DAY_OF_MONTH";
    EnumCustomFieldDateOperator["DayOfWeek"] = "DAY_OF_WEEK";
    EnumCustomFieldDateOperator["Year"] = "YEAR";
    EnumCustomFieldDateOperator["Month"] = "MONTH";
    EnumCustomFieldDateOperator["Week"] = "WEEK";
    EnumCustomFieldDateOperator["Hour"] = "HOUR";
    EnumCustomFieldDateOperator["Minute"] = "MINUTE";
    EnumCustomFieldDateOperator["Second"] = "SECOND";
    EnumCustomFieldDateOperator["Millisecond"] = "MILLISECOND";
    EnumCustomFieldDateOperator["DateToString"] = "DATE_TO_STRING";
    EnumCustomFieldDateOperator["Add"] = "ADD_MILLISECONDS";
    EnumCustomFieldDateOperator["DateAdd"] = "DATE_ADD";
    EnumCustomFieldDateOperator["Now"] = "CURRENT_DATE_TIME";
    EnumCustomFieldDateOperator["DateDiff"] = "DATE_DIFF";
})(EnumCustomFieldDateOperator = exports.EnumCustomFieldDateOperator || (exports.EnumCustomFieldDateOperator = {}));
var EnumCustomFieldComparisonOperator;
(function (EnumCustomFieldComparisonOperator) {
    // Compare = "COMPARE",
    EnumCustomFieldComparisonOperator["Equal"] = "EQUAL";
    EnumCustomFieldComparisonOperator["Greater"] = "GREATER";
    EnumCustomFieldComparisonOperator["GreaterOrEqual"] = "GREATER_OR_EQUAL";
    EnumCustomFieldComparisonOperator["Less"] = "LESS";
    EnumCustomFieldComparisonOperator["LessOrEqual"] = "LESS_OR_EQUAL";
    EnumCustomFieldComparisonOperator["NotEqual"] = "NOT_EQUAL";
    EnumCustomFieldComparisonOperator["IsNull"] = "IS_NULL";
    EnumCustomFieldComparisonOperator["NotNull"] = "NOT_NULL";
})(EnumCustomFieldComparisonOperator = exports.EnumCustomFieldComparisonOperator || (exports.EnumCustomFieldComparisonOperator = {}));
var EnumCustomFieldBooleanOperator;
(function (EnumCustomFieldBooleanOperator) {
    EnumCustomFieldBooleanOperator["And"] = "AND";
    EnumCustomFieldBooleanOperator["Or"] = "OR";
    EnumCustomFieldBooleanOperator["Not"] = "NOT";
})(EnumCustomFieldBooleanOperator = exports.EnumCustomFieldBooleanOperator || (exports.EnumCustomFieldBooleanOperator = {}));
var EnumCustomFieldConditionOperator;
(function (EnumCustomFieldConditionOperator) {
    EnumCustomFieldConditionOperator["IfNull"] = "IF_NULL";
    EnumCustomFieldConditionOperator["Iif"] = "IIF";
})(EnumCustomFieldConditionOperator = exports.EnumCustomFieldConditionOperator || (exports.EnumCustomFieldConditionOperator = {}));
var EnumCustomFieldTypeOperator;
(function (EnumCustomFieldTypeOperator) {
    EnumCustomFieldTypeOperator["Convert"] = "CONVERT";
})(EnumCustomFieldTypeOperator = exports.EnumCustomFieldTypeOperator || (exports.EnumCustomFieldTypeOperator = {}));
var EnumCustomFieldConstantOperator;
(function (EnumCustomFieldConstantOperator) {
    EnumCustomFieldConstantOperator["Is"] = "IS";
})(EnumCustomFieldConstantOperator = exports.EnumCustomFieldConstantOperator || (exports.EnumCustomFieldConstantOperator = {}));
