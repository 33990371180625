"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var SplashRoutingService = /** @class */ (function () {
    function SplashRoutingService() {
        this.splashShowChange = new rxjs_1.Subject();
    }
    SplashRoutingService.prototype.showSplashRouting = function (isShow) {
        this.splashShowChange.next(isShow);
    };
    SplashRoutingService.ngInjectableDef = i0.defineInjectable({ factory: function SplashRoutingService_Factory() { return new SplashRoutingService(); }, token: SplashRoutingService, providedIn: "root" });
    return SplashRoutingService;
}());
exports.SplashRoutingService = SplashRoutingService;
