"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["#splash-routing[_ngcontent-%COMP%]{position:fixed;z-index:10000;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;background-color:#f2f3f8;opacity:.5}.splash-spinner[_ngcontent-%COMP%]{-webkit-animation:2s linear infinite rotate;animation:2s linear infinite rotate;margin-left:calc(100vw - 100%);width:100px;height:100px}.splash-spinner[_ngcontent-%COMP%]   #spinner-circle[_ngcontent-%COMP%]{fill:none;stroke-width:5;stroke:#e74340;stroke-linecap:round;stroke-dasharray:90,100}@-webkit-keyframes rotate{100%{transform:rotate(360deg)}}@keyframes rotate{100%{transform:rotate(360deg)}}"];
exports.styles = styles;
