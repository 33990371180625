export interface ICustomFieldState {
  unlimitedArgNum: boolean;
  minArgNum: number;
  maxArgNum: number;
  restrictedFirstArgType: boolean;
  argType: Array<string>;
  outputDataTypeChoice: Array<string>;
  placeholder: Array<string>;
}

export class CustomFieldState implements ICustomFieldState {
  unlimitedArgNum = false;
  minArgNum = 1;
  maxArgNum = 1;
  restrictedFirstArgType = false;
  argType: Array<string> = new Array<string>();
  outputDataTypeChoice = new Array<string>();
  placeholder = new Array<string>();
}
