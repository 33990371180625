import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ScheduleInfo {

  getCronTabMinuteField() {
    return [
      {
        param: "0-59",
        description: "allowed values; 0 is the top of the hour"
      },
      {
        param: "*",
        description: "first-last (every minute)"
      },
      {
        param: ",",
        description: "a list of minutes; ie. 0,30 would be the 0 AND 30th minutes"
      },
      {
        param: "-",
        description: "a range of minutes; ie. 0-5 would be minutes 0, 1, 2, 3, 4, and 5 (you can also specify a list of ranges 0-5,30-35)"
      },
      {
        param: "/",
        description: "step values will skip the specified number within a range; ie */5 is every 5 minutes, and 0-30/2 is every 2 minutes between 0 and 30 minutes"
      }
    ];
  }

  getCronTabHourField() {
    return [
      {
        param: "0-59",
        description: "allowed values; 0 is the top of the hour"
      },
      {
        param: "*",
        description: "first-last (every hour)"
      },
      {
        param: ",",
        description: "a list of hours; ie. 0,12 would be the 0 AND 12th hours"
      },
      {
        param: "-",
        description: "a range of minutes; ie. 19-23 would be hours 19, 20, 21, 22, 23(you can also specify a list of ranges 0-5,12-16)"
      },
      {
        param: "/",
        description: "step values will skip the specified number within a range; ie */3 is every 3 hours, and 0-10/2 is every 2 hours between 0 and 10th hour"
      }
    ]
  }

  getCronTabDayOfMonthField() {
    return [
      {
        param: "1-31",
        description: "allowed values"
      },
      {
        param: "*",
        description: "first-last (every day of the month)"
      },
      {
        param: ",",
        description: "a list of days; ie. 2,16 would be the 2nd AND 16th day of the month"
      },
      {
        param: "-",
        description: "a range of days; ie. 2-6 would be days 2, 3, 4, 5, 6(you can also specify a list of ranges 2-8,15-30)"
      },
      {
        param: "/",
        description: "step values will skip the specified number within a range; ie */3 is every 3 days, and 1-10/2 is every 2 days between 1st and the 10th day of the month"
      }
    ]
  }

  getCronTabMonthField() {
    return [
      {
        param: "1-12",
        description: "allowed values"
      },
      {
        param: "*",
        description: "first-last (every month)"
      },
      {
        param: ",",
        description: "a list of months; ie. 2,6 would be the January AND June"
      },
      {
        param: "-",
        description: "a range of months; ie. 2-4 would be February, March and April (you can also specify a list of ranges 2-5,8-10)"
      },
      {
        param: "/",
        description: "step values will skip the specified number within a range; ie */3 is every 3 months, and 1-10/2 is every 2 months between January and October"
      }
    ]
  }

  getCronTabDayOfWeekField() {
    return [
      {
        param: "0-6",
        description: "allowed values; 0=Sunday, 1=Monday, 2=Tuesday, 3=Wednesday, 4=Thursday, 5=Friday, 6=Saturday"
      },
      {
        param: "*",
        description: "first-last (every day of the week)"
      },
      {
        param: ",",
        description: "a list of days; ie. 2,6 would be the Tuesday and Saturday"
      },
      {
        param: "-",
        description: "a range of days; ie. 2-4 would be Tuesday, Wednesday and Thursday (you can also specify a list of ranges 0-3, 5-6)"
      },
      {
        param: "/",
        description: "step values will skip the specified number within a range; ie */3 is every 3 days, and 1-5/2 is every 2 days between Monday and Friday"
      }
    ]
  }
}
