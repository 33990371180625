import {SweetAlertService} from './sweet-alert.service';
import {Router} from '@angular/router';
import {BreadCrumbService} from './bread-crumb.service';
import {Injectable} from '@angular/core';

declare var $: any;

@Injectable()
export class DataTableAjaxService {

    constructor(private router: Router,
                private sweetAlertService: SweetAlertService) {
    }


    ajaxReload(id: String, url: String) {
        $('#' + id).DataTable().ajax.url(url).load();
    }


    statusCodeHandle() {
        return {
            401: () => {
                this.sweetAlertService.sessionTimeout(() => {
                    this.router.navigate([BreadCrumbService.login().href]);
                });
            },
            500: () => {
                this.sweetAlertService.serverError();
            }
        };
    }


    errorHandling(xhr) {
      // if (xhr.status === 500) {
      //     this.sweetAlertService.serverError();
      // } else if (xhr.status === 401) {
      //     this.sweetAlertService.sessionTimeout(() => {
      //         this.router.navigate([BreadCrumbService.login().href]);
      //     });
      // }
    }
}
