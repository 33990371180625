"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var router_1 = require("@angular/router");
var Subject_1 = require("rxjs/Subject");
var route_name_enum_1 = require("../enums/route-name.enum");
var BreadCrumbService = /** @class */ (function () {
    function BreadCrumbService() {
    }
    BreadCrumbService.refreshBreadCrumbs = function (activatedRoute) {
        var breadcrumbs = [];
        var pathParams = {};
        var queryParams = {};
        var children = activatedRoute.children;
        // return if there are no more children
        if (children.length === 0) {
            return;
        }
        var child = activatedRoute.firstChild;
        // iterate over each children
        while (child != undefined) {
            if (child.outlet == router_1.PRIMARY_OUTLET && child.snapshot.data.hasOwnProperty('breadcrumb')) {
                // add breadcrumb
                breadcrumbs.push(child.snapshot.data.breadcrumb);
            }
            if (child.snapshot.params !== undefined) {
                // pathParams = child.snapshot.params;
                pathParams = tslib_1.__assign({}, pathParams, child.snapshot.params);
                queryParams = tslib_1.__assign({}, queryParams, child.snapshot.queryParams);
            }
            child = child.firstChild;
        }
        this.pathParams = pathParams;
        this.queryParams = queryParams;
        this.subjectBreadCrumbs.next(breadcrumbs);
    };
    BreadCrumbService.login = function () {
        var _this = this;
        return {
            title: 'Login',
            text: '',
            href: '/auth/login',
            path: 'login',
            customBreadCrumbs: function () {
                return [
                    _this.login()
                ];
            }
        };
    };
    BreadCrumbService.dashboard = function () {
        var _this = this;
        return {
            route: route_name_enum_1.EnumRouteName.Dashboard,
            title: 'Dashboard',
            text: 'Dashboard',
            href: '/dashboard',
            path: 'dashboard',
            customBreadCrumbs: function () {
                return [
                    _this.dashboard()
                ];
            }
        };
    };
    BreadCrumbService.test = function () {
        var _this = this;
        return {
            route: route_name_enum_1.EnumRouteName.Test,
            title: 'Test',
            text: 'Test',
            href: '/test',
            path: 'test',
            customBreadCrumbs: function () {
                return [
                    _this.test()
                ];
            }
        };
    };
    BreadCrumbService.dataApi = function (activatedRoute) {
        var _this = this;
        var dataEntityID = undefined;
        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }
        return {
            route: route_name_enum_1.EnumRouteName.DataApi,
            title: 'Edit Api Setting',
            text: 'Edit Api Setting',
            href: '/data-api/' + dataEntityID,
            path: 'data-api: dataEntityID',
            customBreadCrumbs: function (activatedRoute) {
                return [
                    _this.dataApi(activatedRoute)
                ];
            }
        };
    };
    BreadCrumbService.dataWarehouse = function () {
        var _this = this;
        return {
            route: route_name_enum_1.EnumRouteName.DataWarehouse,
            title: 'Data Warehouse',
            text: 'Data Warehouse',
            href: '/dataWarehouse',
            path: 'dataWarehouse',
            customBreadCrumbs: function () {
                return [
                    _this.dataWarehouse()
                ];
            }
        };
    };
    BreadCrumbService.dataEntity = function () {
        var _this = this;
        return {
            route: route_name_enum_1.EnumRouteName.DataEntity,
            title: 'Create New Entity',
            text: 'Create New Entity',
            href: '/dataEntity',
            path: 'dataEntity',
            customBreadCrumbs: function () {
                return [
                    _this.dataWarehouse()
                ];
            }
        };
    };
    BreadCrumbService.dataSource = function (activatedRoute) {
        var _this = this;
        var dataEntityID = undefined;
        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }
        return {
            route: route_name_enum_1.EnumRouteName.DataSource,
            title: 'Create New Entity',
            text: 'Create New Entity',
            href: '/dataSource/' + dataEntityID,
            path: 'dataSource:dataEntityID',
            customBreadCrumbs: function (activatedRoute) {
                return [
                    _this.dataSource(activatedRoute)
                ];
            }
        };
    };
    BreadCrumbService.dataSchema = function (activatedRoute) {
        var _this = this;
        var dataEntityID = undefined;
        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }
        return {
            route: route_name_enum_1.EnumRouteName.DataSource,
            title: 'Create New Schema',
            text: 'Create New Schema',
            href: '/dataSchema' + dataEntityID,
            path: 'dataSchema:dataEntityID',
            customBreadCrumbs: function (activatedRoute) {
                return [
                    _this.dataSchema(activatedRoute)
                ];
            }
        };
    };
    BreadCrumbService.dataView = function (activatedRoute) {
        var _this = this;
        var dataEntityID = undefined;
        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }
        return {
            route: route_name_enum_1.EnumRouteName.DataView,
            title: 'Data View',
            text: 'Data View',
            href: '/dataView/' + dataEntityID,
            path: 'dataView:dataEntityID',
            customBreadCrumbs: function (activatedRoute) {
                return [
                    _this.dataView(activatedRoute)
                ];
            }
        };
    };
    BreadCrumbService.entityHistory = function (activatedRoute) {
        var _this = this;
        var dataEntityID = undefined;
        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }
        return {
            route: route_name_enum_1.EnumRouteName.EntityHistory,
            title: 'Entity History',
            text: 'Entity History',
            href: '/entity-history/' + dataEntityID,
            path: 'entity-history: dataEntityID',
            customBreadCrumbs: function (activatedRoute) {
                return [
                    _this.entityHistory(activatedRoute)
                ];
            }
        };
    };
    BreadCrumbService.fileHistory = function (activatedRoute) {
        var _this = this;
        var dataEntityID = undefined;
        if (activatedRoute !== undefined) {
            dataEntityID = this.pathParams['dataEntityID'];
        }
        return {
            route: route_name_enum_1.EnumRouteName.FileHistory,
            title: 'File History',
            text: 'File History',
            href: '/fileHistory/' + dataEntityID,
            path: 'fileHistory: dataEntityID',
            customBreadCrumbs: function (activatedRoute) {
                return [
                    _this.fileHistory(activatedRoute)
                ];
            }
        };
    };
    BreadCrumbService.dataPipelineManagement = function () {
        var _this = this;
        return {
            route: route_name_enum_1.EnumRouteName.DataPipelineManagement,
            title: 'Data Pipeline',
            text: 'Data Pipeline Management',
            href: '/data-pipeline-management',
            path: 'data-pipeline-management',
            customBreadCrumbs: function () {
                return [
                    _this.dataPipelineManagement()
                ];
            }
        };
    };
    BreadCrumbService.dataPipelineEditing = function (activatedRoute) {
        var _this = this;
        return {
            route: route_name_enum_1.EnumRouteName.DataPipelineEditing,
            title: 'Data Pipeline Editing',
            text: 'Data Pipeline Editing',
            href: '/data-pipeline-editing',
            path: 'data-pipeline-editing',
            customBreadCrumbs: function () {
                return [
                    _this.dataPipelineManagement(),
                    _this.dataPipelineEditing(activatedRoute)
                ];
            }
        };
    };
    BreadCrumbService.getBreadCrumbsObservable = function () {
        return this.subjectBreadCrumbs.asObservable();
    };
    BreadCrumbService.subjectBreadCrumbs = new Subject_1.Subject();
    return BreadCrumbService;
}());
exports.BreadCrumbService = BreadCrumbService;
