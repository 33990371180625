"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var param_model_1 = require("../../../shared/model-states/param.model");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var NotificationService = /** @class */ (function () {
    function NotificationService(http) {
        this.http = http;
        this.BASE_URL = "/data-canva-api/protected/notification";
    }
    NotificationService.prototype.updateFilterParams = function (paginator, sorting, searchTerm, filterKey, filterTerm) {
        var params = new param_model_1.ParamFilterState();
        params.draw = 1;
        params.start = (paginator.page - 1) * paginator.pageSize;
        params.length = paginator.pageSize;
        params.searchValue = searchTerm;
        params.filterKey = filterKey;
        params.filterValue = filterTerm;
        params.orderColumn = sorting.column;
        params.orderDirection = sorting.direction;
        return params;
    };
    NotificationService.prototype.getNotificationDashboardList = function (type, params) {
        var url = this.BASE_URL + "/getNotificationDashboardList";
        params.type = type;
        // params.isDeleted = false;
        return this.http.get(url, { params: params });
    };
    NotificationService.prototype.getLatestNotificationDashboardList = function () {
        var url = this.BASE_URL + "/getLatestNotificationDashboardList";
        return this.http.get(url);
    };
    NotificationService.prototype.getLatestNotification = function () {
        var url = this.BASE_URL + "/getLatestNotification";
        return this.http.get(url);
    };
    NotificationService.prototype.countNotification = function () {
        var url = this.BASE_URL + "/getNotificationCount";
        return this.http.get(url);
    };
    NotificationService.prototype.getNotificationList = function (type, unreadMode, callTime) {
        var url = this.BASE_URL + "/getNotificationList?type=" + type + "&unreadMode=" + unreadMode + "&callTime=" + callTime;
        return this.http.get(url);
    };
    NotificationService.prototype.saveNotificationSetting = function (notificationSettingList) {
        var url = this.BASE_URL + "/saveNotificationSetting";
        var formData = new FormData();
        formData.append("notificationSettingList", notificationSettingList);
        return this.http.post(url, formData);
    };
    NotificationService.prototype.saveNotificationSettingFromAdmin = function (userID, notificationSettingList) {
        var url = this.BASE_URL + "/saveNotificationSettingFromAdmin";
        var formData = new FormData();
        formData.append("userID", userID);
        formData.append("notificationSettingList", notificationSettingList);
        return this.http.post(url, formData);
    };
    NotificationService.prototype.getAppendNotificationList = function (type, callTime) {
        var url = this.BASE_URL + "/getAppendNotificationList?type=" + type + "&callTime=" + callTime;
        return this.http.get(url);
    };
    NotificationService.prototype.getAllUnreadList = function (type) {
        var url = this.BASE_URL + "/getAllUnreadList?type=" + type;
        return this.http.get(url);
    };
    NotificationService.prototype.getNotificationSetting = function () {
        var url = this.BASE_URL + "/getNotificationSetting";
        return this.http.get(url);
    };
    NotificationService.prototype.getNotificationSettingByUser = function (selectedUser) {
        var url = this.BASE_URL + "/getNotificationSettingByUser?userID=" + selectedUser;
        return this.http.get(url);
    };
    NotificationService.prototype.markReadNotice = function (mnuID) {
        var formData = new FormData();
        formData.append("mnuID", mnuID.toString());
        var url = this.BASE_URL + "/markReadNotice";
        return this.http.post(url, formData);
    };
    NotificationService.prototype.markReadNotices = function (idList) {
        var url = this.BASE_URL + "/markReadNotices";
        var formData = new FormData();
        formData.append("mnuIDList", idList);
        return this.http.post(url, formData);
    };
    NotificationService.prototype.deleteNotification = function (mnuID) {
        var url = this.BASE_URL + "/deleteNotification?mnuID=" + mnuID;
        return this.http.delete(url);
    };
    NotificationService.prototype.deleteNotifications = function (idList) {
        var url = this.BASE_URL + "/deleteNotifications";
        var params = { "mnuIDList": idList };
        return this.http.delete(url, { params: params });
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.HttpClient)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
exports.NotificationService = NotificationService;
