import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Params
} from '@angular/router';

@Injectable()
export class RouteParamsService {

    private pathParams: Params;
    private queryParams: Params;

    constructor() {
    }


    public init(activatedRouteSnapshot: ActivatedRouteSnapshot): void {

        let pathParams: Params = {};
        let queryParams: Params = {};
        let child = activatedRouteSnapshot.root.firstChild;
        // iterate over each children
        while (child != undefined) {


            if (child.params !== undefined) {
                pathParams = {...pathParams, ...child.params};
                queryParams = {...queryParams, ...child.queryParams};
            }

            child = child.firstChild;
        }

        this.pathParams = pathParams;
        this.queryParams = queryParams;
    }


    // path params, please call init function before getting any path params
    public getPathParam(key: string): string {
        return this.pathParams[key];
    }


    // query params, please call init function before getting any query params
    public getQueryParam(key: string): string {
        return this.queryParams[key];
    }
}
