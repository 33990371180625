import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SplashRoutingService {
  splashShowChange: Subject<boolean> = new Subject<boolean>();
  showSplashRouting(isShow: boolean) {
    this.splashShowChange.next(isShow);
  }
}
