"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumNotificationType;
(function (EnumNotificationType) {
    // notification type
    EnumNotificationType["Alert"] = "ALERT";
    EnumNotificationType["Event"] = "EVENT";
    EnumNotificationType["Log"] = "LOG";
})(EnumNotificationType = exports.EnumNotificationType || (exports.EnumNotificationType = {}));
var EnumNotificationRealm;
(function (EnumNotificationRealm) {
    // notification realm
    EnumNotificationRealm["DataWarehouse"] = "DATA_WAREHOUSE";
    EnumNotificationRealm["DataPipeline"] = "DATA_PIPELINE";
    EnumNotificationRealm["SmartQuery"] = "SMART_QUERY";
    EnumNotificationRealm["UserGroup"] = "USER_GROUP";
    EnumNotificationRealm["AccessControl"] = "ACCESS_CONTROL";
})(EnumNotificationRealm = exports.EnumNotificationRealm || (exports.EnumNotificationRealm = {}));
