"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var RouteParamsService = /** @class */ (function () {
    function RouteParamsService() {
    }
    RouteParamsService.prototype.init = function (activatedRouteSnapshot) {
        var pathParams = {};
        var queryParams = {};
        var child = activatedRouteSnapshot.root.firstChild;
        // iterate over each children
        while (child != undefined) {
            if (child.params !== undefined) {
                pathParams = tslib_1.__assign({}, pathParams, child.params);
                queryParams = tslib_1.__assign({}, queryParams, child.queryParams);
            }
            child = child.firstChild;
        }
        this.pathParams = pathParams;
        this.queryParams = queryParams;
    };
    // path params, please call init function before getting any path params
    RouteParamsService.prototype.getPathParam = function (key) {
        return this.pathParams[key];
    };
    // query params, please call init function before getting any query params
    RouteParamsService.prototype.getQueryParam = function (key) {
        return this.queryParams[key];
    };
    return RouteParamsService;
}());
exports.RouteParamsService = RouteParamsService;
