"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var i0 = require("@angular/core");
/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
var CustomAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomAdapter, _super);
    function CustomAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.DELIMITER = '/';
        return _this;
    }
    CustomAdapter.prototype.fromModel = function (value) {
        if (value) {
            var date = value.split(this.DELIMITER);
            return {
                month: parseInt(date[0], 10),
                day: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    };
    CustomAdapter.prototype.toModel = function (date) {
        return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : null;
    };
    CustomAdapter.ngInjectableDef = i0.defineInjectable({ factory: function CustomAdapter_Factory() { return new CustomAdapter(); }, token: CustomAdapter, providedIn: "root" });
    return CustomAdapter;
}(ng_bootstrap_1.NgbDateAdapter));
exports.CustomAdapter = CustomAdapter;
/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
var CustomDateParserFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomDateParserFormatter, _super);
    function CustomDateParserFormatter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.DELIMITER = '/';
        return _this;
    }
    CustomDateParserFormatter.prototype.parse = function (value) {
        if (value) {
            var date = value.split(this.DELIMITER);
            return {
                month: parseInt(date[0], 10),
                day: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    };
    CustomDateParserFormatter.prototype.format = function (date) {
        return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';
    };
    CustomDateParserFormatter.ngInjectableDef = i0.defineInjectable({ factory: function CustomDateParserFormatter_Factory() { return new CustomDateParserFormatter(); }, token: CustomDateParserFormatter, providedIn: "root" });
    return CustomDateParserFormatter;
}(ng_bootstrap_1.NgbDateParserFormatter));
exports.CustomDateParserFormatter = CustomDateParserFormatter;
