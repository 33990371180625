import {Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {IBreadCrumb} from '../interfaces/custom/bread-crumb.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {EnumUserRole} from "../enums/user-role.enum";
import {ContextDataService} from '../global-services/context-data.service';
import {EnumRouteName} from "../enums/route-name.enum";
import {LayoutInitService, LayoutService} from "../_metronic/core";
import KTLayoutContent from '../../assets/js/layout/base/content';
import {RoleAccessService} from "./pages/userGroup/_services/role-access.service";

@Component({
    selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
    templateUrl: "./theme.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnDestroy {
  // Public variables
  selfLayout = 'default';
  asideSelfDisplay: true;
  asideMenuStatic: true;
  contentClasses = '';
  contentContainerClasses = '';
  subheaderDisplay = true;
  contentExtended: false;
  asideCSSClasses: string;
  asideHTMLAttributes: any = {};
  headerMobileClasses = '';
  headerMobileAttributes = {};
  footerDisplay: boolean;
  footerCSSClasses: string;
  headerCSSClasses: string;
  headerHTMLAttributes: any = {};
  // offcanvases
  extrasSearchOffcanvasDisplay = false;
  extrasNotificationsOffcanvasDisplay = false;
  extrasQuickActionsOffcanvasDisplay = false;
  extrasCartOffcanvasDisplay = false;
  extrasUserOffcanvasDisplay = false;
  extrasQuickPanelDisplay = false;
  extrasScrollTopDisplay = false;
  // @ts-ignore
  @ViewChild('ktAside', { static: true }) ktAside: ElementRef;
  // @ts-ignore
  @ViewChild('ktHeaderMobile', { static: true }) ktHeaderMobile: ElementRef;
  // @ts-ignore
  @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;

    breadcrumbs$: Subscription;
    EnumUserRole: typeof EnumUserRole = EnumUserRole;
    EnumRouteName: typeof EnumRouteName = EnumRouteName;

    customBreadCrumbs: IBreadCrumb[];
    title: string;
    userRole: EnumUserRole;
    currentRouteName;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private initService: LayoutInitService,
                private roleAccessService: RoleAccessService,
                private layout: LayoutService,) {

        // TODO: need to solve it in the future
        if(this.router.url === '/'){
          window.location.href = '/data-canva/dashboard'
        }

        this.initService.init();
        // console.log('theme',activatedRoute,activatedRoute.snapshot.data)
        // load context data and init the ContextDataService
        // ContextDataService.init(activatedRoute.snapshot.data.rsContextData);

        this.userRole = ContextDataService.userRole;

        // load breadcrumbs and construct custom breadcrumbs
      // this.breadcrumbs$ = BreadCrumbService.getBreadCrumbsObservable().subscribe(breadcrumbs => {
      //
      //     // init custom breadcrumbs and read title
      //     this.customBreadCrumbs = breadcrumbs[breadcrumbs.length - 1].customBreadCrumbs(activatedRoute);
      //     if (breadcrumbs.length > 0) {
      //         this.title = this.customBreadCrumbs[this.customBreadCrumbs.length - 1].title;
      //     }
      //
      //     // get current route name
      //     this.currentRouteName = RouteNameService.getCurrentRouteName(this.activatedRoute);
      // });
    }

  ngOnInit(): void {
    // build view by layout config settings
    this.selfLayout = this.layout.getProp('self.layout');
    this.asideSelfDisplay = this.layout.getProp('aside.self.display');
    this.asideMenuStatic = this.layout.getProp('aside.menu.static');
    this.subheaderDisplay = this.layout.getProp('subheader.display');
    this.contentClasses = this.layout.getStringCSSClasses('content');
    this.contentContainerClasses = this.layout.getStringCSSClasses(
      'content_container'
    );
    this.contentExtended = this.layout.getProp('content.extended');
    this.asideHTMLAttributes = this.layout.getHTMLAttributes('aside');
    this.asideCSSClasses = this.layout.getStringCSSClasses('aside');
    this.headerMobileClasses = this.layout.getStringCSSClasses('header_mobile');
    this.headerMobileAttributes = this.layout.getHTMLAttributes(
      'header_mobile'
    );
    this.footerDisplay = this.layout.getProp('footer.display');
    this.footerCSSClasses = this.layout.getStringCSSClasses('footer');
    this.headerCSSClasses = this.layout.getStringCSSClasses('header');
    this.headerHTMLAttributes = this.layout.getHTMLAttributes('header');
    // offcanvases
    if (this.layout.getProp('extras.search.display')) {
      this.extrasSearchOffcanvasDisplay =
        this.layout.getProp('extras.search.layout') === 'offcanvas';
    }

    if (this.layout.getProp('extras.notifications.display')) {
      this.extrasNotificationsOffcanvasDisplay =
        this.layout.getProp('extras.notifications.layout') === 'offcanvas';
    }

    if (this.layout.getProp('extras.quickActions.display')) {
      this.extrasQuickActionsOffcanvasDisplay =
        this.layout.getProp('extras.quickActions.layout') === 'offcanvas';
    }

    if (this.layout.getProp('extras.cart.display')) {
      this.extrasCartOffcanvasDisplay =
        this.layout.getProp('extras.cart.layout') === 'offcanvas';
    }

    if (this.layout.getProp('extras.user.display')) {
      this.extrasUserOffcanvasDisplay =
        this.layout.getProp('extras.user.layout') === 'offcanvas';
    }

    this.extrasQuickPanelDisplay = this.layout.getProp(
      'extras.quickPanel.display'
    );

    this.extrasScrollTopDisplay = this.layout.getProp(
      'extras.scrolltop.display'
    );
  }

  ngAfterViewInit(): void {
    if (this.ktAside) {
      for (const key in this.asideHTMLAttributes) {
        if (this.asideHTMLAttributes.hasOwnProperty(key)) {
          this.ktAside.nativeElement.attributes[key] = this.asideHTMLAttributes[
            key
            ];
        }
      }
    }

    if (this.ktHeaderMobile) {
      for (const key in this.headerMobileAttributes) {
        if (this.headerMobileAttributes.hasOwnProperty(key)) {
          this.ktHeaderMobile.nativeElement.attributes[
            key
            ] = this.headerMobileAttributes[key];
        }
      }
    }

    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[
            key
            ] = this.headerHTMLAttributes[key];
        }
      }
    }
    // Init Content
    KTLayoutContent.init('kt_content');
  }
    route(bc) {

        let href = bc.href.substring(1);
        let params = href.split("/");

        if (undefined !== bc.queryParams) {
            this.router.navigate(params, {queryParams: bc.queryParams});
        } else {
            this.router.navigate(params);
        }
    }


    ngOnDestroy() {
      //     this.breadcrumbs$.unsubscribe();
    }
}
