"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var data_type_enum_1 = require("../../../enums/data-type.enum");
var forms_1 = require("@angular/forms");
var smart_query_enum_1 = require("../../../enums/smart-query.enum");
var form_control_helper_1 = require("../../helpers/form-control.helper");
var custom_field_model_1 = require("../../model-states/custom-field.model");
var common_validator_1 = require("../../helpers/common-validator");
var sweet_alert_service_1 = require("../../../global-services/sweet-alert.service");
var custom_field_mapping_service_1 = require("./custom-field-mapping.service");
var SetCustomFieldModalComponent = /** @class */ (function () {
    function SetCustomFieldModalComponent(modal, fb, sweetAlertService, formControlHelper, customFieldMappingService, cv) {
        this.modal = modal;
        this.fb = fb;
        this.sweetAlertService = sweetAlertService;
        this.formControlHelper = formControlHelper;
        this.customFieldMappingService = customFieldMappingService;
        this.cv = cv;
        // very important !!!
        this.tempFieldList = [];
        this.filteredFieldList = [];
        this.nameList = [];
        this.expressionType = "";
        this.expressionList = Object.values(data_type_enum_1.EnumCustomFieldExpressType);
        this.operator = "";
        this.operatorList = [];
        this.argumentList = [];
        this.argumentTypeList = [];
        this.argumentDataTypeList = [];
        this.argumentDelList = [];
        this.outputDataTypeList = [];
        this.placeholderList = [];
        this.selectedList = [];
        this.readyCustomFieldList = [];
        this.numericalTypeList = [data_type_enum_1.EnumDataType.Int, data_type_enum_1.EnumDataType.Double, data_type_enum_1.EnumDataType.Long, data_type_enum_1.EnumDataType.Float];
        this.isSubmitted = false;
        this.argObj = new custom_field_model_1.CustomFieldState();
        this.output = "";
    }
    Object.defineProperty(SetCustomFieldModalComponent.prototype, "enumSmartQueryDropZone", {
        get: function () {
            return smart_query_enum_1.EnumSmartQueryDropZone;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetCustomFieldModalComponent.prototype, "enumSmartQueryCustomFieldAction", {
        get: function () {
            return smart_query_enum_1.EnumSmartQueryCustomFieldAction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetCustomFieldModalComponent.prototype, "enumDataType", {
        get: function () {
            return data_type_enum_1.EnumDataType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetCustomFieldModalComponent.prototype, "enumArgumentType", {
        get: function () {
            return smart_query_enum_1.EnumArgumentType;
        },
        enumerable: true,
        configurable: true
    });
    SetCustomFieldModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedList = [];
        this.operatorList = [];
        console.log(this.fieldList, this.mode, this.savedCustomField);
        this.fieldList.forEach(function (field) { return _this.nameList.push(field.fieldName); });
        this.loadCustomFieldForm();
    };
    SetCustomFieldModalComponent.prototype.initCustomFieldForm = function () {
        this.formGroup = this.fb.group({
            customFieldName: ["", forms_1.Validators.compose([forms_1.Validators.required,
                    this.cv.validateDuplicateFieldName(this.nameList)])],
            expressionType: [""],
            operator: [""],
            outputDataType: [""]
        });
        this.tempFieldList = Object.assign([], this.fieldList);
    };
    SetCustomFieldModalComponent.prototype.loadExistCustomFieldForm = function () {
        var _this = this;
        var _a, _b;
        this.formGroup = this.fb.group({
            customFieldName: [this.savedCustomField.fieldName, forms_1.Validators.compose([forms_1.Validators.required,
                    this.cv.validateDuplicateFieldName(this.nameList)])],
            expressionType: [""],
            operator: [""],
            outputDataType: [""]
        });
        this.dropZone = this.savedCustomField.dropZone;
        (_a = this.readyCustomFieldList).push.apply(_a, this.savedCustomField.readyCustomFieldList);
        this.fieldList.forEach(function (field) {
            if (field.fieldName !== _this.savedCustomField.fieldName) {
                _this.tempFieldList.push(field);
            }
        });
        (_b = this.tempFieldList).push.apply(_b, this.savedCustomField.readyCustomFieldList);
    };
    SetCustomFieldModalComponent.prototype.loadCustomFieldForm = function () {
        if (this.mode === smart_query_enum_1.EnumSmartQueryCustomFieldAction.ActionEdit) {
            this.loadExistCustomFieldForm();
        }
        else if (this.mode === smart_query_enum_1.EnumSmartQueryCustomFieldAction.ActionAdd) {
            this.initCustomFieldForm();
        }
    };
    SetCustomFieldModalComponent.prototype.openPopupDocumentation = function () {
        var currentURL = window.location.href;
        var host = currentURL.split("/");
        var url = host[0] + "//" + host[2] + "/documentation/custom-field";
        window.open(url, "_blank", "toolbar,scrollbars,resizable");
    };
    SetCustomFieldModalComponent.prototype.onSelectExpressionType = function (expressionType) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.formGroup.value.operator = "";
        this.operatorList = [""];
        this.argumentTypeList = [];
        this.argumentDelList = [];
        this.argumentDataTypeList = [];
        switch (expressionType) {
            case data_type_enum_1.EnumCustomFieldExpressType.Arithmetic:
                (_a = this.operatorList).push.apply(_a, Object.values(data_type_enum_1.EnumCustomFieldNumericalOperator));
                var numericTypeList_1 = [data_type_enum_1.EnumDataType.Int, data_type_enum_1.EnumDataType.Double, data_type_enum_1.EnumDataType.Long, data_type_enum_1.EnumDataType.Float, data_type_enum_1.EnumCustomFieldExpressType.Arithmetic];
                this.filteredFieldList = this.tempFieldList.filter(function (field) { return numericTypeList_1.includes(field.dataType); });
                break;
            case data_type_enum_1.EnumCustomFieldExpressType.Boolean:
                (_b = this.operatorList).push.apply(_b, Object.values(data_type_enum_1.EnumCustomFieldBooleanOperator));
                this.filteredFieldList = this.tempFieldList.filter(function (field) { return field.dataType == expressionType; });
                break;
            case data_type_enum_1.EnumCustomFieldExpressType.Comparison:
                (_c = this.operatorList).push.apply(_c, Object.values(data_type_enum_1.EnumCustomFieldComparisonOperator));
                this.filteredFieldList = this.tempFieldList;
                break;
            case data_type_enum_1.EnumCustomFieldExpressType.Condition:
                (_d = this.operatorList).push.apply(_d, Object.values(data_type_enum_1.EnumCustomFieldConditionOperator));
                this.filteredFieldList = this.tempFieldList;
                break;
            case data_type_enum_1.EnumCustomFieldExpressType.Constant:
                (_e = this.operatorList).push.apply(_e, Object.values(data_type_enum_1.EnumCustomFieldConstantOperator));
                this.filteredFieldList = this.tempFieldList;
                break;
            case data_type_enum_1.EnumCustomFieldExpressType.Date:
                (_f = this.operatorList).push.apply(_f, Object.values(data_type_enum_1.EnumCustomFieldDateOperator));
                var dateTypeList_1 = [data_type_enum_1.EnumDataType.DateDate, data_type_enum_1.EnumDataType.DateDatetime, data_type_enum_1.EnumDataType.DateTimestamp,
                    data_type_enum_1.EnumCustomFieldExpressType.Date];
                this.filteredFieldList = this.tempFieldList.filter(function (field) { return dateTypeList_1.includes(field.dataType); });
                break;
            case data_type_enum_1.EnumCustomFieldExpressType.String:
                (_g = this.operatorList).push.apply(_g, Object.values(data_type_enum_1.EnumCustomFieldStrOperator));
                var stringTypeList_1 = [data_type_enum_1.EnumDataType.String, data_type_enum_1.EnumDataType.Categorical];
                this.filteredFieldList = this.tempFieldList.filter(function (field) { return stringTypeList_1.includes(field.dataType); });
                break;
            case data_type_enum_1.EnumCustomFieldExpressType.Type:
                (_h = this.operatorList).push.apply(_h, Object.values(data_type_enum_1.EnumCustomFieldTypeOperator));
                this.filteredFieldList = this.tempFieldList;
                break;
        }
        console.log(this.filteredFieldList);
    };
    SetCustomFieldModalComponent.prototype.onOperatorChange = function () {
        var _a, _b;
        this.argumentTypeList = [];
        this.argumentDelList = [];
        this.argumentDataTypeList = [];
        this.outputDataTypeList = [""];
        this.placeholderList = [];
        this.argObj = this.customFieldMappingService.operatorMappingArgNum(this.formGroup.value.operator);
        console.log(this.argObj);
        if (!this.argObj.unlimitedArgNum) {
            for (var i = 0; i < this.argObj.argType.length; i++) {
                this.argumentList.push("");
                this.argumentTypeList.push(this.argObj.argType[i]);
                this.argumentDelList.push(true);
                this.argumentDataTypeList.push("");
            }
        }
        (_a = this.outputDataTypeList).push.apply(_a, this.argObj.outputDataTypeChoice);
        (_b = this.placeholderList).push.apply(_b, this.argObj.placeholder);
        console.log(this.placeholderList);
        this.output = this.formGroup.value.operator + "()";
    };
    SetCustomFieldModalComponent.prototype.selectChangeArgDataType = function (index) {
        var _this = this;
        var field = this.filteredFieldList.find(function (field) {
            return (_this.dropZone === smart_query_enum_1.EnumSmartQueryDropZone.DropZoneCustom) ? field.newName : field.fieldName === _this.argumentList[index];
        });
        this.argumentDataTypeList[index] = field.dataType;
    };
    SetCustomFieldModalComponent.prototype.inputChangeArgDataType = function (index) {
        switch (this.placeholderList[index]) {
            case "<number>":
            case "<base>":
            case "<exponent>":
            case "<place>":
            case "<milliseconds>":
            case "<amount>":
            case "<startIndex>":
            case "<endIndex>":
            case "<finalPaddedLength>":
            case "<padding>":
            case "<codePointIndex>":
                this.argumentDataTypeList[index] = data_type_enum_1.EnumDataType.Long;
                break;
            default:
                this.argumentDataTypeList[index] = data_type_enum_1.EnumDataType.String;
                break;
        }
        console.log(this.argumentDataTypeList);
    };
    SetCustomFieldModalComponent.prototype.addArgument = function (mode) {
        this.argumentTypeList.push(mode);
        if (mode === smart_query_enum_1.EnumArgumentType.Select) {
            this.argumentList.push("");
        }
        else {
            for (var s = 0; s < this.argumentTypeList.length; s++) {
                this.argumentList[s] = this.argumentList[s] || "";
            }
        }
        this.argumentDelList.push(false);
        this.argumentDataTypeList.push("");
    };
    SetCustomFieldModalComponent.prototype.deleteArgument = function (index) {
        this.argumentList.splice(index, 1);
        this.argumentTypeList.splice(index, 1);
        this.argumentDelList.splice(index, 1);
        this.argumentDataTypeList.splice(index, 1);
    };
    SetCustomFieldModalComponent.prototype.checkDisableAddArg = function () {
        if (this.formGroup.value.operator === "" || this.argumentTypeList.length >= this.argObj.maxArgNum) {
            return true;
        }
        else {
            return this.argumentList.length == this.argObj.maxArgNum;
        }
    };
    SetCustomFieldModalComponent.prototype.deleteCustomLogic = function (index) {
        var deletedCustomLogicArray = this.readyCustomFieldList.slice(index);
        var deleteCustomFieldName = [];
        deletedCustomLogicArray.forEach(function (field) { return deleteCustomFieldName.push(field.fieldName); });
        this.tempFieldList = this.tempFieldList.filter(function (field) { return !deleteCustomFieldName.includes(field.fieldName); });
        this.onSelectExpressionType(this.formGroup.value.expressionType);
        this.readyCustomFieldList.splice(index);
    };
    // todo: some problem here
    SetCustomFieldModalComponent.prototype.add = function () {
        this.isSubmitted = false;
        var dropZone;
        switch (this.dropZone) {
            case smart_query_enum_1.EnumSmartQueryDropZone.DropZoneField:
                dropZone = smart_query_enum_1.EnumSmartQueryDropZone.DropZoneField;
                break;
            case smart_query_enum_1.EnumSmartQueryDropZone.DropZoneDimension:
            case smart_query_enum_1.EnumSmartQueryDropZone.DropZoneMeasure:
                dropZone = ![data_type_enum_1.EnumDataType.Int, data_type_enum_1.EnumDataType.Double, data_type_enum_1.EnumDataType.Long, data_type_enum_1.EnumDataType.Float].includes(this.formGroup.value.outputDataType) ? "dimension" : "measure";
                // dropZone = ((this.formGroup.value.outputDataType !== EnumDataType.Int) && (this.formGroup.value.outputDataType !== EnumDataType.Double)) ? "dimension" : "measure";
                break;
            case smart_query_enum_1.EnumSmartQueryDropZone.DropZoneCustom:
                dropZone = smart_query_enum_1.EnumSmartQueryDropZone.DropZoneCustom;
                break;
        }
        var lastCustomFieldList = this.readyCustomFieldList[this.readyCustomFieldList.length - 1];
        var addCustomFieldForm = {
            fieldName: this.formGroup.value.customFieldName,
            dataType: lastCustomFieldList.dataType,
            readyCustomFieldList: this.readyCustomFieldList,
            isCustom: true,
            dropZone: dropZone,
            isMeasure: false
        };
        console.log(addCustomFieldForm);
        this.modal.close(addCustomFieldForm);
    };
    SetCustomFieldModalComponent.prototype.checkDisableCustomButton = function () {
        return this.argumentTypeList.length < this.argObj.minArgNum;
    };
    SetCustomFieldModalComponent.prototype.custom = function () {
        console.log(this.fieldList);
        console.log(this.argumentList);
        var tempArg = "";
        var argElement = [];
        for (var i = 0; i < this.argumentList.length; i++) {
            var element = {
                arg: this.argumentList[i],
                type: this.argumentTypeList[i],
                dataType: this.argumentDataTypeList[i]
            };
            argElement.push(element);
            if (i == 0) {
                tempArg += this.argumentList[0];
            }
            else {
                tempArg += ", " + this.argumentList[i];
            }
        }
        this.output = this.output.replace("()", "(" + tempArg + ")");
        var customFieldList = {
            fieldName: this.output,
            operator: this.formGroup.value.operator,
            argElement: argElement,
            dataType: this.formGroup.value.outputDataType,
            dropZone: smart_query_enum_1.EnumSmartQueryDropZone.DropZoneCustom
        };
        this.readyCustomFieldList.push(customFieldList);
        this.tempFieldList.push(customFieldList);
        this.resetValue();
        console.log(this.readyCustomFieldList);
    };
    SetCustomFieldModalComponent.prototype.resetValue = function () {
        this.formGroup.patchValue({ expressionType: '', operator: '', outputDataType: '' });
        this.argumentList = [];
        this.argumentDelList = [];
        this.argumentTypeList = [];
        this.output = "";
        this.onSelectExpressionType(this.formGroup.value.expressionType);
    };
    return SetCustomFieldModalComponent;
}());
exports.SetCustomFieldModalComponent = SetCustomFieldModalComponent;
